import CSRF from 'oneapp/src/utils/CSRF';

/**
 * @class app.wishlist
 */
(function(app, $) {
	var $cache = {},
		$document = $(document),
		wishlistTableSimpleRowClass = 'b-wishlist_table-item--',
		wishlistTableFirstRowClass = 'b-wishlist_table-item--first',
		initedComponent = false,
		documentEventsInitialized = false,
		methods,
		beforeAddToWishlistFunction = null,
		initialized = false;

	function initializeCache() {
		$cache = {
			document: $(document),
			listItemPage: $('.js-search_result-content'),
			addedToWishlistMsg: $('.js-added_to_wishlist'),
			editAddress: $('.js-edit_address'),
			wishlistTable: $('.js-pt_wishlist .js-table-item_list'),
			wishlistFlyout: $('.js-wishlist_flyout_container'),
			wishlistContainer: $('.js-wish_list, .js-pt_wishlist .js-table-item_list'),
			wishlistQty: '.js-wishlist_qty',
			addtoWishListError: $('.js-add_to_wishlist_error'),
			wishlistFlyoutMouseover: $('.js-wishlist_flyout-mouseover'),
			wishlistTooltip: $('.js-header-tooltip'),
			wishlistTooltipValSel: '.js-wishlist-tooltip_value',
			addtoWishListSel: '.js-add_to_wishlist, .js-hp_wishlist-add',
			addtoWishListCopySel: '.js-add_to_wishlist_copy',
			addtoWishListMultiVarSel: '.js-add_to_wishlist-multivar',
			addtoWishListClose: '.js-add_to_wishlist-close',
			productContentSel: '.js-product_content',
			productTileSel: '.js-product_tile',
			errorVariationsSel: '.js-error_variations',
			emptyCartRecommBlockSel: '.js-empty-cart-recommendations-block',
			variantsBlockSel: '.js-product_tile-variations',
			wishlistPopupSel: '.js-wishlist_popup_wrapper',
			popupCloseButton: $('.js-variation-popup-close'),
			fancyboxOverlay: '.fancybox-overlay',
			popupWrapper: 'b-wishlist_popup_wrapper js-wishlist_popup_wrapper',
			popupOverlay: 'b-wishlist_popup_wrapper-overlay',
			errorPopup: $('.js-error-variation-popup'),
			wishListConfirmationContent: $('.js-wishlist-confirmation-popup'),
			cartWishlistBtnClass: 'js-cart_wishlist-btn',
			hiddenCls: 'h-hidden',
			variantsOpenedCls: 'm-variations-opened',
			wishlistAddedClass: 'b-add_to_wishlist--added',
			wishlistBlock: $('.js-wishlistblock'),
			wishlistItem: null,
			pdpQuickviewSel: '.js-pdp_primary_content-quickview',
			isPDPConfigurable: 'configurator' in app.components.product && app.components.product.configurator.isConfigurable,
			addtowishlist: 'addtowishlist',
			openWishListFlyoutSessionKey: 'openwishlistflyout',
			addToWishlistItemNum: 'addToWishlistItemNum',
			wishlistShowLoginFlyout: 'wishlistShowLoginFlyout',
			cartWishlistBtn: '.js-cart_wishlist-btn',
			updateWishlistAddToCartQuantity: $('.js-update-wishlist-add-to-cart-quantity'),
			wishlistTableListItemSel: '.js-wishlist_table-list_item',
			productQuantitySel: '.js-product_quantity',
			wishListPIDS: [],
			sid: $.cookie('sid'),
			contentsToParse: [],
			lastVisitedSel: '.js-last_visited',
			recommendationsBlockSel: '.js-productrecommendation_general',
			wishlistDeleteForm: $('.js-wishlist-delete-form'),
			notifyMePopupSel: '.js-notifyme_popup',
			quikViewContentSel: '.js-pdp_primary_content_quickview',
			dataAriaAddToWishlist: 'aria-add-to-wishlist',
			dataAriaAddedToWishlist: 'aria-added-to-wishlist',
			customAddCallback: null
		};
	}

	/**
	 * @private
	 * @function
	 * @description Binds the send to friend and address changed events to the wishlist page
	 */
	function initializeEvents() {
		app.components.global.sendToFriend.initializeDialog('.js-list_table_header', '.js-send_to_friend');
		app.components.global.sendToFriend.initializeDialog('.js-table-item_list', '.js-send_to_friend');
		$cache.editAddress.on('change', function() {
			window.location.href = app.util.appendParamToURL(app.urls.wishlistAddress, 'AddressID', $(this).val());
		});

		//add js logic to remove the , from the qty field to pass regex expression on client side
		$('.js-option_quantity_desired div input').focusout(function() {
			$(this).val(
				$(this)
					.val()
					.replace(',', '')
			);
		});

		//add js logic to remove item in dropdown wishlist
		$cache.wishlistFlyout.on('submit', '.js-remove_from_wishlist', function(e) {
			e.preventDefault();
			if (app.page.ns !== 'wishlist') {
				var itemid = $(this)
						.find('input[name="itemid"]')
						.val(),
					href = '';
				if (!itemid.length || !app.urls.removeProdyctFromWishlist) return;
				href = app.util.appendParamsToUrl(app.urls.removeProdyctFromWishlist, { pliid: itemid, format: 'ajax' });
				$.ajax({
					url: href,
					xhrFields: app.corsOrigin ? { withCredentials: true } : undefined,
					type: 'get'
				}).done(function(data) {
					if (data) {
						$document.trigger('wishlist.removed');
					}
				});
				e.stopImmediatePropagation();
				return false;
			}
		});

		$cache.popupCloseButton.on('click', function() {
			app.fancybox.close();
		});

		$cache.wishlistContainer.on('click', $cache.notifyMePopupSel, app.product.openNotifyMePopup);

		// manage appearence of wishlist tooltip
		if (app.device.currentDevice() === 'desktop' && !initedComponent) {
			initedComponent = true;
			$cache.wishlistFlyoutMouseover.hover(function() {
				$cache.wishlistTooltip.toggleClass('h-hidden');
			});
		}

		$($cache.wishlistQty).on('change', function() {
			var $parentForm = $(this).closest('form');
			if ($parentForm.valid()) {
				$parentForm.find('.js-product_list-updateqty_button').click();
				app.page.refresh();
			}
		});

		document.addEventListener('wishlist.storage.init', function() {
			initStorage();

			// Defining if product has to be added to the basket first. Otherwise, just opening the wishlist flyout.
			if ($.cookie($cache.addtowishlist) && app.components.global && app.currentCustomer.isAuthenticated()) {
				var opts = { hidepopup: !$.cookie($cache.wishlistShowLoginFlyout), pid: app.util.getParamFromUrl($.cookie($cache.addtowishlist), 'pid') };

				if (typeof $.cookie('addToWishlistItemNum') !== 'undefined') {
					var cookieVal = $.cookie($cache.addToWishlistItemNum);

					$cache.wishlistItem = $($cache.cartWishlistBtn + '[data-line-item-num="' + cookieVal + '"]');
					$.removeCookie($cache.addToWishlistItemNum);
				}

				addtoWishListAction($.cookie($cache.addtowishlist), opts);
				$.removeCookie($cache.addtowishlist, { path: '/' });
				sessionStorage.removeItem($cache.openWishListFlyoutSessionKey);
			} else if (sessionStorage.getItem($cache.openWishListFlyoutSessionKey) && app.currentCustomer.isAuthenticated()) {
				sessionStorage.removeItem($cache.openWishListFlyoutSessionKey);
				app.flyoutMgr.open('wishlist');
			}
		});

		if (app.page.ns !== 'account') {
			$.removeCookie($cache.wishlistShowLoginFlyout, { path: '/' });
		}

		if (app.device.isMobileView()) {
			app.components.product.mobile.init();
		}

		$cache.updateWishlistAddToCartQuantity.on('change', function() {
			var $this = $(this);
			$this.closest($cache.wishlistTableListItemSel).find($cache.productQuantitySel).val($this.val());
		});

		$cache.wishlistDeleteForm.submit(function () {
			var pid = $(this).data('pid');
			if(pid){
				deleteFromStorage(pid);
			}

			if (app.preferences.emarsysWishlistReminderEnabled) {
				var form = $(this).closest('form');
				var formActionUrl = form.attr('action');

				form.attr('action', app.util.appendParamToURL(formActionUrl, 'triggerWishlistReminder', true));
			}
		});

		$cache.document.on('last.visited.loaded', function() {
			var lastVisitedBlock = $($cache.lastVisitedSel);
			if (lastVisitedBlock.length) {
				updateWishlistButtons(lastVisitedBlock);
			}
		});

		$cache.document.on('recommendations.loaded', function() {
			var recommendationsBlock = $($cache.recommendationsBlockSel);
			if (recommendationsBlock.length) {
				updateWishlistButtons(recommendationsBlock);
			}
		});
	}

	function initializeDocumentEvents() {
		// add product to whishlist if user registered
		var swatchAnchorEvent = Modernizr.touchevents && !$($cache.addtoWishListSel).is('a, div, span, button') ? 'touchstart' : 'click';
		$document.on(swatchAnchorEvent, $cache.addtoWishListSel, function(event) {
			const $this = $(this);
			const customizedProduct = $this.data('customization-json');

			if ($cache.isPDPConfigurable || customizedProduct || app.product.isCustomized()) {
				app.components.wishlist.custom.addCustomizedProduct.call(this, event, customizedProduct);
				return false;
			} else {
				if ($this.hasClass('js-wishist-select-variant')) {
					return;
				}
				updateHref($this);
				return addProductToWhishlist(this, event);
			}
		});

		$document.on('click', $cache.addtoWishListClose, function() {
			var productTile = $(this).closest($cache.productTileSel);

			productTile.find($cache.variantsBlockSel).removeClass('error-variant_unchosen');
			productTile.removeClass($cache.variantsOpenedCls);
		});

		// add product with multiple variants to wishlist
		$document.on('click', $cache.addtoWishListMultiVarSel, function(event) {
			var $this = $(this),
				productTile = $this.closest($cache.productTileSel),
				variantsBlock = productTile.length
					? productTile.find($cache.variantsBlockSel)
					: $this.closest($cache.variantsBlockSel),
				isVariantChosen = variantsBlock.data('variant-chosen');

			if (isVariantChosen === true) {
				updateHref($this);
				return addProductToWhishlist(this, event);
			} else {
				isVariantChosen === false && variantsBlock.addClass('error-variant_unchosen');
				showVariants($this, productTile, variantsBlock);
			}
			event.stopImmediatePropagation();
		});

		$document.on('product.added', function(event, container) {
			if (!app.preferences.removeProductFromWishlist) {
				return false;
			}
			var form = $(container);
			if (app.page.ns !== 'wishlist' || !form.data('wishlistItem')) return;
			var itemid = form.find('input[name="itemid"]').val(),
				href = '';
			if (!itemid.length || !app.urls.wishlistItemRemove) return;
			href = app.util.appendParamsToUrl(app.urls.wishlistItemRemove, {
				pliid: itemid,
				format: 'ajax'
			});
			$.ajax({
				url: href,
				type: 'get'
			}).done(function() {
				var $controlContainer = form.closest('tr');
				if ($controlContainer.siblings('.js_wishlist-controls').length) {
					if ($controlContainer.prev('tr').hasClass(wishlistTableFirstRowClass)) {
						$controlContainer
							.next('tr')
							.addClass(wishlistTableFirstRowClass)
							.removeClass(wishlistTableSimpleRowClass);
					}
					$controlContainer.prev('tr').remove();
					$controlContainer.remove();
				}
			});
		});

		$document.on('cart.addproduct.popup.close', function() {
			if (app.page.ns !== 'wishlist') return;

			app.page.refresh();
		});

		if (app.device.isMobileView() && app.page.ns === 'product') {
			$document.on('click', $cache.addtoWishListCopySel, function(event) {
				var $element = $($cache.addtoWishListSel);
				if ($element.length) {
					if ($cache.isPDPConfigurable || app.product.isCustomized()) {
						app.components.wishlist.custom.addCustomizedProduct.call($element[0], event);
					} else {
						addProductToWhishlist($element[0], event);
					}
				}
			});
		}
	}

	function updateHref($el, customizationID) {
		var pid = $el.data('pid'),
			url = $el.data('href');
		if (pid) {
			url = app.util.appendParamToURL(url, 'pid', pid);
		}
		if (app.preferences.emarsysWishlistReminderEnabled) {
			url = app.util.appendParamToURL(url, 'triggerWishlistReminder', true);
		}
		if (typeof customizationID !== 'undefined' && customizationID !== '') {
			url = app.util.removeParamFromURL(url, 'customizationID');
			url = app.util.appendParamToURL(url, 'customizationID', customizationID);
		}
		$el.data('href', url);
	}

	function showVariants($this, $tile, $variantsBlock) {
		if ($variantsBlock && $variantsBlock.length) {
			if (app.device.isMobileView()) {
				!$($cache.wishlistPopupSel).length &&
					app.fancybox.open($variantsBlock, {
						wrapCSS: $cache.popupWrapper
					});
			} else {
				$tile.addClass($cache.variantsOpenedCls);
			}
			$variantsBlock.data('variant-chosen', false);
		} else {
			var pid = $this.data('pid'),
				params = { pid: pid, tmpl: 'selectvariation' };
			if (app.device.isMobileView()) {
				app.fancybox.open(app.util.appendParamsToUrl(app.urls.productGetVariants, params), {
					wrapCSS: $cache.popupWrapper,
					type: 'ajax',
					afterShow: function() {
						$($cache.wishlistPopupSel)
							.addClass($cache.variantsOpenedCls)
							.find($cache.variantsBlockSel)
							.data('variant-chosen', false);
					}
				});
			} else {
				app.ajax.load({
					url: app.util.appendParamsToUrl(app.urls.productGetVariants, params),
					callback: function(data) {
						if (data) {
							$tile
								.append(data)
								.addClass($cache.variantsOpenedCls)
								.find($cache.variantsBlockSel)
								.data('variant-chosen', false);
						}
					}
				});
			}
		}
	}

	function getAddedToWishlistMsg(pid) {
		return $(`.js-added_to_wishlist[data-pid=${pid}]`);
	}

	function addtoWishListAction(href, opts) {
		if (href) {
			href = app.util.appendParamToURL(href, 'sourcepage', app.page.ns);

			$.ajax({
				url: href,
				method: 'POST',
				contentType: 'application/json'
			})
				.done(function(data) {
					if (data) {
						if (app.page.ns !== 'checkout') {
							app.wishlist.update(data);
						}

						if (app.page.ns === 'wishlist') {
							app.page.redirect(app.urls.wishlistShow);

							return false;
						}

						if (['product', 'search'].includes(app.page.ns) && app.util.getConfig('showNotificationMessage')) {
							var text = findProductInWishlist(opts.pid.toString()) === -1 ? app.resources.PRODUCT_ADDED_TO_WISHLIST_SUCCESS : app.resources.PRODUCT_ALREADY_ADDED;

							app.notificationsMgr.show('general', { text });
						}

						// sometimes we need to show funcy box after product was added
						if (app.fancybox) {
							app.fancybox.close();
						}

						if (app.preferences.wishlistAddAuthenticated === 'popup') {
							app.fancybox.open($cache.wishListConfirmationContent, {
								wrapCSS: $cache.popupWrapper,
								minHeight: 'auto',
								afterShow: function() {
									$($cache.fancyboxOverlay).addClass($cache.popupOverlay);
									$($cache.popupCloseButton).on('click', function() {
										app.fancybox.close();
									});
								}
							});
						} else {
							getAddedToWishlistMsg(opts.pid).show();
						}

						if (typeof opts !== 'undefined' && typeof opts.pid !== 'undefined') {
							addToStorage(opts.pid);
							updateWishlistButtons();
						}
						if (app.page.ns !== 'checkout' && !app.device.isMobileView()) {
							if (app.flyoutMgr.isOpen('wishlist')) {
								app.flyoutMgr.update('wishlist');
							} else if (typeof $cache.customAddCallback === 'function') {
								$cache.customAddCallback();
							} else {
								app.flyoutMgr.open('wishlist');
							}
						}

						$document.trigger('wishlist.added', [data.lineItemsQuantity || 0, (opts && opts.hidepopup)]);
					}
				});
		}
	}

	function findProductInWishlist(pid) {
		return $cache.wishListPIDS.findIndex((wishlistProduct) => wishlistProduct.PID === pid);
	}

	function deleteFromStorage(pid) {
		pid = pid ? pid.toString() : '';
		var pIDindex = findProductInWishlist(pid);

		if (pid && pIDindex >= 0) {
			$cache.wishListPIDS.splice(pIDindex, 1);
		}
	}

	function addToStorage(pid) {
		pid = pid ? pid.toString() : '';

		if (pid && findProductInWishlist(pid) === -1) {
			$cache.wishListPIDS.push({ PID: pid });
		}
	}

	function initStorage() {
		if (app.currentCustomer.isAuthenticated() || app.wishlist.markup.isWishlistForNotAuthUser) {
			$cache.wishListPIDS = (app.wishlist.markup.products || []).map((product) => ({
				PID: product.pid,
				masterPID: product.masterPid
			}));

			$cache.document.trigger('wishlist.initialize.done');
		}
		updateWishlistButtons();
	}

	function updateWishlistButtons(container, pid) {
		container = container || $('body');

		if (!initialized) {
			app.wishlist.init();
		}
		function buttonsUpdater() {
			var $wishlistAddedButtons = container.find('.' + $cache.wishlistAddedClass);
			var isCheckout = app.page.ns === 'checkout';

			if (!isCheckout) {
				$wishlistAddedButtons.removeClass($cache.wishlistAddedClass).attr('aria-pressed', 'false');

				if (app.configs.wishlist.showAddedMessage) {
					getAddedToWishlistMsg(pid).hide();
				}

				resetWishlistAriaLabel($wishlistAddedButtons);
			}

			if (Array.isArray($cache.wishListPIDS) && $cache.wishListPIDS.length > 0) {
				container.find($cache.addtoWishListSel).each(function() {
					var $this = $(this);
					var $elMasterPID = $this.parents($cache.productTileSel).data('itemid');

					for (var i = 0, len = $cache.wishListPIDS.length; i < len; i++) {
						if (($this.data('pid') && (($cache.wishListPIDS[i].PID === $this.data('pid').toString())
							|| ($cache.wishListPIDS[i].masterPID === $this.data('pid').toString())))
							|| ($this.data('id') && ($cache.wishListPIDS[i].PID === $this.data('id').toString()))
							|| ($elMasterPID && ($cache.wishListPIDS[i].masterPID === $elMasterPID.toString()))) {
							$this.addClass($cache.wishlistAddedClass).attr('aria-pressed', 'true');

							if (app.configs.wishlist.showAddedMessage
								|| (isCheckout && app.configs.wishlist.showAddedMessageOnCheckout)) {
								$this.html(app.resources.SAVED_TO_WISHLIST);
							}

							setAddedToWishlistAriaLabel($this);
						}
					}
				});
			}
		}

		buttonsUpdater();
	}

	/**
	* @function
	* @param {jQuery} $activeWishlistButtons - active wishlist buttons
	*/
	function resetWishlistAriaLabel($activeWishlistButtons) {
		$activeWishlistButtons.each(function() {
			var $this = $(this);
			var ariaLabelText = $this.data($cache.dataAriaAddToWishlist);

			if (ariaLabelText) {
				$this.attr('aria-label', ariaLabelText);
			}
		});
	}

	/**
	* @function
	* @param {jQuery} $wishlistBtn - current wishlist button
	*/
	function setAddedToWishlistAriaLabel($wishlistBtn) {
		var ariaLabelText = $wishlistBtn.data($cache.dataAriaAddedToWishlist);

		if (!ariaLabelText) return;

		$wishlistBtn.attr('aria-label', ariaLabelText);
	}

	function wishlistHandlerWrapper() {
		if (typeof beforeAddToWishlistFunction === 'function') {
			return beforeAddToWishlistFunction.call(beforeAddToWishlistFunction);
		}
		return true;
	}

	function addProductToWhishlist(element, event) {
		var $this = $(element),
			$productContentBlock = $this.closest($cache.productContentSel),
			productErrorBlock = $productContentBlock.find($cache.errorVariationsSel);
		$document.trigger('wishlist.beforeadded', $this);
		$cache.wishlistItem = $this;
		if (productErrorBlock.length > 0) {
			if (
				app.preferences.isPdpPopupEnabled &&
				app.page.ns === 'product' &&
				$this.closest($cache.pdpQuickviewSel).length === 0
			) {
				$document.trigger('product.valid', [productErrorBlock]);
				if (productErrorBlock.is(':visible')) {
					productErrorBlock.hide();
				}
				app.fancybox.open($cache.errorPopup, {
					wrapCSS: $cache.popupWrapper,
					afterShow: function() {
						$($cache.fancyboxOverlay).addClass($cache.popupOverlay);
					}
				});
			} else {
				$document.trigger('product.invalid', [productErrorBlock]);
				productErrorBlock.show();

				if (app.preferences.pdpScrollToVariationsError) {
					if (!app.util.isVisibleFullHeight(productErrorBlock[0])) {
						var sizeSelectorPosition = $productContentBlock.offset().top;
						var headerHeight = $('header.js-header_main:first').height();
						app.util.scrollBrowser(sizeSelectorPosition - headerHeight);
					}
				}
			}
		} else {
			if (app.currentCustomer.isAuthenticated() || app.preferences.enableWishlistForNotAuthUser) {
				var isEmptyCartRecommCarousel = $this.closest($cache.emptyCartRecommBlockSel).length;
				if (app.preferences.redirectToWishlistFromCartPage && $this.hasClass($cache.cartWishlistBtnClass)) {
					window.location = app.util.appendParamsToUrl($this.data('href'), { redirectToWishlist: true });
				} else if (
					('redirect' === app.preferences.wishlistAddAuthenticated &&
						!(app.device.isMobileView() && app.preferences.productShowPopup)) ||
					(isEmptyCartRecommCarousel && !$cache.wishlistBlock.length && !app.device.isMobileView())
				) {
					window.location = app.util.appendParamsToUrl($this.data('href'), { format: 'redirect' });
				} else {
					var pid;

					if ($this.parents($cache.productContentSel).length && !$this.parents($cache.quikViewContentSel).length) {
						pid = app.product.isCustomizable() ? app.configurator.configurator.getProductID() : $this.data('pid');
					} else {
						pid = $this.data('pid');
					}

					var url = app.util.appendParamToURL($this.data('href'), 'pid', pid);

					addtoWishListAction(url, {
						pid: pid,
						addtostorage: $this.data('addtostorage')
					});
					typeof event !== 'undefined' && event.stopImmediatePropagation();
				}
			} else {
				if (app.device.isMobileView() && !app.preferences.enableMobileWishlistLoginPopup) {
					window.location = app.util.appendParamsToUrl($this.data('href'), {
						format: 'redirect',
						returnUrl:
							app.preferences.wishlistAddNotauthenticatedMobileReturn === 'wishlist'
								? app.urls.wishlistShow
								: encodeURIComponent(window.location)
					});
				} else if ('redirect' === app.preferences.wishlistAddNotauthenticated) {
					window.location = app.util.appendParamsToUrl($this.data('href'), { format: 'redirect' });
				} else {
					$.cookie($cache.addtowishlist, $this.data('href'), {
						path: '/'
					});
					$.cookie($cache.addToWishlistItemNum, $cache.wishlistItem.data('line-item-num'));
					if (app.preferences.wishlistShowLoginFlyout) {
						$.cookie($cache.wishlistShowLoginFlyout, true, {
							path: '/'
						});
						if (app.preferences.wishlistShowLoginError === true) {
							$cache.addtoWishListError.show();
						}
						if (
							app.page.ns === 'checkout' &&
							!app.device.isMobileView()
						) {
							app.components.account.fakelogin.showPopup();
						} else if (!app.preferences.customFakeLoginLogic) {
							typeof event !== 'undefined' && app.components.account.fakelogin.show(event);
						}
					}

					if (!app.preferences.enableMobileWishlistLoginPopup && app.fancybox) {
						app.fancybox.close();
					}

					if (app.preferences.wishlistRedirectAfterClose) {
						$document.on('fancybox.closed', function() {
							var toUrl = $this.data('href');
							toUrl = app.util.removeParamFromURL(toUrl, 'format');
							toUrl = app.util.appendParamToURL(toUrl, 'format', 'redirect');
							app.page.redirect(toUrl);
						});
						typeof event !== 'undefined' && event.stopImmediatePropagation();
					}
				}
			}

			return false;
		}

		if (
			app.preferences.plpScrollTopOnAddToWishlist == 'true' &&
			(app.preferences.isPdpPopupEnabled && app.page.ns !== 'product')
		) {
			app.util.scrollBrowser(0);
		}
	}

	function updateTooltipVal(val) {
		val = parseInt(val);
		if (val > 0) {
			$($cache.wishlistTooltipValSel).text(
				val == 1 ? app.resources.TOOLTIP_ITEM.replace('#', val) : app.resources.TOOLTIP_ITEMS.replace('#', val)
			);
		} else {
			$($cache.wishlistTooltipValSel).text(app.resources.TOOLTIP_EMPTY);
		}
	}

	function updateWishlistQuantity(params) {
		const {
			quantity,
			wishlistTitleCtnr,
			wishlistQuantityCtnr,
			wishlistQuantityValueCtnr
		} = params;

		if (wishlistTitleCtnr.length && wishlistQuantityValueCtnr.length) {
			wishlistTitleCtnr.removeClass('h-click-disable')
				.toggleClass('b-wishlist_empty', quantity === 0);
			const quantityTemplate = app.configs.wishlist.quantityTemplate;
			const renderedTemplate = app.util.renderTemplate(quantityTemplate, {
				quantity: quantity.toString()
			});

			wishlistQuantityValueCtnr.html(renderedTemplate);
			wishlistQuantityCtnr.toggleClass('h-hidden', quantity === 0 && !app.configs.wishlist.showZeroValue);
			updateTooltipVal(quantity);
		}
	}

	function removeProduct(pliid, pid, quiteMode) {
		var url = app.urls.removeProdyctFromWishlist;

		if (app.preferences.emarsysWishlistReminderEnabled) {
			url = app.util.appendParamToURL(url, 'triggerWishlistReminder', true);
		}

		$.ajax({
			url: url,
			type: 'GET',
			xhrFields: app.corsOrigin ? { withCredentials: true } : undefined,
			data: {
				pliid,
				format: 'ajax'
			}
		})
			.done(function(data) {
				app.wishlist.update(data);
				if (!quiteMode) {
					app.flyoutMgr.update('wishlist');
					if (data.lineItemsQuantity > 0) {
						var carousel = $('.js-wish_list .js-owl_carousel');

						app.owlcarousel.initCarousel(carousel);
					}
				}

				if (app.configs.wishlist.autoCloseOnEmpty && data.lineItemsQuantity === 0) {
					app.flyoutMgr.close('wishlist');
				}

				if (pid) {
					deleteFromStorage(pid);
					updateWishlistButtons(null, pid);
					$cache.document.trigger('wishlist.removed');
				}
			}).fail(function() {
				location.reload();
			});
	}

	/** ************* Object of method for external functionality ************** */
	methods = {
		beforeAddToWishlistFunction : function(func){
			beforeAddToWishlistFunction = func;
		}
	};

	/******* app.wishlist public object ********/
	app.wishlist = {
		/**
		 * @function
		 * @description Binds events to the wishlist page
		 */
		init: function() {
			if(!initialized) {
				initializeCache();
				app.product && app.product.initAddToCart($('.js-wishlist-add_to_cart'));
				initializeEvents();

				if (!documentEventsInitialized) {
					initializeDocumentEvents();
					documentEventsInitialized = true;
				}

				initialized = true;
			}
		},
		addProductToWhishlist: addProductToWhishlist,
		updateHref: updateHref,
		updateTooltipVal: updateTooltipVal,
		beforeAddToWishlistFunction : methods.beforeAddToWishlistFunction,
		updateWishlistButtons: updateWishlistButtons,
		deleteFromStorage,
		addToStorage,
		wishlistHandlerWrapper,
		removeProduct,
		updateWishlistQuantity,
		setCustomAddCallback: (callback) => {
			$cache.customAddCallback = callback;
		}
	};
})((window.app = window.app || {}), jQuery);
