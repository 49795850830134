/* global $, KCP_Pay_Execute, GetField */
export const KCP_SELECTORS = Object.freeze({
	FORM_WRAPPER: '.js-kcp-form-wrapper',
	FORM: '.js-kcp-form',
	OVERLAY_LOADER: '.js-kcp-overlay'
});

const SUCCESS_CODE = '0000';

/** Class representing a KCP Manager. */
export class KCPMgr {
	/**
	 * Show if KCP is applicable on this page
	 * @return {Boolean}
	 */
	static isApplicable() {
		return !!document.querySelector(KCP_SELECTORS.FORM_WRAPPER);
	}

	/**
	 * Hide KCP overlay loader
	 * @return {Boolean}
	 */
	static hideOverlayLoader() {
		$(KCP_SELECTORS.OVERLAY_LOADER).addClass('h-hidden');
	}

	/** Create a KCP Manager instance. */
	constructor(billingUrl, formData, isMobileView, generateApprovalKeyUrl) {
		this.billingUrl = billingUrl;
		this.formData = formData;
		this.isMobileView = isMobileView;
		this.generateApprovalKeyUrl = generateApprovalKeyUrl;
	}

	/** Process KCP Payment */
	processPayment() {
		app.ajax.load({
			type: 'POST',
			url: this.billingUrl,
			data: $.param(this.formData),
			target: KCP_SELECTORS.FORM_WRAPPER,
			callback: () => {
				const kcpForm = document.querySelector(KCP_SELECTORS.FORM);
				if (kcpForm) {
					if (this.isMobileView) {
						this.approveMobileOrder(kcpForm);
					} else {
						KCP_Pay_Execute(kcpForm);
					}
				}
			}
		});
	}

	/** Approve Mobile Order
	 * * @param {Object} kcpForm - The string containing two comma-separated numbers.
	 */
	approveMobileOrder(kcpForm) {
		const continueURL = kcpForm.action;
		$.ajax({
			type: 'POST',
			url: this.generateApprovalKeyUrl,
			data: {
				returnURL: continueURL
			},
			dataType: 'json',
			success: (data) => {
				if (data) {
					if (!(data.error || data.resultCode !== SUCCESS_CODE)) {
						kcpForm.approval_key.value = data.approvalKey;
						kcpForm.Ret_URL.value = continueURL;
						kcpForm.action = data.payUrl;
					} else {
						kcpForm.res_code.value = data.resultCode;
						kcpForm.res_msg.value = data.resultMessage;
					}
				}
				kcpForm.submit();
			}
		});
	}
}

/**
 * Recursive Function for completed authentication process
 * This function name cannot be changed or modified.
 * This function has to be declared before payplus.js
 * Returned in Form in case of Web method
 * @param {Object} formOrJson
 * @param {Function} closeEvent
 */
window.m_Completepayment = (formOrJson, closeEvent) => {
	const kcpForm = document.querySelector(KCP_SELECTORS.FORM);
	GetField(kcpForm, formOrJson);
	kcpForm.submit();
	closeEvent();
};

export default KCPMgr;
