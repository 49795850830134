/**
 * The Director class responsible for constructing the Calendar using the provided builder.
 * @module CalendarDirector
 */

/**
 * The Director class responsible for constructing the ShippingCalendar using the provided builder.
 */
class Director {
	/**
	 * Constructs a Director instance.
	 * @param {CalendarBuilder} builder - The builder instance.
	 */
	constructor(builder) {
		this.builder = builder;
	}

	/**
	 * Builds the ShippingCalendar using the provided properties.
	 * @param {Object} calendarProps - The properties for building the calendar.
	 * @param {Date} calendarProps.date - The initial date for the calendar.
	 * @param {number} calendarProps.daysLimit - The number of days to limit in the calendar.
	 * @param {number} calendarProps.monthShift - The month shift value.
	 * @param {Date} calendarProps.selectedDate - The selected date for the calendar.
	 */
	buildShippingCalendar({ date, daysLimit, monthShift, selectedDate, error }) {
		this.builder.setDate(date);
		this.builder.setError(error);
		this.builder.setDaysLimit(daysLimit);
		this.builder.setSelectedDate(selectedDate);
		this.builder.setMonthShift(monthShift);
		this.builder.buildShippingNextMonthButton();
		this.builder.buildShippingPrevMonthButton();
		this.builder.buildDays();
		this.builder.buildYear();
		this.builder.buildMonth();
		this.builder.buildError();
	}
}

export default Director;
