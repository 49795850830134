(function(app, $) {
	
	var $cache = {
			document : $(document),
			quickViewBlockSel : '.js-quickview',
			quickViewMainSel : '.js-quick_view_main'
		},
		default_options = {
			type: 'ajax',
			beforeShow: function () {
				$cache.document.trigger('quickview.beforeShow');
			},
			afterShow: function() {
				app.product.init($cache.quickViewMainSel);
				app.components.global.quickview.isOpened = true;
				$cache.document.trigger('quickview.opened');
				document.dispatchEvent(new CustomEvent('lazyload-reinit'));

				$($cache.quickViewMainSel).on('product.master.reloaded', function () {
					app.product.init($cache.quickViewMainSel, true);
				});
			},
			beforeClose: function(){
				app.components.global.quickview.isOpened = false;
				$cache.document.trigger('quickview.beforeClose');
			},
			afterClose: function () {
				$cache.document.trigger('quickview.closed');
			}
		};

	function initializeConfigs(params) {
		if (params && params.quickViewBlockSel) {
			$cache.quickViewBlockSel = params.quickViewBlockSel;
		}
		if (params && params.options) {
			$.extend(default_options, params.options);
		}
	}

	function initializeEvents() {
		$cache.document.on('click', $cache.quickViewBlockSel, function(event) {
			var $this = $(this);

			$($cache.quickViewBlockSel).removeClass('js-active');
			$this.addClass('js-active');
			app.flyoutMgr.closeAll();
			$cache.document.trigger('toggle.hideall');
			sessionStorage.setItem('productTileOrigin', app.gtm.config.global.getTileOrigin($(event.target)));
			showQuickView($this.data('url') || $this.data('href') || $this.attr('href'), $this.data('options'), $this.data('source'));

			return false;
		});
		$cache.document.on('quickview.open', function(event, data){
			if (data && data.targetUrl) {
				sessionStorage.setItem('productTileOrigin', app.gtm.config.global.getTileOrigin($(event.target)));
				showQuickView(data.targetUrl, data.options);
			}
		});
	}
	
	function showQuickView(_url, _options, source){

		var pdp_url = app.util.appendParamsToUrl(_url, {
			source: source || "quickview"
		});
		var custom_options = _options ? $.extend(true, default_options, _options) : default_options;
		app.fancybox.open(pdp_url, custom_options);
	}
	
	/*************** app.components.global.quickview public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.quickview = {
		// initializations
		init: function(params) {
			initializeConfigs(params);
			initializeEvents();			
		},
		isOpened: false
	};
})(window.app = window.app || {}, jQuery);