/**
 * The CalendarBuilder class responsible for building the Calendar.
 * @module CalendarBuilder
 */
class CalendarBuilder {
	/**
	 * Constructs a CalendarBuilder instance.
	 */
	constructor() {
		this.calendar = {};
		this.dateLimit = null;
		this.selectedDate = null;
		this.monthShift = 0;
		this.error = null;
	}

	/**
	 * Sets the days limit for the calendar.
	 * @param {number} daysLimit - The number of days to limit in the calendar.
	 */
	setDaysLimit(daysLimit) {
		const dateLimit = new Date(this.date);
		dateLimit.setDate(dateLimit.getDate() + daysLimit);

		this.dateLimit = dateLimit;
	}

	/**
	 * Sets the selected date for the calendar.
	 * @param {Date} selectedDate - The selected date for the calendar.
	 */
	setSelectedDate(selectedDate) {
		this.selectedDate = selectedDate;
	}

	/**
	 * Sets the date for the calendar.
	 * @param {Date} date - The initial date for the calendar.
	 */
	setDate(date) {
		this.date = date;
	}

	/**
	 * Sets the error for the calendar.
	 * @param {Object} error
	 */
	setError(error) {
		this.error = error;
	}

	/**
	 * Sets the month shift value for the calendar.
	 * @param {number} monthShift - The month shift value.
	 */
	setMonthShift(monthShift) {
		this.monthShift = monthShift;
	}

	/**
	 * Builds the error block for the calendar.
	 */
	buildError() {
		if (!this.error) {
			this.calendar.errorBlock = ''
		} else {
			this.calendar.errorBlock = app.util.renderTemplate(document.getElementById('calendarError').innerHTML, this.error);
		}
	}

	/**
	 * Builds the shipping next month button for the calendar.
	 */
	buildShippingNextMonthButton() {
		this.calendar.nextMonthButton = app.util.renderTemplate(document.getElementById('calendarNextMonthButton').innerHTML, {});
	}

	/**
	 * Builds the shipping previous month button for the calendar.
	 */
	buildShippingPrevMonthButton() {
		this.calendar.prevMonthButton = app.util.renderTemplate(document.getElementById('calendarPrevMonthButton').innerHTML, {});
	}

	/**
	 * Builds the days for the calendar.
	 */
	buildDays() {
		const year = this.date.getFullYear();
		const month = this.date.getMonth() + this.monthShift;

		const firstDay = new Date(year, month, 1);
		const lastDay = new Date(year, month + 1, 0);

		const calendar = [];

		for (let i = 0, length = firstDay.getDay(); i < length; i++) {
			calendar.push(null);
		}

		for (let i = 1, length = lastDay.getDate(); i <= length; i++) {
			const currentDate = new Date(this.date);

			currentDate.setMonth(currentDate.getMonth() + this.monthShift);
			currentDate.setDate(i);
			calendar.push(currentDate);
		}

		const daysMarkup = calendar.reduce((accumulator, date) => {
			const day = date?.getDate();
			const isUnderLimit = date > this.dateLimit || date < this.date;
			const isSelected = this.selectedDate && date && this.selectedDate.getTime() === date.getTime();
			const cssClass = !day ? 'invisible' : isUnderLimit ? 'disabled' : isSelected ? 'selected' : '';

			const markup = app.util.renderTemplate(document.getElementById('calendarDayButton').innerHTML, {
				day,
				cssClass,
				date
			});

			return accumulator + markup;
		}, '');

		this.calendar.daysBlock = daysMarkup;
	}

	/**
	 * Builds the month for the calendar.
	 */
	buildMonth() {
		const month = getLocalDateString(this.date.getFullYear(), this.date.getMonth() + this.monthShift, { month: 'long' });
		const template = document.getElementById('calendarMonth');

		this.calendar.monthBlock = app.util.renderTemplate(template.innerHTML, { month });
	}

	/**
	 * Builds the year for the calendar.
	 */
	buildYear() {
		const template = document.getElementById('calendarYear');
		const year = getLocalDateString(this.date.getFullYear(), this.date.getMonth() + this.monthShift, { year: 'numeric' });

		this.calendar.yearBlock = app.util.renderTemplate(template.innerHTML, { year });
	}

	/**
	 * Retrieves the result of the calendar building process.
	 * @returns {Object} - The built calendar.
	 */
	getResult() {
		return this.calendar;
	}
}

/**
 * Retrieves Local Date String.
 * @param {number} year - The year.
 * @param {number} month - The month (0-based index).
 * @returns {string} - The name of the month.
 */
const getLocalDateString = (year, month, params) => {
	const shiftedDate = new Date(year, month);

	return shiftedDate.toLocaleDateString(app.user.userInfo.language, params);
};

export default CalendarBuilder;
