/**
 * @class app.accordion
 */

(function (app, $) {
	
	var $cache = {
		classes: {
			header: 'accordion-header-active ui-state-active ui-corner-top',
			contentActive: 'accordion-content-active',
			iconE: 'ui-icon-triangle-1-e',
			iconS: 'ui-icon-triangle-1-s',
			cornerAll: 'ui-corner-all'
		},
		selectors: {
			accordionCntr: '.js-accordion_cntr',
			accordionContent: '.ui-accordion-content',
			icon: '.ui-icon'
		}
	};
	
	var settings = {
		//The basic set of accordion parameters
		//Which panel is currently open
		active: 0,
		
		//If and how to animate changing panels
		animate: {},
		
		//Specify additional classes to add to the widget's elements. This option doesn't work in version of jQuery that is currently used(jquery-1.11.1)!!!
		classes: {},
		
		//Whether all the sections can be closed at once. Allows collapsing the active section.
		collapsible: true,
		
		//Disables the accordion if set to true
		disabled: false,
		
		//The event that accordion headers will react to in order to activate the associated panel. Multiple events can be specified, separated by a space.
		event: 'click',
		
		//Controls the height of the accordion and each panel. Possible values: 'auto', 'fill', 'content'
		heightStyle: 'content',
		
		//Selector for the header element, applied via .find() on the main accordion element. Content panels must be the sibling immediately after their associated headers.
		header: '> li > :first-child,> :not(li):even',
		
		//Icons to use for headers
		icons: false,
		
		//Custom option, few content blocks can be opened if set to true
		multiple: true,
		
		//Triggered directly before a panel is activated
		beforeActivate: function(event, ui) {
			if(!$(event.target).accordion('option').multiple) return;

			var currHeader = null;
			var currContent = null;

			if (ui.newHeader.length) {
				currHeader = ui.newHeader;
				currContent = currHeader.next($cache.selectors.accordionContent);
			} else {
				currHeader = ui.oldHeader;
				currContent = currHeader.next($cache.selectors.accordionContent);
			}

			var isPanelSelected = currHeader.attr('aria-selected') === 'true';

			toggleHeader(currHeader, !isPanelSelected);
			// Toggle the panel's content
			currContent.toggleClass($cache.classes.contentActive, !isPanelSelected)	
			isPanelSelected ? currContent.slideUp() : currContent.slideDown();

			return false;
		},
		create: function(event, ui) {
			var isActive = this.active !== false;

			toggleHeader(ui.header, isActive);
		}
	};


	/**
	 * Init accordion for certain element
	 * @return undefined
	 **/
	function initAccordion($elem, options) {
		var dataSettings = $elem.data('settings') ? $elem.data('settings') : {};

		if($.fn.accordion) {
			$elem.accordion($.extend({}, settings, dataSettings, options));
		}
	}

	function toggleHeader($header, isActive) {
		var $icon = $header.children($cache.selectors.icon);

		$header.toggleClass($cache.classes.cornerAll, !isActive).toggleClass($cache.classes.header, isActive).attr('aria-selected', isActive.toString());
		$icon.toggleClass($cache.classes.iconE, !isActive).toggleClass($cache.classes.iconS, isActive);
	}

	/**
	 * Init all accordions in page by the selector
	 * @return undefined
	 **/
	function initAllAccordions(){
		$($cache.selectors.accordionCntr).each(function(key, element) {
			initAccordion($(element));
		});
	}

	app.accordion = {
		init: function(){
			initAllAccordions();
		},
		initAccordions: function($elem, options) {
			$elem.each(function() {
				initAccordion($(this), options);
			});
		}
	};
}(window.app = window.app || {}, jQuery));
