(function(app, $) {
	function GeneralNotification(config) {
		this.text = config.text;
		this.timeout = config.timeout || 5000;
		this.animationSpeed = config.animationSpeed || 'slow';
		this.containerClass = config.containerClass || 'b-general-notification-item ';
		this.textClass = config.textClass || 'b-general-notification-item-text';
		this.closeButtonClass = config.closeButtonClass || 'b-general-notification-close';
		this.$html = createHTML.call(this);
		this.hideTimeoutID = false;
	}

	GeneralNotification.prototype.getHTML = function() {
		return this.$html;
	};

	GeneralNotification.prototype.show = function() {
		this.$html.fadeIn(this.animationSpeed, function() {
			this.$html.addClass('faded');
		}.bind(this));

		this.hideTimeoutID = setTimeout(function() {
			this.hide();
		}.bind(this), this.timeout);
	};

	GeneralNotification.prototype.hide = function() {
		this.$html.fadeOut(this.animationSpeed, function() {
			this.remove();
		}.bind(this));
	};

	GeneralNotification.prototype.remove = function() {
		app.notificationsMgr.removeNotification(this);
		window.clearTimeout(this.hideTimeoutID);
		this.$html.remove();
	};

	function createHTML() {
		const notification = this;

		const $html = $('<div>', {
			class: notification.containerClass
		});

		const $text = $('<span>', {
			class: notification.textClass
		}).html(notification.text);

		const $closeButton = $('<button>', {
			class: notification.closeButtonClass
		});

		$closeButton.on('click', function() {
			notification.hide();
		});

		$html.append($text);
		$html.append($closeButton);

		return $html;
	}

	app.notification = app.notification || {};
	app.notification.General = GeneralNotification;
})((window.app = window.app || {}), jQuery);
