/**
 * @class app.klarna
 */
(function (app, $) {
	var $cache = {};

	function initializeCache (){
		$cache = {
			document: $(document),
			klarnaTocSel : "#js-klarna-toc",
			klarnaConsentSel : "#js-klarna-consent"
		};
	}
	function initializeDOM () {
		initializeTOC();
		initializeConsent();
	}
	function initializeEvents() {
		$cache.document.on('cart.updateModels', function() {
			initializeTOC();
		});
	}
	function initializeTOC (){
		var el = $($cache.klarnaTocSel);

		if (el.length && !el.children().length && window.Klarna && window.Klarna.Terms) {
			new Klarna.Terms.Invoice({
				el: 'js-klarna-toc',
				eid: el.data('eid'),
				locale: el.data('locale'),
				charge: 0,
				type: app.isMobileView() ? 'mobile' : 'desktop'
			});
		}
	}
	function initializeConsent (){
		var el = $($cache.klarnaConsentSel);

		if (el.length && window.Klarna && window.Klarna.Terms) {
			new Klarna.Terms.Consent({
				el: 'js-klarna-consent',
				eid: el.data('eid'),
				locale: el.data('locale'),
				charge: 0,
				type: app.isMobileView() ? 'mobile' : 'desktop'
			});
		}
	}
	
	/*************** app.components.global.autocomplete public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.klarna = {
		// initializations
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
	
}(window.app = window.app || {}, jQuery));