(function (app, $) {
	var $cache = {};
	var initialized = false;
	var defaultParams = {
		fancyBoxWrapperClass: 'b-product_image_zoomed js-zoomed',
		fancyBoxWrapperSel: '.js-zoomed',
		mainContainerClass: 'zoom-container',
		zoomedImgContainerClass: 'b-container_main_image js-container_main_image',
		zoomedImgContainerSel: '.js-container_main_image',
		thumbnailsContainerClass: 'zoom_thumbnails_container js-thumbnails_container',
		thumbnailsContainerSel: '.js-thumbnails_container',
		thumbnailSel: '.js-thumbnail',
		thumbnailImgSel: 'img',
		thumbnailSelectedClass: 'b-thumbnail-selected'
	};

	function initializeCache() {
		$cache.selectors = {
			fancyBoxClose: ".fancybox-close"
		};
		$cache.classes = {
			thumbnailsArrowUp: 'b-thumbnails-arrow_up',
			thumbnailsArrowDown: 'b-thumbnails-arrow_down'
		};
		$cache.defaultThumbnailsScrollSettings = {
			itemCount: 4,
			arrowUpClass: $cache.classes.thumbnailsArrowUp,
			arrowDownClass: $cache.classes.thumbnailsArrowDown,
			heightRecalculate: app.util.getConfig('disableThumbHeightRecalculate'),
			reverse: app.preferences.pdpThumbnailsSliderReverse,
			loop: true
		};
	}

	function createZoomedContainer(params) {
		var zoomContainer = $("<div />", {
			class: params.mainContainerClass
		});

		if (params.showNavigationArrows) {
			var nextImageButton = $(document.createElement('div')).addClass('b-product-image_next js-product-image_next');
			var prevImageButton = $(document.createElement('div')).addClass('b-product-image_prev js-product-image_prev');

			zoomContainer.append(nextImageButton);
			zoomContainer.append(prevImageButton);
		}

		zoomContainer.append(
			$("<div />", {
				class: params.zoomedImgContainerClass
			}).append(
				params.zoomedImg
			)
		);

		var thumbnailsCntr = $("<div />", {
			class: params.thumbnailsContainerClass
		}).append(
			params.thumbnails
		);
		if(params.thumbnailsDirectlyInMainCntr) {
			thumbnailsCntr = params.thumbnails;
		}
		zoomContainer.append(thumbnailsCntr);

		if(typeof params.afterZoomCntrWasConstructed === "function") {
			zoomContainer = params.afterZoomCntrWasConstructed(zoomContainer);
		}

		return zoomContainer;
	}
	function initThumbnailsScroll(params) {
		var images = params.thumbnails.find('img');
		var promises = [];

		for (var i = 0, len = images.length; i < len; ++i) {
			promises.push(app.components.global.images.imageLoaded(images.eq(i)));
		}

		$.when.apply($, promises).done(function() {
			var sliderSettings = $.extend({}, $cache.defaultThumbnailsScrollSettings, params.thumbnailsScrollSettings);
			params.thumbnails.closest(params.thumbnailsContainerSel).thumbnailsSlider(sliderSettings);
		});
	}
	function initThumbnailsClickEvent(params) {
		params.thumbnails.on("click", params.thumbnailSel, function(e) {
			var clickedThumbnail = $(e.currentTarget);
			params.thumbnails
				.find(params.thumbnailSel)
				.removeClass(params.thumbnailSelectedClass);
			clickedThumbnail.addClass(params.thumbnailSelectedClass);

			var lgimgData = clickedThumbnail.find(params.thumbnailImgSel).data("lgimg");
			if(lgimgData) {
				params.zoomedImg.attr({
					src: lgimgData.url,
					alt: lgimgData.alt,
					title: lgimgData.title
				});
			} else {
				var clickedThumbnailSrc = app.util.removeDISParameters(clickedThumbnail[0].src);

				params.zoomedImg.attr({
					src: clickedThumbnailSrc,
					alt: clickedThumbnail[0].alt,
					title: clickedThumbnail[0].title
				});
			}
		});
	}
	function initAutoImgScroll(params) {
		var image = params.zoomedImg.css('position', 'relative');
		var deltaPrc = 0;
		var startPosition = null;
		var scope = params.zoomedImg.closest(params.zoomedImgContainerSel);
		var moveActive = false;
		function mouseMove() {
			image.css('margin-top', -deltaPrc + 'px');
		}

		scope.on('mousemove', function(e) {
			if (!startPosition) {
				startPosition = e.clientY;
			}

			if (!moveActive && Math.abs(startPosition - e.clientY) > 10) {
				moveActive = true;
				scope.on('mousemove', function(e) {
					if (image.height() > window.innerHeight) {
						deltaPrc = parseInt((image.height() - window.innerHeight) * parseFloat(e.clientY / window.innerHeight));
					}

					app.util.throttle(mouseMove, 1);
				});
			}
		});
	}

	app.zoom = {
		init: function() {
			if (!initialized) {
				initializeCache();
				initialized = true;
			}
		},
		showZoomedImg: function(params) {

			if(!initialized) {
				console.warn("Attempt to zoom before the zoom was initialized.");
				return;
			}

			params = $.extend({}, defaultParams, params);

			var zoomContainer = null;
			if(params.providedZoomCntr) {
				zoomContainer = params.providedZoomCntr;
			} else {
				zoomContainer = createZoomedContainer(params);
			}

			app.fancybox.open(params.source, {
				content: zoomContainer,
				width: "100%",
				height: window.outerHeight,
				margin: 0,
				padding: 0,
				topRatio: 0,
				wrapCSS: params.fancyBoxWrapperClass,
				autoSize: false,
				beforeShow: function() {
					if (params.zoomedImg && params.zoomedImg.length) {
						var imgUrl = params.zoomedImg.attr('src');
						imgUrl = app.util.removeDISParameters(imgUrl);
						params.zoomedImg.attr('src', imgUrl);
					}
				},
				afterShow: function() {
					if(typeof params.afterShow === "function") {
						params.afterShow({
							content: this.content
						});
					} else {
						if(params.thumbnails) {
							initThumbnailsScroll(params);
							initThumbnailsClickEvent(params);
						}

						if (params.bodyClass) {
							$('body').addClass(params.bodyClass);
						}

						params.zoomedImg.on('click', function() {
							if (typeof params.onImgClick === 'function') {
								params.onImgClick();
							} else {
								app.fancybox.close();
							}
						});
						initAutoImgScroll(params);
					}

					if(typeof params.onCloseClick === "function") {
						$(params.fancyBoxWrapperSel).find($cache.selectors.fancyBoxClose).on("click", function() {
							return params.onCloseClick();
						})
					}
				},
				beforeClose: function() {
					if (params.bodyClass && $('body').hasClass(params.bodyClass)) {
						$('body').removeClass(params.bodyClass);
					}
				}
			});
		},
		initAutoImgScroll: initAutoImgScroll
	};
}(window.app = window.app || {}, jQuery));
