/**
 * @class app.dynamicpromobanner
 */

(function (app, $) {
	var $cache = {};
	var bannerDefaultSettings = {
		closeButton: true
	};
	var carouselDefaultSettings = {
		items: 1,
		autoplayHoverPause: true,
		autoplay: true,
		loop: true,
		animateIn: 'fadeIn',
		smartSpeed: 100,
		autoHeight: false
	};

	function initializeCache() {
		$cache = {
			document: $(document),
			dynamicPromoBanner: $('.js-dynamic-promo-banner'),
			promoBannerCloseSel: '.header-promo-close',
			dynamicPromoBannerCookieName: app.preferences.promoBannerCookieName,
			hMinimized: "h-minimized"
		};
	}

	function initializeDOM () {
		if ($cache.dynamicPromoBanner.length && !$.cookie($cache.dynamicPromoBannerCookieName)) {
			var bannerSettings = $cache.dynamicPromoBanner.data("settingsConfig");
			var carouselSettings = $cache.dynamicPromoBanner.data("settings");
			var bannerSettingsExtended = $.extend({}, bannerDefaultSettings, bannerSettings);
			var carouselSettingsExtended = $.extend({}, carouselDefaultSettings, carouselSettings);

			$cache.dynamicPromoBanner.data("settings", carouselSettingsExtended);
			app.owlcarousel.initCarousel($cache.dynamicPromoBanner);

			if (bannerSettingsExtended.closeButton) {
				$cache.dynamicPromoBanner.each(function() {
					var $this = $(this);
					if (!$this.find($cache.promoBannerCloseSel).length) {
						$this.append('<div class="header-promo-close"><a class="js-header-promo-close" aria-label="close promotional banner" href="javascript:void(0)">x</a></div>');
					}
				});
			}

			$cache.dynamicPromoBanner.removeClass($cache.hMinimized);
			$cache.dynamicPromoBanner.show();
			$cache.document.trigger('promoBanner.show');
		}
	}

	app.dynamicpromobanner = {
		init: function () {
			initializeCache();
			initializeDOM();
		}
	};
}(window.app = window.app || {}, jQuery));