(function(app) {
	function Notification(config) {
		config.containerClass = 'b-product-configurator-notification-item js-product-configurator-notification-item';
		config.textClass = 'b-product-configurator-notification-item-text';
		config.closeButtonClass = 'b-product-configurator-notification-close js-configurator-notification-close';
		app.notification.General.call(this, config);
	}

	Notification.prototype = Object.create(app.notification.General.prototype);
	Notification.prototype.constructor = app.notification.Notification;

	app.configurator = app.configurator || {};
	app.configurator.Notification = Notification;
})((window.app = window.app || {}), jQuery);
