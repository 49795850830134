/* global define*/
define('app.components.global.scrollToTop', function (require, exports) {

	var $cache = {},
		$ = require('$'),
		$window = require('$win'),
		$document = require('$doc'),
		scrollScreensToShowMobile = app.util.getConfig('fullpageMobile.disable') ? 1.5 : 1,
		hiddenMobileClass = 'h-hidden',
		hiddenDesktopClass = 'h-opaque',
		scrollToTopScrollButtonTransformClass = 'js-scroll_to_top_transform';

	function initializeCache() {
		$cache.scrollToTopButton = $('.js-scroll_to_top');
		$cache.scrollToTopMobileButton = $('.js-scroll_to_top_mobile');
		$cache.content = $('.js-search_result-content');
		$cache.content = $cache.content.length ? $cache.content : $('main');
	}

	function initializeMobileEvents(config) {
		if (config.scrollScreensToShowMobile) {
			scrollScreensToShowMobile = config.scrollScreensToShowMobile;
		}
		var scrollToTopWidth = getScrollToTopHeight(),
			stateHidden = true;
		
		if (scrollToTopWidth) {
			$window.on('scroll', function () {
				if ($document.scrollTop() > scrollToTopWidth && stateHidden) {
					$cache.scrollToTopMobileButton.removeClass(hiddenMobileClass);
					stateHidden = false;
				}
				else if($document.scrollTop() <= scrollToTopWidth && !stateHidden) {
					$cache.scrollToTopMobileButton.addClass(hiddenMobileClass);
					stateHidden = true;
				}
				$cache.scrollToTopMobileButton.removeClass(scrollToTopScrollButtonTransformClass);
			});

			$window.on('fullpage.scroll.transform', function(e, v){
				if ((v.sectionIndex + 1) * $window.innerHeight() > scrollToTopWidth && stateHidden) {
					$cache.scrollToTopMobileButton.removeClass(hiddenMobileClass);
					stateHidden = false;
				}
				else if((v.sectionIndex + 1) * $window.innerHeight() <= scrollToTopWidth && !stateHidden) {
					$cache.scrollToTopMobileButton.addClass(hiddenMobileClass);
					stateHidden = true;
				}
				$cache.scrollToTopMobileButton.addClass(scrollToTopScrollButtonTransformClass);
			});

			$cache.scrollToTopButton.on('click', scrollToTop);
		}
	}

	function initializeEvents() {
		var offset = screen.availHeight / 4,
			stateHidden = true;

		$window.on('scroll', function () {
			if ($(this).scrollTop() > offset && stateHidden) {
				$cache.scrollToTopButton.addClass(hiddenDesktopClass);
				stateHidden = false;
			}
			else if ($(this).scrollTop() < offset && !stateHidden) {
				$cache.scrollToTopButton.removeClass(hiddenDesktopClass);
				stateHidden = true;
			}
		});

		$cache.scrollToTopButton.on('click', scrollToTop);
	}
	
	function scrollToTop() {
		if ($(this).hasClass(scrollToTopScrollButtonTransformClass)) {
			$.fn.fullpage.moveTo(1);
		} else {
			var duration = 500;
			$('html, body').animate({scrollTop: 0}, duration);
		}
		$document.trigger('scrolled.totop');
	}
	
	function getScrollToTopHeight() {
		var headerHeight = $cache.content.offset() ? parseInt($cache.content.offset().top) : 0,
			elementHeight = 0;

		return $window.height() * scrollScreensToShowMobile - headerHeight;
	}

	exports.init = function (config) {
		initializeCache();
		if (require('device').isMobileView()) {
			initializeMobileEvents(config);
		} else {
			initializeEvents();
		}
	};
});
