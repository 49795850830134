(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			dataVideoPlayedAttr: 'data-video-played',
			loadedCls: 'm-loaded',
			vimeoContainerSel: '.js-vimeo-container',
			vimeoPlayerSel: '.js-vimeo-player',
			videoThumbnailSel: '.js-thumbnail-vimeo',
			productTileImgCls: 'js-producttile_image'
		};
	}

	function initSpecPlpVideoBlock($cachedata) {
		// change video/frame block height due to design impossibility
		$cachedata.video2x2.height(function(e, val) {
			return val * 2;
		});
		// change video/frame block height in case of window resize
		$(window).resize(function() {
			$cachedata.video2x2.height($cachedata.specPlpBot.height() * 2);
		});
	}

	function initVimeo(thumbnailsSel, options) {
		$cache.document.find(thumbnailsSel).each(function() {
			var thumbnailData = {
				thumbnail: $(this),
				isThumbnailLoaded: false,
				options: options
			};
			if (thumbnailData.thumbnail.attr('src')) {
				thumbnailData.isThumbnailLoaded = true;
				$cache.document.trigger('vimeo.init', thumbnailData);
			} else {
				thumbnailData.thumbnail.load(function() {
					thumbnailData.isThumbnailLoaded = true;
					$cache.document.trigger('vimeo.init', thumbnailData);
				});
			}
		});
	}

	function destructVimeo(thumbnailsSel) {
		$cache.document.find(thumbnailsSel).each(function() {
			var thumbnail = $(this);
			thumbnail.attr($cache.dataVideoPlayedAttr, false).removeClass($cache.loadedCls);
			thumbnail.parents($cache.vimeoContainerSel).find($cache.vimeoPlayerSel).html('');
			thumbnail.parents($cache.vimeoContainerSel).find($cache.videoThumbnailSel).removeClass($cache.productTileImgCls);
		});
	}


	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.video = {
		init: function() {
			initializeCache();
		},
		initSpecPlpVideoBlock: initSpecPlpVideoBlock,
		initVimeo: initVimeo,
		destructVimeo: destructVimeo
	};
})(window.app = window.app || {}, jQuery);
