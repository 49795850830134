/**
 * Represents FormFieldFactory
 */
class CustomerUtils {
	/**
	 * Request header customer Info
	 * @param {Function} callback Callback function
	 * @returns {Promise}
	 */
	requestHeaderCustomerInfo(callback) {
		return fetch(app.urls.getHeaderCustomerInfo, {
			credentials: 'include'
		}).then((res) => res.text())
			.then((markup) => callback ? callback(markup) : null);
	}
}

export default new CustomerUtils();
