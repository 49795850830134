import CSRF from 'oneapp/src/utils/CSRF';
import CustomerUtils from 'oneapp/src/utils/CustomerUtils';

(function(app, $) {
	var $cache = {};
	var iframeLogin = null;
	var iframeTarget = 'loginiframe';
	var submitEvent = Modernizr.touchevents ? 'touchstart' : 'click';
	var eventMessage = 'message';
	var loginFormFields = {};
	var communityLoginFormFields = {};
	var messageToPost = null;
	var timer = {
		id: null,
		clear: function() {
			if (timer.id) {
				window.clearTimeout(timer.id);
				delete timer.id;
			}
		},
		start: function(duration) {
			timer.id = setTimeout(app.components.account.fakelogin.closelogin, duration);
		}
	};
	var configs = {
		checkoutHideOnMouseLeave: true
	};
	var timeOut = app.preferences.fakeloginTimeout;

	function initializeConfigs(params) {
		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function sendToIframe(message) {
		var $iframeContainer = $($cache.iframeContainerSel);
		if ($iframeContainer.length) {
			$iframeContainer.empty();
			$iframeContainer.append('<iframe id="js-login_iframe" src="' +
				$iframeContainer.data('iframeUrl') + '"/>');
			messageToPost = message;
		}
	}

	function initializeCache() {
		$cache = {
			dropdownActiveClass: 'b-login_dropdown-active',
			linkActiveClass: 'b-login_header-link-active',
			fakeSubmitSel: '.js-fake_submit',
			fieldsForIframeSel: '.js-fields_for_iframe',
			errorFormTextSel: '.js-error_form',
			minimizedClass: 'h-minimized',
			loginFormSel: 'form.js-login_account-form',
			loginLink: $('.js-fakelogin-link'),
			wrapper: $('.js-login_dropdown'),
			wrapperSel: '.js-login_dropdown',
			loginFlyout: $('.js-login_dropdown-flyout'),
			loginHeaderTitle: $('.js-login_dropdown-title'),
			loginFlyoutWrapper: $('.js-login-flyout-wrapper'),
			checkoutRefreshForm: $('#checkoutRefreshForm'),
			iframeContainerSel: '.js-login_iframe_container',
			document: $(document),
			wrapperHoverClass: $('.js-login_dropdown').data('custom-class'),
			wishlistBlockLoginSel: '.js-wishlistblock-login',
			externalPlaceholderSel: '.js-external_placeholder',
			focusedInputSel: 'input:focus',
			communityTokenCookieName: 'communityToken',
			communityCookieName: 'communityRedirect',
			csrfTokenName: 'csrf_token'
		};

		loginFormFields = {
			username: '#dwfrm_login_username',
			password: '#dwfrm_login_password',
			newsletter: '#dwfrm_login_signup',
			rememberme: '#dwfrm_login_rememberme',
			newsfor: '[name="dwfrm_newsletter_simple_newsfor"]:checked'
		};

		communityLoginFormFields = {
			username: '#dwfrm_login_community_username',
			password: '#dwfrm_login_community_password',
			newsletter: '#dwfrm_login_community_signup',
			rememberme: '#dwfrm_login_community_rememberme',
			newsfor: '[name="dwfrm_newsletter_simple_newsfor"]:checked',
			community: '#dwfrm_login_community_communitylogin'
		};
	}

	function initializeEvents() {
		if (configs.checkoutHideOnMouseLeave) {
			$cache.wrapper.on('mouseenter toggle', function(e) {
				e.stopPropagation();
				showPopup.call(this, e);
			})
				.on('mouseleave', function(e) {
					if (!$cache.loginLink.find($cache.wrapperSel).length) {
						hidePopup(e);
					}
				});

			$cache.loginLink.on('mouseenter touchstart toggle', function(e) {
				if (this === e.target) {
					e.stopPropagation();
					showPopup.call(this, e);
				}
			})
				.on('mouseleave', function(e) {
					hidePopup(e);
				});

			$cache.document.on('touchstart', function(e) {
				e.stopPropagation();
				ipadHidePopup.call(this, e);
			});
		}

		$cache.document.on('fakelogin.reinitcache', function() {
			initializeCache();
		});

		/*
		 * catch fake login submit and send message to iframe
		 */
		$cache.document.on(submitEvent, $cache.fakeSubmitSel, function(e) {
			submitInIframe(e);
			return false;
		});

		$cache.document.on('submit', $cache.loginFormSel, function(e) {
			submitInIframe(e);
			return false;
		});
		/*
		 * catch message events from login iframe
		 */

		$(window).on(eventMessage, function(e) {
			var originUrl = e.originalEvent.origin,
				message,
				iframe;

			if (originUrl === getIframeUrl()) {
				// sign in user after iframe is ready
				if (e.originalEvent.data === 'loginiframe.ready') {
					iframe = document.getElementById('js-login_iframe');
					if (iframe && messageToPost) {
						iframe.contentWindow.postMessage(JSON.stringify(messageToPost), getIframeUrl());
						messageToPost = '';
					}
					return;
				}

				try {
					message = JSON.parse(e.originalEvent.data);
				} catch (error) {
				}

				if (message && message.oneapp && message.oneapp.target === iframeTarget) {
					var response = message.data;
					if (response.success) {
						document.dispatchEvent(new Event('login.success'));

						if (response.communityToken) {
							$.cookie($cache.communityTokenCookieName, response.communityToken, { path: '/' });
						}

						if (response.communityRedirect) {
							$.cookie($cache.communityCookieName, 'true', { path: '/' });
						}

						var isRedirectToWishlist = new URL(window.location.href).searchParams.get('redirectToWishlist');

						if (isRedirectToWishlist) {
							app.page.redirect(app.urls.wishlistShowAbs);
						}

						if (app.page.type === 'orderconfirmation') {
							window.location.href = app.urls.homePage;
						}
						else if ($cache.checkoutRefreshForm.length) {
							CSRF.populateFormByCSRFToken($cache.checkoutRefreshForm[0], true)
								.then(() => $cache.checkoutRefreshForm.submit());
						}
						else {
							$cache.document.trigger('account.fakelogin.beforeRefresh');
							var onLogin = app.device.isMobileView() ? app.preferences.fakeloginMobileOnlogin : app.preferences.fakeloginOnlogin;
							var isNohitsWithWishlistLoginBlock = app.page.ns === 'search' && $($cache.wishlistBlockLoginSel).length > 0;
							if ('refresh' === onLogin || isNohitsWithWishlistLoginBlock) {
								app.page.refresh(true);
							} else {
								var onLoginPipeline = app.device.isMobileView() ? app.preferences.fakeloginMobileOnloginPipeline : app.preferences.fakeloginOnloginPipeline;
								app.page.redirect(onLoginPipeline);
							}
						}
					} else if (response.redirectURL) {
						document.dispatchEvent(new Event('login.success'));
						app.page.redirect(response.redirectURL);
					} else if (response.error) {
						$($cache.errorFormTextSel).text(response.error).show();
					}
				}
			}
		});

		if (app.preferences.showFakeloginOnHover === 'true') {
			$cache.loginHeaderTitle.on('mouseenter', function() {
				app.components.account.fakelogin.show();
			});
		}
	}

	function hidePopup(e) {
		if (e.relatedTarget != null) {
			if ($cache.loginLink.length) {
				$cache.wrapper.removeClass($cache.wrapperHoverClass)
					.addClass($cache.minimizedClass)
					.removeClass($cache.dropdownActiveClass);
				$cache.loginLink.removeClass($cache.linkActiveClass);
				$cache.wrapper.find($cache.fieldsForIframeSel).find($cache.focusedInputSel).blur();
			} else {
				$cache.wrapper.removeClass($cache.wrapperHoverClass);
			};

			if (navigator.userAgent.match(/iPad/i)) {
				$cache.document.off('touchstart', function(event) {
					event.stopPropagation();
					ipadHidePopup.call(this, event);
				});
			}
		}
	};

	function ipadHidePopup(e) {
		var $clicked = $(e.target);
		e.relatedTarget = e.target;

		if ($clicked !== $cache.wrapper && !($clicked.parents($cache.wrapperSel).length)) {
			hidePopup(e);
		}
	};

	function showPopup() {
		function processPopupOpening() {
			var $this = $(this);

			if ($cache.loginLink.length) {
				$cache.wrapper.addClass($cache.wrapperHoverClass)
					.removeClass($cache.minimizedClass)
					.addClass($cache.dropdownActiveClass);
				$cache.loginLink.addClass($cache.linkActiveClass);
				// related to browser autofill and overlapp placeholders
				$this.find($cache.externalPlaceholderSel + ':not(.f-state-error)').trigger('focusin');
			} else {
				$cache.wrapper.addClass($cache.wrapperHoverClass);
			}
		}

		if ($cache.wrapper.length) {
			processPopupOpening.call(this);
		} else {
			CustomerUtils.requestHeaderCustomerInfo((function(markup) {
				if ($($cache.wrapperSel).length === 0 && markup && $cache.loginFlyoutWrapper.length) {
					$cache.loginFlyoutWrapper.append(markup);
					$cache.wrapper = $($cache.wrapperSel);
					$cache.wrapperHoverClass = $cache.wrapper.data('custom-class');
					$cache.document.trigger('login.flyout.update');
				}

				processPopupOpening.call(this);
			}).bind(this));
		}
	};

	function submitInIframe(e) {
		e.preventDefault();
		var wrapperClass = $(e.target).data('wrapper'),
			$loginForm = $((wrapperClass ? wrapperClass + ' ' : '') + $cache.loginFormSel);

		$((wrapperClass ? wrapperClass + ' ' : '') + $cache.errorFormTextSel).hide();
		$loginForm.validate();
		if (!$loginForm.valid()) {
			return false;
		}
		if ($($cache.iframeContainerSel).length) {
			var $fieldsForIframe = $((wrapperClass ? wrapperClass + ' ' : '') + $cache.fieldsForIframeSel),
				formData;

			if ($fieldsForIframe.find(communityLoginFormFields.community).length) {
				formData = {
					username: $fieldsForIframe.find(communityLoginFormFields.username).val() || '',
					password: $fieldsForIframe.find(communityLoginFormFields.password).val() || '',
					newsletter: $fieldsForIframe.find(communityLoginFormFields.newsletter).prop('checked') || false,
					rememberme: $fieldsForIframe.find(communityLoginFormFields.rememberme).prop('checked') || false,
					newsfor: $fieldsForIframe.find(communityLoginFormFields.newsfor).val() || '',
					community: $fieldsForIframe.find(communityLoginFormFields.community).val() || ''
				};
			} else {
				formData = {
					username: $fieldsForIframe.find(loginFormFields.username).val() || '',
					password: $fieldsForIframe.find(loginFormFields.password).val() || '',
					newsletter: $fieldsForIframe.find(loginFormFields.newsletter).prop('checked') || false,
					rememberme: $fieldsForIframe.find(loginFormFields.rememberme).prop('checked') || false,
					newsfor: $fieldsForIframe.find(loginFormFields.newsfor).val() || '',
				};
			}

			formData[$cache.csrfTokenName] = $fieldsForIframe.find('input[name$="csrf_token"]')?.get(0)?.value

			sendToIframe({
				formData: formData,
				oneapp: { target: iframeTarget }
			});

			return;
		}
	}

	/*
	 * get origin url for post message to iframe
	 */
	function getIframeUrl() {
		var $iframeContainer = $($cache.iframeContainerSel);
		if ($iframeContainer.length) {
			var obj = app.util.getUri($iframeContainer.data('iframeUrl'));
			return obj.protocol + '//' + obj.hostname;
		}
		return 'https://' + window.location.host;
	}

	/**
	 * @namespace app.components.account.fakelogin public object
	 **/
	app.components = app.components || {};
	app.components.account = app.components.account || {};
	app.components.account.fakelogin = {

		init: function(params) {
			submitEvent = (params && 'submitEvent' in params) ? params['submitEvent'] : submitEvent;
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},

		show: function() {
			timer.clear();

			// show the item
			$cache.loginFlyout.addClass('h-show');
			$cache.loginHeaderTitle.addClass('h-toggled');
			// after a time out automatically close it
			timer.start(timeOut);
		},

		closelogin: function() {
			timer.clear();
			$cache.loginFlyout.removeClass('h-show');
			$cache.loginHeaderTitle.removeClass('h-toggled');
		},

		getIframe: function() {
			return iframeLogin;
		},
		sendMessage: sendToIframe,
		showPopup: showPopup,
		hidePopup: hidePopup
	};
}(window.app = window.app || {}, jQuery));
