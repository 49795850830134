/*
 * Module for working with UPS
 */

(function (app, $) {
	var $cache = {},
		$document = $(document),
		COUNTRY_LEVEL_ZOOM = 7,
		GoogleMap = null,
		markers = [],
		bounds = typeof google !== 'undefined' ? new google.maps.LatLngBounds() : null;
	
	function checkUpsApZipOrCity() {
		var countryCode = $cache.currentLocationCountry.val(),
			postalCode  = $cache.postalCode.val() + '';
		
		if (postalCode) {
			var params = {"country" : countryCode};
			// detect zipCode
			if (/\d/.test(postalCode)) {
				if (app.validator.validateZipByCountry(countryCode, postalCode)) {
					params.zip = postalCode;
				} else {
					errorShow($cache.errors.zip);
				}
			} else {
				params.city = postalCode;
			}
			findUpsAp(params);
		}
	}
	
	// Finding nearest ups access points by user's geolocation if delivery to ups ap selected  first time
	var upsApSelected = function () {
		if($cache.upslocatorMapHolder.length) {
			if (GoogleMap == null && !app.device.isMobileUserAgent()) {
				app.geolocation.getCurrentPosition()
					.done(function(params){
						var countryCode = $cache.currentLocationCountry.val();
						params = $.extend(params, {"notshowerror" : true, "country" : countryCode});
						findUpsAp(params);
					})
					.fail(function(){
						$cache.upslocatorMapHolder.addClass($cache.hHide);
						console.warn('The requested geolocation server does not return a data or timeout is exceeded');
					});
			}
		}
	}

	var selectUpsAp = function (id) {
		$cache.apChange.removeClass($cache.hHide);
		$cache.shipToStoreWrap.trigger($cache.events.storeSelected, {"storeid": id});
	}

	var upsApChange = function () {
		if ( $cache.apChange.length !== 0 ) {
			$cache.apChange.addClass($cache.hHide);
			$cache.postalCode.trigger('focusout');
			checkUpsApZipOrCity();
		}
	}

	this.findUpsAp = function (data) {
		if(data && (data.zip || data.city || (data.lat && data.lng))) {
			data.format = "ajax";
			addLoader($cache.upslocatorMapHolder);
			
			var locatorUrl = $cache.urls.findUpsAp;
			locatorUrl = app.util.appendParamsToUrl(locatorUrl, data);
			$.ajax({
				url : locatorUrl,
				success: function(response) {
					if ($(response).find($cache.jsGmap).length > 0) {
						$cache.upslocatorStoreList.show();
						$cache.upslocatorMap.css('width', '');
						$cache.upslocatorList.html(response);
						$cache.upslocatorList.scrollbar({
							"ignoreMobile": false,
							"disableBodyScroll": true
						});
						!app.preferences.isMobileView && upsApDisplayMap(response);
					} else if (!app.preferences.isMobileView && data.lat && data.lng) {
						$cache.upslocatorStoreList.hide();
						$cache.upslocatorMap.css('width', '100%');
						upsApDisplayMap(null, data.lat, data.lng);
					} else {
						!data.notshowerror && errorShow($cache.errors.noap);
					}
					removeLoader($cache.upslocatorMapHolder);
				},
				error: function() {
					!data.notshowerror && errorShow($cache.errors.noap);
					removeLoader($cache.upslocatorMapHolder);
				}
			});
		} else {
			errorShow($cache.errors.zip);
		}
	}
	
	// Country drop down selector
	this.setAllMap = function (map) {
		for (var i = 0; i < markers.length; i++) {
			markers[i].setMap(map);
		}
	}

	// Showing map with founded UPS Access Points
	this.upsApDisplayMap = function (response, lat, lng, centerOnly) {
		var storeItems = $(response).find($cache.jsGmap); 
		
		if (GoogleMap == null) {
			$cache.upslocatorMap.empty();
			var gMmap = new google.maps.Map($cache.upslocatorMap[0], {
				zoom      : Number(app.preferences.storeLocatorMapZoom),
				mapTypeId : google.maps.MapTypeId.ROADMAP,
				scrollwheel : false
			});				
			var gMarker = new google.maps.Marker();
			GoogleMap = {
				map		: gMmap,
				marker	: gMarker
			};
		} else {
			setAllMap(null);
			markers = [];
		}
		
		if (storeItems.length !== 0) {
			var mapLat = storeItems.data("latitude"),
				mapLng = storeItems.data("longitude"),
				mTitle = storeItems.data("title"),
				latLng = new google.maps.LatLng(mapLat, mapLng);

				var gMarker = new google.maps.Marker({
					position : latLng,
					map      : gMmap,
					title    : mTitle
				});
				markers.push(gMarker);
				bounds = new google.maps.LatLngBounds();
				GoogleMap.marker.setPosition(latLng);
				GoogleMap.map.panTo(latLng);
				GoogleMap.map.setCenter(latLng);
				GoogleMap.map.setZoom(Number(app.preferences.storeLocatorMapZoom));
			
			if (!centerOnly) {
				storeItems.each(function(){
					var mapLat = $(this).data("latitude"),
						mapLng = $(this).data("longitude"),
						mTitle = $(this).data("title"),
						mInfo = $(this).data("info"),
						storeID = $(this).data("id")
						latLng = new google.maps.LatLng(mapLat, mapLng);
						mInfo += '<div class="s-select-store_wrap"><button class="js-select-upsap-store" data-id="'+ storeID +'">'+app.resources['SELECT']+'</button></div>'; 
					if ($cache.jsShowCity.val() === 'true') {
						if ($cache.postalCode.val() === "") {
							var gMarker = new google.maps.Marker({
								position	: latLng
							});		
						} else {
							var gMarker = new google.maps.Marker({
								position	: latLng,
								map			: GoogleMap.map,
								title		: mTitle
							});
						}
					} else {
						var gMarker = new google.maps.Marker({
							position	: latLng,
							map			: GoogleMap.map,
							title		: mTitle
						});
					}
					var infowindow = new google.maps.InfoWindow({
						content: $('<div/>').addClass($cache.storelocatorMapInfoWindow).html(mInfo)[0]
					});					
					markers.push(gMarker);
					google.maps.event.addListener(gMarker, 'click', function() {
						var content = infowindow.getContent();
						GoogleMap.map.setZoom(Number(app.preferences.storeLocatorMapZoom));
						GoogleMap.map.setCenter(gMarker.getPosition());
						infowindow.setContent(mInfo);
						infowindow.open(GoogleMap.map,gMarker);
					});

					$(this).on("click", function(e){
						e.preventDefault();
						GoogleMap.map.setZoom(Number(app.preferences.storeLocatorMapZoom));
						GoogleMap.map.setCenter(gMarker.getPosition());
						infowindow.open(GoogleMap.map,gMarker);
						$('html, body').animate({scrollTop: 0});
					});
					bounds.extend(gMarker.position);
				});
				GoogleMap.map.fitBounds(bounds);
			} else {
				GoogleMap.map.setZoom(COUNTRY_LEVEL_ZOOM);
			}
		} else {
			if (lat != null && lng != null) {
				var latLng = new google.maps.LatLng(lat, lng);
				GoogleMap.map.setCenter(latLng);
				GoogleMap.map.setZoom(10);
			} else {
				errorShow($cache.errors.zip);
			}
		}
	}
	
	function errorShow(error) {
		var errorObject = {};
		errorObject[$cache.postalCodeName] = $cache.zipError.data(error);
		$cache.validator.showErrors(errorObject);
	}
	
	function addLoader($elem, addedClass){
		if(addedClass != null){
			$elem.addClass(addedClass);
		}
		$cache.upslocatorMapHolder.removeClass($cache.hHide);
		$('<div/>', {class : $cache.loaderClass}).appendTo($elem);
	}
	
	function removeLoader($elem, removedClass){
		if(removedClass != null){
			$elem.removeClass(removedClass);
		}
		$elem.find('.' + $cache.loaderClass).remove();
	}	
	
	function initializeCache() {
		$cache = {
			currentLocationCountry : $(".js-current_location-country"),
			upslocatorMapHolder: $('.js-upsaplocator'),
			upslocatorList : $('.js-upsaplocator_storelist'),
			upslocatorStoreList : $('.js-upsaplocator_list'),
			shipToStoreWrap : $('.js-store_selector_wrap'),
			apChange : $('.js-upsap-change'),
			upslocatorMap : $('.js-upsaplocator_map'),	
			postalCode : $('.js-storelocator_postalcode'),
			zipError : $('.js-ziperror'),
			zipErrorMess : $('.js-ziperror-mess'),
			noUpsApError : $('.js-noupsaperror'),
			jsShowCity : $('.js-showcity'),
			selectUpsApStoreButt : '.js-select-upsap-store',
			storelocatorMapInfoWindow : 'b-storelocator-map-info_window',
			loaderClass : 'lazyloadxt-indicator',
			findButtonSel: '.js-button_find',
			shipToStoreWrapSel: '.js-store_selector_wrap',
			jsGmap: '.js-gmap',
			hHide: 'h-hidden',
			hShow: 'h-show',
			checkoutForm: $('.js-checkout_form'),
			postalCodeName: app.forms.names.storelocatorPostalCode
		};
		$cache.events = {
			storeSelected : 'store.selected',
			storeChange : 'store.change',
			upsapSearch : 'upsap.search'
		};		
		$cache.errors = {
			zip : 'ziperror',
			noap : 'nostoreserror'
		};		
		$cache.urls =  {
			findUpsAp: app.urls.findUpsAP
		};
		$cache.validator =  ($cache.checkoutForm.length > 0) ? $cache.checkoutForm.validate() : undefined;
	}

	function initializeEvents() {
		$document.on($cache.events.upsapSearch, $cache.findButtonSel, function(e) {
			e.preventDefault();
			checkUpsApZipOrCity();
		});
		$document.on($cache.events.storeChange, $cache.shipToStoreWrapSel, function() {
			upsApChange();
		});
		$document.on('click', $cache.selectUpsApStoreButt, function(e) {
			e.preventDefault();
			selectUpsAp($(this).data("id"));
		});
		if (!$cache.postalCode.val()) {
			upsApSelected();
		}
	}
	
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.ups = {
		init : function (params) {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);