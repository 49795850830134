import CalendarBuilder from './Builder';
import CalendarDirector from './Director';

/**
 * @typedef {Object} StatusObject
 * @property {boolean} success - Indicates the success status of the operation.
 * @property {string} [message] - The error message associated with the status (if applicable).
 */

const selectors = {
	prevButton: '.js-calendar_prev-button',
	nextButton: '.js-calendar_next-button',
	daysButton: '.js-calendar_day-button:not(.disabled):not(.invisible)',
	calendar: '.js-calendar',
	formFiled: '.js-shipping-date'
};

const classes = {
	wrapper: 'b-cart_shipping_method-list_wrapper--appointment'
};

/**
 * Represents a Shipping Calendar.
 */
class ShippingCalendar {
	/**
	 * Constructs a ShippingCalendar instance.
	 * @param {Object} options - The options object.
	 * @param {Element} options.targetElement - The target element to render the calendar.
	 * @param {Date} options.date - The initial date for the calendar.
	 * @param {number} options.daysLimit - The number of days to limit in the calendar.
	 * @param {Object} options.actions - The actions to perform on checkout events.
	 * @param {string} options.id - The ID of the calendar.
	 * @param {Date} options.selectedDate - The initially selected date for the calendar.
	 */
	constructor({ targetElement, date, daysLimit, actions, id, selectedDate }) {
		this.statuses = {
			DATE_NOT_SELECTED: 'DATE_NOT_SELECTED',
			SUCCESS: 'SUCCESS'
		};
		this.errors = {
			[this.statuses.DATE_NOT_SELECTED]: {
				message: app.resources.CALENDAR_DATE_NOT_SELECTED
			}
		};
		this.targetElement = targetElement;
		this.actions = actions;
		this.id = id;
		this.calendarProps = {
			date,
			monthShift: 0,
			daysLimit,
			selectedDate,
			error: null
		};

		init.call(this);
	}

	/**
	 * Increases the month shift value.
	 */
	increaseMonthShift() {
		this.calendarProps.monthShift += 1;

		reInit.call(this);
	}

	/**
	 * Decreases the month shift value.
	 */
	decreaseMonthShift() {
		this.calendarProps.monthShift -= 1;

		reInit.call(this);
	}

	/**
	 * Displays an error message and performs necessary actions.
	 * @param {StatusObject} error - The error message to be displayed.
	 */
	showErrorMessage(error) {
		this.calendarProps.error = error;

		reInit.call(this);

		this.targetElement.scrollIntoView({ behavior: 'smooth' });
	}

	/**
	 * Validates the calendar properties.
	 * @returns {StatusObject} - The status of the validation.
	 */
	validate() {
		if (!this.calendarProps.selectedDate) {
			return getStatus.call(this, this.statuses.DATE_NOT_SELECTED);
		}

		return getStatus.call(this, this.statuses.SUCCESS);
	}
}

/**
 * Initializes the ShippingCalendar.
 * @this {ShippingCalendar} The ShippingCalendar instance.
 */
function init() {
	this.builder = new CalendarBuilder(this.targetElement);
	this.director = new CalendarDirector(this.builder);
	this.calendarTemplate = document.getElementById('calendar');

	render.call(this);
	initEvents.call(this);
}

/**
 * Re-initializes the component by calling the render and initEvents functions.
 * @this {ShippingCalendar} The ShippingCalendar instance.
 */
function reInit() {
	render.call(this);
	initEvents.call(this);
}

/**
 * Initializes the events for the ShippingCalendar.
 * @this {ShippingCalendar} The ShippingCalendar instance.
 */
function initEvents() {
	const prevButtonNode = this.targetElement.querySelector(selectors.prevButton);
	const nextButtonNode = this.targetElement.querySelector(selectors.nextButton);
	const daysButtons = this.targetElement.querySelectorAll(selectors.daysButton);

	if (nextButtonNode) {
		nextButtonNode.addEventListener('click', () => {
			this.increaseMonthShift();
		});
	}

	if (prevButtonNode) {
		prevButtonNode.addEventListener('click', () => {
			this.decreaseMonthShift();
		});
	}

	for (const dayButtonNode of daysButtons) {
		dayButtonNode.addEventListener('click', () => {
			this.calendarProps.selectedDate = new Date(dayButtonNode.dataset.date);
			this.targetElement.querySelector(selectors.formFiled).setAttribute('value', this.calendarProps.selectedDate);

			reInit.call(this);

			this.actions.selectShippingMethod.call(this.targetElement);
		});
	}
}

/**
 * Renders the ShippingCalendar.
 * @this {ShippingCalendar} The ShippingCalendar instance.
 */
function render() {
	this.director.buildShippingCalendar(this.calendarProps);

	const calendarNode = this.targetElement.querySelector(selectors.calendar);
	const calendarMarkup = app.util.renderTemplate(this.calendarTemplate.innerHTML, this.builder.getResult());

	if (calendarNode) {
		this.targetElement.removeChild(calendarNode);
		this.targetElement.classList.remove(classes.wrapper);
	}

	this.targetElement.classList.add(classes.wrapper);
	this.targetElement.innerHTML += calendarMarkup;
}

/**
 * Retrieves the status based on the provided status ID.
 * @param {string} statusId - The ID of the status to retrieve.
 * @returns {StatusObject} - An object containing the success status and message (if applicable).
 */
function getStatus(statusId) {
	if (this.errors.hasOwnProperty(statusId)) {
		return {
			success: false,
			message: this.errors[statusId].message
		};
	}

	return { success: true };
}

export default ShippingCalendar;
