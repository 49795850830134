(function(app, $) {
	var $cache = {};
	$cache.list = {};
	/* configuration structure
	{
		list: ['value', 'value'],
		default: {
			desktop: 'value', // mandatory for desktop switcher
			mobile: 'value', //mandatory for mobile switcher
			tablet: { //optional for desktop switcher
				landscape: 'value',
				portrait: 'value'
			}
		}
	}*/
	var configs = {
		//thousand years from now
		expiration: new Date().setTime(+new Date() + 31.536e+9)
	};
	var selectors = {
		columnSwitcher: '.js-filter_view-header',
		activeButtonSelector: '.js-filter_view-header .',
		activeButtonClass: 'b-change_view-type-active',
		contentCategoryClass: 'js-content-category',
		productTilesContainer: '.js-search_result-content',
		changeViewColumnsSelector: '.js-change-view-columns',
		singleToggler: '.js-column-switcher-singleToggler'
	};

	function initializeConfigs(params) {
		configs.cookieName = $cache.jsViewEl.hasClass(selectors.contentCategoryClass) ? 'plpcontentcolumns' : 'plpcolumns';
		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
		configs.isTabletMode = app.device.currentDevice() === 'tablet' && configs.default.tablet;
		configs.classesList = $.map(configs.list, function(e) { return 'm-' + e + '-columns js-' + e + '-columns'; }).join(' ');

		selectors.activeButtonClass = params.customActiveClass || selectors.activeButtonClass;
		selectors.activeButtonSelector += selectors.activeButtonClass;
	}

	function initializeCache() {
		$cache.document = $(document);
		$cache.window = $(window);
		$cache.jsViewEl = $('.js-view-selector');
		$cache.productTilesContainer = $();
		$cache.hHiddenClass = 'h-hidden';
	}
	
	function initializeDOM(){
		var cookieValue = $.cookie(configs.cookieName),
			gridValue = cookieValue;
	
		if (!cookieValue || configs.list.indexOf(cookieValue) === -1) {
			gridValue = configs.isTabletMode
				? configs.default.tablet[app.device.isPortraitOrientation() ? 'portrait' : 'landscape']
				: configs.default[app.device.currentDevice() === 'mobile' ? 'mobile' : 'desktop'];
			//for tablet value saved on click only
			if (!configs.isTabletMode) {
				$.cookie(configs.cookieName, gridValue, { expires: configs.expiration, path: '/' });
			}
		}
		
		//no default value in the config means no switcher
		if (!gridValue) {
			$(selectors.productTilesContainer).removeClass($cache.hHiddenClass);
			return;
		}
		
		preselectGridView(gridValue);
	}

	function initializeEvents() {
		var cookieValue = $.cookie(configs.cookieName),
			gridValue = cookieValue;

		if (configs.isTabletMode && !cookieValue) {
			var resizeTimer;

			$cache.window.on('resize', function() {
				clearTimeout(resizeTimer);
				//This timer used to check end of resize and run this code just one time
				resizeTimer = setTimeout(function() {
					//stop processing if user clicks on switcher and cookie was populated
					if ($.cookie(configs.cookieName)) {
						return;
					}
					var activeMode = configs.default.tablet[app.device.isPortraitOrientation() ? 'portrait' : 'landscape'];
					$(selectors.activeButtonSelector).removeClass(selectors.activeButtonClass);
					$(selectors.productTilesContainer)
						.removeClass(configs.classesList)
						.addClass('m-' + activeMode + '-columns');
					$(selectors.changeViewColumnsSelector + '[data-grid-number="' + activeMode + '"]').addClass(
						selectors.activeButtonClass
					);
				}, 250);
			});
		}
		
		if (app.util.getConfig('gridColumnSwitcher.singleToggler')) {
			$cache.document.on('click', selectors.singleToggler, function() {
				$cache.singleToggler = $(this);
				if ($cache.singleToggler.data('grid-value') !== undefined) {
					gridValue = $cache.singleToggler.data('grid-value');
				}
				$cache.list.activeIndex = configs.list.indexOf(gridValue);
				$cache.list.nextGridIndex = configs.list.length === $cache.list.activeIndex + 1 ? 0 : $cache.list.activeIndex + 1;

				$(selectors.productTilesContainer)
					.removeClass(configs.classesList)
					.addClass('m-' + configs.list[$cache.list.nextGridIndex] + '-columns js-' + configs.list[$cache.list.nextGridIndex] + '-columns');
				$cache.singleToggler.data('grid-value', configs.list[$cache.list.nextGridIndex]);
				$.cookie(configs.cookieName, configs.list[$cache.list.nextGridIndex], { expires: configs.expiration, path: '/' });
				$cache.document.trigger('plp.columns.switched');
			});
		} else {
			for (var i = 0, l = configs.list.length; i < l; i++) {
				(function(j) {
					$cache.document.on('click', selectors.columnSwitcher + ' .js-' + configs.list[j] + '-columns', function() {
						$(selectors.activeButtonSelector).removeClass(selectors.activeButtonClass);
						$(selectors.productTilesContainer)
							.removeClass(configs.classesList)
							.addClass('m-' + configs.list[j] + '-columns js-' + configs.list[j] + '-columns');
						$(this).addClass(selectors.activeButtonClass);
						$.cookie(configs.cookieName, configs.list[j], { expires: configs.expiration, path: '/' });
						$cache.document.trigger('columns.switched');
					});
				})(i);
			}
		}

		$cache.document.on('refinements-update', function(e, extParams) {
			preselectGridView($.cookie(configs.cookieName));
		});
	}
	
	function preselectGridView(gridValue){
		$('.js-' + gridValue + '-columns').addClass(selectors.activeButtonClass);
		$(selectors.productTilesContainer)
			.addClass('m-' + gridValue + '-columns js-' + gridValue + '-columns')
			.removeClass($cache.hHiddenClass);
	}

	/**
	 * @namespace app.components.search.gridcolumnswitcher public object
	 **/
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.gridcolumnswitcher = {
		init: function(params) {
			initializeCache();
			initializeConfigs(params);
			initializeDOM();
			initializeEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
