// app.dialog
(function(app, $) {
	var $cache = {},
		initialized = false,
		templates = {},
		settings = {},
		resources = app.resources,
		carouselWrapper = '.js-fancybox_init';

	// override default HTML form fancybox
	templates = {
		wrap: '<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>',
		image: '<img class="fancybox-image" src="{href}" alt="" />',
		iframe: '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" frameborder="0" vspace="0" hspace="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>',
		error: app.preferences.FANCYBOX_ERROR,
		closeBtn: '<span class="fancybox-close" tabindex="0" aria-label="' + resources.NL_CLOSE_ARIALABEL + '" role="button"></span>',
		next: '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
		prev: '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>'
	};

	// override default properties for fancybox
	settings = {
		padding: 15,
		margin: 20,
		width: 800,
		height: 600,
		minWidth: 100,
		minHeight: 100,
		maxWidth: 9999,
		maxHeight: 9999,
		pixelRatio: 1, // Set to 2 for retina display support
		autoSize: true,
		autoHeight: false,
		autoWidth: false,
		autoResize: true,
		autoCenter: true,
		fitToView: true,
		aspectRatio: false,
		topRatio: 0.5,
		leftRatio: 0.5,
		scrolling: 'no', // 'auto', 'yes' or 'no'
		wrapCSS: '',
		arrows: true,
		closeBtn: true,
		closeClick: false,
		nextClick: false,
		mouseWheel: true,
		autoPlay: false,
		playSpeed: 3000,
		preload: 3,
		modal: false,
		loop: true,
		scrollOutside: true,
		index: 0,
		type: null,
		href: null,
		content: null,
		title: null,
		tpl: $.extend({}, templates), // HTML templates

		ajax: {
			dataType: 'html',
			headers: {}
		},
		iframe: {
			scrolling: 'auto',
			preload: true
		},
		swf: {
			wmode: 'transparent',
			allowfullscreen: 'true',
			allowscriptaccess: 'always'
		},

		// Properties for each animation type
		// Opening fancyBox
		openEffect: 'none', // 'elastic', 'fade' or 'none'
		openSpeed: 250,
		openEasing: 'fade',
		openOpacity: true,
		openMethod: 'zoomIn',

		// Closing fancyBox
		closeEffect: 'none', // 'elastic', 'fade' or 'none'
		closeSpeed: 250,
		closeEasing: 'swing',
		closeOpacity: true,
		closeMethod: 'zoomOut',

		// Changing next gallery item
		nextEffect: 'elastic', // 'elastic', 'fade' or 'none'
		nextSpeed: 250,
		nextEasing: 'swing',
		nextMethod: 'changeIn',

		// Changing previous gallery item
		prevEffect: 'elastic', // 'elastic', 'fade' or 'none'
		prevSpeed: 250,
		prevEasing: 'swing',
		prevMethod: 'changeOut',

		// Enable default helpers
		helpers: {
			overlay: {
				locked: false
			},
			title: false
		},

		// Callbacks
		onCancel: function() {
			$cache.document.find('html').removeClass('html_fancybox_opened');
		},
		beforeLoad: $.noop, // Before loading
		afterLoad: $.noop, // After loading
		beforeShow: function() {
			// workaround to prevent effect when pop-up flashes with 100% width before normal dimensions are set
			this.wrap && this.wrap.css('visibility', 'hidden');
		}, // Before changing in current item
		afterShow: $.noop, // After opening
		onUpdate: function() {
			// workaround to prevent effect when pop-up flashes with 100% width before normal dimensions are set
			this.wrap && this.wrap.css('visibility', 'visible');
			$(document).trigger('fancybox.updated');
		}, // After update
		beforeChange: $.noop, // Before changing gallery item
		beforeClose: function() {
			$('.fancybox-inner iframe').remove();
			$(document).trigger('fancybox.beforeclose');
		}, // Before closing
		afterClose: function() {
			var $this = $(this.element);
			var iframe = $this.find('iframe');
			if ($.browser.msie && iframe.length) {
				var $video = $(document.createElement('div'));
				$this.empty();
				$(document.createElement('iframe'))
					.attr({
						src: iframe.attr('src'),
						frameborder: iframe.attr('frameborder'),
						allowfullscreen: iframe.attr('allowfullscreen')
					})
					.appendTo($video);
				$this.html($video);
			}
			$(document).trigger('fancybox.closed');
			$cache.document.find('html').removeClass('html_fancybox_lock');
		} // After closing
	};

	function initializeEvents() {
		$cache.document.on('click tap', '.js_fancybox, [target="_modal"]:not(.js_fancybox_disabled)', openFancyboxHandler);
		$cache.document.on('close.fancybox', function(event, data) {
			app.fancybox.close();
		});
		$cache.document.on('click', '.js-close_fancybox', function(e) {
			e.preventDefault();
			app.fancybox.close();
		});
		$cache.document.on('dialog.showed', function() {
			if (app.device.currentDevice() !== 'desktop') {
				var $fBox = $cache.document.find($cache.selectors.fancyboxWindow);

				if (app.device.currentDevice() === 'mobile') {
					$cache.document.find($cache.selectors.body).prop($cache.props.scrollTop, $cache.document.scrollTop());
					if (!$($cache.selectors.cookiesInformer).hasClass($cache.classes.hHidden) || $($cache.selectors.fancyboxWindow).hasClass($cache.classes.fancyboxSubscription)) {
						$cache.document.find($cache.selectors.htmlAndBody).addClass($cache.classes.fancyboxBodyFix);
					}
				}
				$fBox
					.find('form')
					.on('focus', $cache.selectors.inputElements, function() {
						$fBox.addClass($cache.classes.fancyboxPositionFix);
					})
					.on('blur', $cache.selectors.inputElements, function() {
						$fBox.removeClass($cache.classes.fancyboxPositionFix);
					});
			}
		});

		$cache.document.on('fancybox.closed', function() {
			if (app.device.currentDevice() === 'mobile') {
				$cache.document.find($cache.selectors.htmlAndBody).removeClass($cache.classes.fancyboxBodyFix);

				var scrollTop = $cache.document.find($cache.selectors.body).prop($cache.props.scrollTop);
				if (scrollTop) {
					$cache.document.scrollTop(parseInt(scrollTop));
				}
			}
		});
	}

	function initializeCache() {
		$cache.document = $(document);
		$cache.classes = {
			fancyboxSubscription: 'js-simplesubscription',
			fancyboxPositionFix: 'fancybox-wrap_position-fix',
			fancyboxBodyFix: 'fancybox-body-fix',
			hHidden: 'h-hidden'
		};
		$cache.selectors = {
			fancyboxWindow: '.fancybox-wrap.fancybox-opened',
			inputElements: 'input, select, textarea',
			html: 'html',
			body: 'body',
			cookiesInformer: '.js-cookies_informer'
		};
		$cache.selectors.htmlAndBody = $cache.selectors.html + ',' + $cache.selectors.body;
		$cache.props = {
			scrollTop: 'scroll-top'
		};
		$cache.callbackFunctionNames = [
			'onCancel',
			'beforeLoad',
			'afterLoad',
			'beforeShow',
			'afterShow',
			'onUpdate',
			'beforeChange',
			'beforeClose',
			'afterClose'
		];
	}

	function openFancyboxHandler(event) {
		event.preventDefault();
		var $this = $(this),
			data = $this.data(),
			options = 'dialogOptions' in data ? data.dialogOptions : {},
			source = 'target' in data ? $(data.target) : undefined,
			afterShowCallback = 'afterShow' in options && 'function' === typeof options['afterShow'] ? options.afterShow : $.noop;
		if (!source || !source.length) {
			switch (this.tagName.toLowerCase()) {
				case 'a':
					source = $this.attr('href');
					break;
				case 'img':
					source = $this.attr('src');
					break;
			}
			if (!source && 'href' in data) source = data.href;
			if (!('type' in options)) options.type = 'ajax';
			if (!('href' in options)) options.href = app.util.appendParamToURL(source, 'format', 'ajax');
		}

		options.afterShow = function() {
			afterShowCallback();
			$cache.document.trigger('imageReplace.globalResponsive');
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		};

		if (!source) return;
		app.fancybox.open(source, $.extend({}, settings, options));
	}

	function initAllFancybox() {
		var $fancybox = $(this).find(carouselWrapper);
		if ($fancybox.length) {
			var dataSettings = $fancybox.data('settings') ? $fancybox.data('settings') : {},
				fancyboxSettings = $.extend({}, settings, dataSettings);
			$fancybox.fancybox(fancyboxSettings);
		}
	}
	app.fancybox = {
		// basic initialization
		init: function() {
			if (!$.fn.hasOwnProperty('fancybox')) {
				console.warn('jQuery Fancybox plugin is missing. app.fancybox Namespace initialization failed. ');
				return;
			}

			if (!initialized) {
				initializeCache();
				initializeEvents();
				initialized = true;
			}
			initAllFancybox.call(document);
		},

		//
		create: function(params) {
			if (!initialized) return;
		},
		/**
		 * Opens a dialog
		 *
		 * @param {String|DOMNode} source URL, DOM element or simple string of text
		 * @param {Object} [options]
		 * @param {String} [options.type]
		 * @param {String} [options.width]
		 * @param {String} [options.height]
		 * @param {String} [options.autoSize=true]
		 * @param {String} [options.wrapCSS='']
		 */
		open: function(source, options) {
			if (!initialized) return;

			if (!source) {
				console.warn('FancyBox dialog could not be opened without source');
				return;
			}

			if (!$.isPlainObject(options)) {
				options = {};
			}

			if (options.type === 'ajax' && typeof source === 'string') {
				source = app.util.appendParamsToUrl(source, {
					format: 'ajax'
				}, options.notEncode);
			}

			var option_extended = $.extend({}, settings, options);

			$.each($cache.callbackFunctionNames, function(index, value) {
				if (value in options && 'function' === typeof options[value]) {
					option_extended[value] = function() {
						settings[value].call(this);
						options[value]();
					};
				}
			});

			if (option_extended.hasOwnProperty('afterShow')) {
				var callbackTmp = option_extended.afterShow;
				option_extended.afterShow = function() {
					$cache.document.trigger('dialog.showed');
					return callbackTmp.call(this, arguments);
				};
			}

			$cache.document.trigger('dialog.opening');

			if (option_extended.hasOwnProperty('type') && option_extended['type'] === 'iframe') {
				option_extended.width = 700;
				option_extended.height = 400;
				$.fancybox.open(option_extended);
			} else {
				$.fancybox.open(source, option_extended);
				$cache.document.trigger('dialog.opened');
			}

			app.fancybox.resetAdditionalWrapperCssClass();
		},

		// closes the dialog and triggers the "close" event for the dialog
		close: function(force) {
			if (!initialized) return;
			$.fancybox.close(force);
		},

		// will center fancyBox inside viewport and toggle position type to fixed or absolute if needed
		reposition: function() {
			if (!initialized) return;
			$.fancybox.reposition();
		},

		// auto-sizes fancyBox height to match height of content
		update: function() {
			if (!initialized) return;
			$.fancybox.update();
		},

		// hides loading animation
		showLoading: function() {
			if (!initialized) return;
			$.fancybox.showLoading();
		},

		// hides loading animation
		hideLoading: function() {
			if (!initialized) return;
			$.fancybox.hideLoading();
		},

		// submits the dialog form with the given action
		submit: function(action) {
			if (!initialized) return;

			var $form = $.fancybox.inner.find('form:first'),
				post = $form.serialize(),
				url = $form.attr('action');

			$('<input />')
				.attr({
					name: action,
					type: 'hidden'
				})
				.appendTo($form);

			var $xhr = app.ajax.load({
				target: $.fancybox.inner,
				url: url,
				data: post,
				dataType: 'html',
				type: 'POST'
			});

			$xhr.done(function() {
				$.fancybox.inner.html(data);
			});

			$xhr.fail(function() {
				window.alert(app.resources.SERVER_ERROR);
			});
		},

		setAdditionalWrapperCssClass : function (cssClass) {
			settings.tpl.wrap = settings.tpl.wrap.replace('fancybox-wrap', cssClass + ' fancybox-wrap');

			return this;
		},

		resetAdditionalWrapperCssClass : function () {
			settings.tpl = $.extend({}, templates);

			return this;
		},

		// push default preferences to public scope
		settings: settings,

		isFancyboxEvent: function(e) {
			var $element = $(e.target);

			return $element.closest('.fancybox-overlay').length > 0 || ($element.attr('class') && $element.attr('class').split(/\s+/).filter(function(className) {
				return className.indexOf('fancybox') !== -1;
			}).length > 0);
		}
	};
})((window.app = window.app || {}), jQuery);
