//app.components.customerservice.contactus
( function(app, $) {
	var $cache = {};
	
	function initializeDOM(){
		getMyQuestionLabel();
		$($cache.fileInputfieldSel).addClass($cache.hiddenClass);
		$($cache.fileInputfieldSel).siblings($cache.attachmentInputWrapperSel).addClass($cache.hiddenClass);
	}
	
	function initializeEvents() {
		
		$cache.myquestionSelect.on('change', function(e){
			getMyQuestionLabel();
		});
		
		$cache.contactusform.on('submit', function(event){
			event.preventDefault();
			submitContactUsForm.call(this);
		});
		
		$cache.contactusforminstore.on('submit', function(event){
			event.preventDefault();
			submitContactUsForm.call(this);
		});
		
		$cache.contactusformpress.on('submit', function(event){
			event.preventDefault();
			submitContactUsForm.call(this);
		});
		attachmentFormEvents();
		attachmentFormClearing();
		
	}
	function initializeCache() {
		
		$cache = {
			contactusform : $(".js-contactus_form"),
			contactusforminstore: $('.js-customerservice-in-store_without-receipt_form_tag'),
			contactusformpress: $('.js-customerservice-press_form_tag'),
			myquestionSelect: $('.js-myquestion_select, .js-myquestion_select select'),
			myQuestionLabelInput : $(".js-myquestionlabel_input"),
			contactUsPageWrapperSel: '.js-customer_service_content',
			contactUsConfirmationBlock: $(".js-confirmation-content"),
			b2bprogrammClass : 'js-b2bprogramm',
			spinClass : "m-spin_bar",
			window : $(window),
			recaptchaRenderBlockSel: '.js-recaptcha-render-block',
			recaptchaInvalidSel: '.js-recaptcha-invalid',
			recaptcaErrorBlockSel: '.js-captcha-error-block',
			warningTextSel: '.f-warning',
			attachmentInputSel: '.js-attachment',
			attachmentInputWrapperSel: '.js-attach-wrapper',
			fileInputfieldSel: '.js-fileattachment',
			fileAddedMarkerClass: 'js-file-added',
			noFileLabel: $('.js-inputfile-nofiles'),
			addFileBtn: $('.js-inputfile-addfile'),
			removeFileBtn: $('.js-inputfile-remove'),
			hiddenClass: 'h-hidden',
			fileFieldStateErrorClass: 'fileField-state-error',
			fileFieldSel: '.js-fileField',
			contactusSubmitBtnSel: '[class*="js-contactus_"][class$="submit"]'
		};
	}
	function getMyQuestionLabel(){
		var label = $cache.myquestionSelect.children('option:selected').text();
		if(label){
			$cache.myQuestionLabelInput.attr('value', label);
		}
	}

	function submitContactUsForm(){
		var $this = $(this),
			submitButton = $this.find($cache.contactusSubmitBtnSel),
			fileFields = $this.find('input[type="file"]'),
			data = new FormData(this);
		
		if ($this.valid()) {
			if (app.preferences.contactUsRecaptchaEnabled) {
				var recaptchaId = $this.find($cache.recaptchaRenderBlockSel).attr('id');
				
				if (recaptchaId) {
					app.grecaptcha.execute(recaptchaId);
					$(document).on('captcha-'+recaptchaId+'-solved', function(e, token) {
						submit(token);
					})
				}
				else {
					submit();
				}
			}
			else {
				submit();
			}
			
			function submit(token) {
				submitButton.addClass($cache.spinClass).attr('disabled', true);
				data.append(submitButton.attr('name'), submitButton.attr('value'));
				
				var url = $this.attr('action');
				
				if (token) {
					url += '?' + 'g-recaptcha-response=' + token
				}
				
				fileFields.each(function() {
					if (this.files.length > 0) {
						data.append(this.name, this.files[0]);
					}
					else {
						data.delete(this.name);
					}
				});
				
				$.ajax({
					url: url,
					type: 'POST',
					cache: false,
					contentType: false,
					processData: false,
					dataType: 'html',
					data: data
				}).done(function(response) {
					if (app.preferences.contactUsRecaptchaEnabled && $(response).find($cache.recaptchaInvalidSel).length) {
						$($cache.recaptcaErrorBlockSel).css('display', 'block');
						app.grecaptcha.reset(recaptchaId);
						submitButton.removeClass($cache.spinClass).attr('disabled', false);
					} else if ( $($cache.contactUsPageWrapperSel).hasClass($cache.b2bprogrammClass) ){
						$cache.contactUsConfirmationBlock.html(response);
						$cache.contactUsConfirmationBlock.removeClass($cache.hiddenClass);
						$cache.contactusform.trigger("reset");
						submitButton.removeAttr('disabled');
					} else {
						$($cache.contactUsPageWrapperSel).html(response);
						$cache.window.scrollTop(0);
					}
				});
			}
		}
	}
	
	function attachmentFormEvents(){
		$($cache.attachmentInputSel)
			.on('click', function(){
				$(this).val('');
			})
			.on('change', function(){
				var $this = $(this),
					inputAttachmentTextWrapper,
					inputAttachmentTextTarget,
					fileName = $this.val().split('\\').pop();
				
				inputAttachmentTextWrapper = $this.closest($cache.attachmentInputWrapperSel).siblings(".f-field");
				inputAttachmentTextTarget = inputAttachmentTextWrapper.find('input[type="text"]');
				inputAttachmentTextTarget.val(fileName);
				$this.valid();
			});
		
		$cache.addFileBtn.on('click', function(e){
			e.preventDefault();
			
			var $fileFields = $($cache.fileFieldSel),
				$fileFieldWithErrors = $fileFields.filter('.' + $cache.fileFieldStateErrorClass);
			
			if ($fileFieldWithErrors.length) {
				handleAttachmentInput($fileFieldWithErrors.find($cache.attachmentInputSel));
			}
			else {
				$fileFields.each(function() {
					var $attachmentInput = $(this).find($cache.attachmentInputSel);
					
					if (!$attachmentInput.hasClass($cache.fileAddedMarkerClass)) {
						handleAttachmentInput($attachmentInput);
						
						return false;
					}
				});
			}
		});
	}
	
	function handleAttachmentInput($attachmentInput) {
		$attachmentInput.addClass($cache.fileAddedMarkerClass).click();
		
		$attachmentInput.on('change', function() {
			if ($attachmentInput.val() || $attachmentInput.val() === '') {
				var $inputFileWrapper = $attachmentInput.closest($cache.attachmentInputWrapperSel);
				$inputFileWrapper.removeClass($cache.hiddenClass);
				$inputFileWrapper.siblings($cache.fileInputfieldSel).removeClass($cache.hiddenClass);
				$cache.noFileLabel.addClass($cache.hiddenClass);
			}
		});
	}
	
	function attachmentFormClearing(){
		$cache.removeFileBtn.on('click', function(e){
			e.preventDefault();
			var $inputFileWrapper = $(this).parents($cache.attachmentInputWrapperSel);
			var parentContainer = $cache.addFileBtn.parent();
			parentContainer.removeClass('f-state-error');
			$inputFileWrapper.addClass($cache.hiddenClass);
			$inputFileWrapper.siblings($cache.fileInputfieldSel).addClass($cache.hiddenClass);
			$inputFileWrapper.siblings($cache.fileInputfieldSel).find($cache.warningTextSel + '_text').text('');
			$inputFileWrapper.find('input[type="file"]').val('');
			$inputFileWrapper.find($cache.attachmentInputSel).removeClass($cache.fileAddedMarkerClass);
			if ($($cache.fileInputfieldSel + ':not(.' + $cache.hiddenClass + ')').length === 0) {
				$cache.noFileLabel.removeClass($cache.hiddenClass);
			}
		});
	}
	/**
	 * @namespace app.components.account.navigation public object
	 **/
	app.components = app.components || {};
	app.components.customerservice = app.components.customerservice || {};
	app.components.customerservice.contactus = {
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};

}(window.app = window.app || {}, jQuery));
