(function (app, $) {

	var trackerData = app.trackerData || {};
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			selectors: {
				quickViewMainMain: '.js-quick_view_main',
				productVariansInfo: '.js-gtm_product_variants_info',
				head: 'head'
			}
		};
	}

	function initializeEvents() {
		$cache.document.on('quickview.opened', function () {
			var product = getCurrentProduct();
			if (product) {
				var data = {};
				var itemId = product.productMasterID;
				data.type = 'product';
				data.info = [
					'trackPageView',
					{
						'item': itemId
					}
				];
				app.mc.pushEvent(data);
			}
		});

		if (app.page.ns !== 'checkout') {
			$cache.document.on('minicart.flyout.open minicart.flyout.update', function() {
				app.mc.pushCart();
			});
		} else {
			$cache.document.on('cart.updateModels', function(e, basket) {
				var lineItems = basket.lineItems;
				var cartItems = Object.keys(lineItems).map(function(item) {
					var itemObj = {
						variantId: lineItems[item].pid,
						quantity: lineItems[item].qty,
						masterId: lineItems[item].masterPID,
						price: lineItems[item].grossPrice.value
					};
					return itemObj;
				});
				trackerData.cartInfo.cartProducts = cartItems;
				app.mc.pushCart();
			});
		}
	}

	function getCurrentProduct(id) {
		id = id || "default";

		var $popupInfo = $($cache.selectors.quickViewMainMain).find($cache.selectors.productVariansInfo);
		var $productInfo = $($cache.selectors.head).children($cache.selectors.productVariansInfo);
		var product = {};

		if ($popupInfo.length || $productInfo.length) {
			var productVariansInfoJson = $popupInfo.length ? $popupInfo.html() : $productInfo.html();

			try {
				var productVariansInfo = JSON.parse(productVariansInfoJson);
				$.each(productVariansInfo, function (index, item) {
					if (id === item.productSku) {
						product = item;
					}
				});
			} catch (e) {
				return null;
			}
		}

		return product;
	}


	app.mc = app.mc || {};
	app.mc.config = app.mc.config || {};
	app.mc.config.global = {
		init: function () {
			initializeCache();
			initializeEvents();
		}
	};

}(window.app = window.app || {}, jQuery));
