(function(app, $){
	var $cache = {};

	function initializeCache(params){
		$cache = {
			accordionTitleSel: '.js_accordion_title',
			accordionOpenClass: 'js_accordion_description--open',
			document: $(document),
			window: $(window),
			headerMain: $('.js-header_main'),
			scrollAnimationSpeed: 200,
			htmlBody: $('html, body'),
			enableScrollToTitle: 'enableScrollToTitle' in params ? params.enableScrollToTitle : false,
			appendItem : $('.js-append-item'),
			appendDestination : $('.js-append-destination'),
			hiddenClass: 'h-hidden',
			accordionDescriptionSel: '.js_accordion_description'
		};

		$cache.headerMainHeight = $cache.headerMain.length && $cache.headerMain.css('position') === 'fixed' ? $cache.headerMain.height() : 0;
	}

	function toggleAriaHidden($el, isState) {
		$el.attr('aria-hidden', isState).attr('tabindex', isState ? -1 : 0);
	}

	function toggleAriaSelected($el, isState) {
		$el.attr('aria-selected', isState).attr('aria-expanded', isState);
	}

	function initializeEvents() {
		$cache.document.on('click', $cache.accordionTitleSel, function() {
			var $section = $(this);
			var $container = $section.parent();
			var $containerDescription = $container.children($cache.accordionDescriptionSel);
			var $containerSiblings = $container.siblings();
			var $containerSiblingsTitles = $containerSiblings.find($cache.accordionTitleSel);

			if ($container.hasClass($cache.accordionOpenClass)) {
				$container.removeClass($cache.accordionOpenClass);

				if ($containerDescription.length) {
					toggleAriaHidden($containerDescription, true);
				}

				toggleAriaSelected($section, false);

				$section.trigger('accordion.closed');
			} else {
				var accordionContainerTop = $section.parent().parent().offset().top;
				if($cache.enableScrollToTitle && $cache.window.scrollTop() > accordionContainerTop){
					$cache.htmlBody.animate({
						scrollTop: accordionContainerTop - $cache.headerMainHeight
					}, $cache.scrollAnimationSpeed);
				}
				if ($containerDescription.length) {
					toggleAriaHidden($containerDescription, false);
					toggleAriaHidden($containerSiblings.children($cache.accordionDescriptionSel), true);
				}

				toggleAriaSelected($section, true);
				toggleAriaSelected($containerSiblingsTitles, false);

				$section.trigger('accordion.opening');
				$containerSiblings.removeClass($cache.accordionOpenClass);
				$container.addClass($cache.accordionOpenClass);
			}
		});

		if ($cache.appendItem.length) {
			$cache.appendItem.appendTo($cache.appendDestination);
			$cache.appendItem.removeClass($cache.hiddenClass);
		}
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.accordion = {
		init: function(params){
			initializeCache(params);
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);