(function(app, $) {
	var $cache = {},
		$document = $(document),
		COUNTRY_LEVEL_ZOOM = 7,
		GoogleMap = null,
		markers = [],
		infoWindows = [],
		google = window.google,
		bounds = typeof google !== 'undefined' ? new google.maps.LatLngBounds() : null;

	function initializeCache() {
		$cache = {
			htmlBody: $('html, body'),
			header: $('header'),
			checkoutForm: $('.js-checkout_form'),
			isCC: $('.js-cc-enabled').val() == 'true',
			isUpsAP: $('.js-shiptoupsap_delivery_type').val() == 'true',
			countrySelector: $('.js-storelocator_country'),
			stateBlockSelector: $('.js-storelocator_state-field'),
			stateSelector: $('.js-storelocator_state'),
			citySelector: $('.js-storelocator_city'),
			storeTypeSelector: $('.js-storelocator_type'),
			zipError: $('.js-ziperror'),
			spinbar: $('.js-spinbar'),
			spinbarClass: 'm-spin_bar',
			infoWrapperClass: 'b-storelocator-map-info_window',
			infoWindowClass: 'js-infowindow',
			hideClass: 'h-hidden',
			showClass: 'h-show',
			selectClass: 'selected',
			findButtonSel: '.js-button_find',
			storelocatorMapSel: '.js-storelocator_map',
			zipFieldForValidSel: '.js-zipcode-field .js-storelocator_postalcode',
			storesListSel: '.js-storelocator_list',
			selectedStoreList: '.js-storelocator-results_list',
			storesNotFoundSel: '.js-stores-notfound',
			formSel: '.js-storelocator_search_form',
			storelocatorWrapSel: '#storelocator',
			postalCodeSel: '.js-storelocator_postalcode',
			currentLocationCountrySel: '.js-current_location-country',
			zipErrorMessageSel: '.js-ziperror-mess',
			selectedStoreSel: '.js-selected_store',
			countrySel: '.js-storelocator_country',
			selectStoreSel: '.js-select-store',
			storeWrapSelected: '.js-store-address',
			storeAddressName: '.js-store-address_name',
			infoWindowDialogSel: '.gm-style-iw-d'
		};
		$cache.address1Field = $cache.checkoutForm.find("input[name$='_shippingAddress_addressFields_address1']");
		$cache.errors = {
			zip: 'ziperror',
			city: 'cityerror',
			notselected: 'notselectederror',
		};
		$cache.events = {
			upsapSearch: 'upsap.search',
			storelocatorMapDisplayed: 'storelocator.map.displayed'
		};
		google = window.google;
		bounds = typeof google !== 'undefined' ? new google.maps.LatLngBounds() : null;
		$cache.showMapOnMobile = app.util.getConfig('storeLocator.showMapOnMobile');
	}

	function initializeEvents() {
		var params = app.util.getQueryStringParams(document.location.search),
			selectedCountry = $($cache.countrySel).val(),
			locatorUrl = '';

		// Initialize Map onLoad
		if (!$cache.isCC && !$cache.isUpsAP) {
			if (params.hasOwnProperty('storeid')) {
				locatorUrl = app.urls.findNearStore;
				locatorUrl = app.util.appendParamToURL(locatorUrl, 'ID', params.storeid);

				$.get(locatorUrl).done(function(response) {
					storeLocatorDisplayMap(response);
				});
			} else {
				if (selectedCountry && (!app.device.isMobileView() || $cache.showMapOnMobile)) {
					if (app.preferences.storelocatorMultiselectEnable) {
						validateStoreSearchParams();
					} else {
						if ($cache.citySelector.val()) {
							locatorUrl = app.urls.getStoreList;
						} else {
							locatorUrl = app.urls.findNearStore;
						}

						locatorUrl = app.util.appendParamToURL(locatorUrl, 'countryCode', selectedCountry);

						$.get(locatorUrl).done(function(response) {
							storeLocatorDisplayMap(response);
						});
					}
				}
			}
		}

		// on pressing enter
		$document.on('keyup', $cache.postalCodeSel, function(e) {
			if (e.keyCode == 13) {
				checkZipOrCity();
			}
		});
		// on click Find button
		$document.on('click', $cache.findButtonSel, function(e) {
			e.preventDefault();
			validateStoreSearchParams();

			if (app.util.getConfig('storeLocator.showResultList')) {
				$($cache.storesListSel).removeClass($cache.hideClass);
			}
		}).on($cache.events.storelocatorMapDisplayed, $cache.findButtonSel, function() {
			if ($(this).parents('.fancybox-wrap').length) {
				app.fancybox.update();
			}
		});

		$document.on('click', $cache.selectStoreSel, function(e) {
			e.preventDefault();
			$($cache.storelocatorMapSel).trigger('store.selected', { storeid: $(this).data('id') });

			if (app.util.getConfig('storeLocator.showResultList')) {
				$($cache.storesListSel).addClass($cache.hideClass);
			}
		});

		if (app.device.isMobileView()) {
			$document.on('click', $cache.storeWrapSelected, function(e) {
				e.preventDefault();
				$($cache.storeWrapSelected).trigger('store.selected', { storeid: $(this).data('storid') });

				if (app.util.getConfig('storeLocator.showResultList')) {
					$($cache.storesListSel).addClass($cache.hideClass);
				}
			});
		}

		$cache.checkoutForm.on('submit', function() {
			if (!isStoreNotSelected()) {
				return false;
			}
		});

		if (app.preferences.changeCountrySearch) {
			$cache.countrySelector.on('change', function() {
				checkZipOrCity();
			});
		}

		if (app.preferences.storelocatorMultiselectEnable) {
			$cache.countrySelector.on('change', function() {
				var params = {
					propName: 'countryCode',
					propGet: 'stateCode',
					disableRegion: app.preferences.storeLocatorDisableRegionFilter,
				};
				fetchStoreDependencies($cache.countrySelector, $cache.stateSelector, params, 'state', [$cache.citySelector])
					.done(function() {
						$cache.stateBlockSelector.show();
					})
					.fail(function() {
						//if no regions - hide state selector and try to find cities
						params = {
							propName: 'countryCode',
							propGet: 'city',
						};
						$cache.stateBlockSelector.hide();
						fetchStoreDependencies($cache.countrySelector, $cache.citySelector, params);
					});
			});

			$cache.stateSelector.on('change', function() {
				var params = {
					propName: 'stateCode',
					propGet: 'city',
				};

				fetchStoreDependencies($cache.stateSelector, $cache.citySelector, params);
			});
		}
	}
	function initializeDom() {
		if (app.preferences.storelocatorMultiselectEnable && $cache.countrySelector.val()) {
			var params = {
				propName: 'countryCode',
				propGet: 'stateCode',
				disableRegion: app.preferences.storeLocatorDisableRegionFilter,
			};
			fetchStoreDependencies($cache.countrySelector, $cache.stateSelector, params, 'state', [$cache.citySelector])
				.done(function() {
					$cache.stateBlockSelector.show();
				})
				.fail(function() {
					params = {
						propName: 'countryCode',
						propGet: 'city',
					};
					$cache.stateBlockSelector.hide();
					fetchStoreDependencies($cache.countrySelector, $cache.citySelector, params);
				});
		}
	}
	function scrollToStoreError() {
		if ($cache.zipError.length > 0) {
			$cache.htmlBody.animate({ scrollTop: $cache.zipError.position().top }, 0);
		}
	}
	function errorShow(error) {
		$($cache.zipErrorMessageSel).html($cache.zipError.data(error));
		$cache.zipError.addClass($cache.showClass).removeClass($cache.hideClass);
	}
	function errorHide() {
		$($cache.zipErrorMessageSel).html();
		$cache.zipError.addClass($cache.hideClass).removeClass($cache.showClass);
	}
	function hideProgress() {
		$cache.spinbar.removeClass($cache.spinbarClass);
	}
	function validateZip() {
		if ($($cache.zipFieldForValidSel).length) {
			$($cache.zipFieldForValidSel).valid();
		}
	}

	// Country drop down selector
	function setAllMap(map) {
		for (var i = 0; i < markers.length; i++) {
			markers[i].setMap(map);
		}
	}

	//define store location depending on previously selected value
	function fetchStoreDependencies(initialSelector, targetSelector, params, aliasPrefix, additionalSelectsToClear) {
		params = params || {};
		var deffered = $.Deferred();
		if (targetSelector.length) {
			var locationUrl = app.urls.getStoreDependencies,
				selectedOption = initialSelector.find('option:selected'),
				propCode = selectedOption.is(':disabled') ? null : selectedOption.attr('value');
			if (propCode) {
				params['propValue'] = propCode;
				if (aliasPrefix) {
					params['alias'] = aliasPrefix + '.' + propCode.toLowerCase();
				}
			}

			locationUrl = app.util.appendParamsToUrl(locationUrl, params);
			if (params.disableRegion) {
				targetSelector.attr('disabled', true);
				deffered.reject();
			} else {
				$.ajax({
					url: locationUrl,
					success: function(response) {
						if (!response.error) {
							//delete all previously generated items
							clearFetchedStoreDependencies(targetSelector);
							//delete additional generated items if needed
							if (additionalSelectsToClear != null) {
								if (additionalSelectsToClear.length) {
									for (var i = 0, length = additionalSelectsToClear.length; i < length; i++) {
										clearFetchedStoreDependencies(additionalSelectsToClear[i]);
									}
								}
							}

							if (response.dependencies.length) {
								$.each(response.dependencies, function(key, dependencyObject) {
									var code, name;
									for (var i in dependencyObject) {
										name = i;
										code = dependencyObject[i];
										break;
									}
									targetSelector.append(
										$('<option></option>')
											.attr('value', code)
											.text(name)
									);
								});
								targetSelector.removeAttr('disabled');

								deffered.resolve();
							} else {
								targetSelector.attr('disabled', true);

								deffered.reject();
							}
						}
					},
				});
			}
		}

		return deffered;
	}

	//helper function to clear previously generated items in store dependencies
	function clearFetchedStoreDependencies(selectToClear) {
		var disabledAttr = selectToClear.attr('disabled');
		if (typeof disabledAttr === 'undefined' || disabledAttr === false) {
			var defaultItem = selectToClear.find('option').first();
			defaultItem.nextAll().remove();
			defaultItem.attr('selected', 'selected');
		}
	}

	// Show map info window popup
	function showInfoWindow(map, infowindow, gMarker, storeID) {
		var content = infowindow.getContent();
		map.setZoom(Number(app.preferences.storeLocatorMapZoom));
		map.setCenter(gMarker.getPosition());
		infowindow.setContent(content);
		google.maps.event.addListener(infowindow, 'domready', function() {
			if (app.device.currentDevice() === 'desktop') {
				$('.' + $cache.infoWindowClass + '--' + storeID).children('div').scrollbar({
					disableBodyScroll: true
				});
			}

			if (app.device.currentDevice() === 'mobile' && app.configs.storeLocator.scrollInfoWindowDown) {
				var infoWindowDialog = $($cache.infoWindowDialogSel);
				var scrollHeight = infoWindowDialog.get(0).scrollHeight;

				infoWindowDialog.scrollTop(scrollHeight);
			}
		});
		infowindow.open(GoogleMap.map, gMarker);
	}

	// Show map with found stores
	function storeLocatorDisplayMap(response, lat, lng, centerOnly) {
		$($cache.storesListSel).html(response);
		document.dispatchEvent(new CustomEvent('stores.loaded'));
		$($cache.selectedStoreList).scrollbar({
			ignoreMobile: false,
			disableBodyScroll: true
		});

		var storeItems = $(response).find('.js-gmap');

		if (storeItems.length === 0) {
			$($cache.storesNotFoundSel).removeClass($cache.hideClass);
		} else {
			$($cache.storesNotFoundSel).addClass($cache.hideClass);
		}

		if (app.device.isMobileView() && !$cache.isCC && !$cache.showMapOnMobile) {
			$($cache.findButtonSel).text($($cache.findButtonSel).data('searchagain'));

			if (storeItems.length !== 0) {
				$($cache.formSel).fadeOut(400);
				$($cache.storesListSel).on('click', '.js-button_searchagain', function() {
					$($cache.storesListSel).empty();
					$($cache.formSel).fadeIn(400);
				});
			} else {
				errorShow($cache.errors.zip);
			}
		} else {
			var latLng;
			if (storeItems.length !== 0) {
				// do not call Maps API if there are no search results
				var mapLat = storeItems.data('latitude');
				var mapLng = storeItems.data('longitude');
				var mTitle = storeItems.data('title');
				latLng = new google.maps.LatLng(mapLat, mapLng);

				if (GoogleMap == null || $($cache.storelocatorMapSel).children().length === 0) {
					$($cache.storelocatorMapSel).empty();
					var gMmap = new google.maps.Map($($cache.storelocatorMapSel)[0], {
						zoom: Number(app.preferences.storeLocatorMapZoom),
						streetViewControl: app.util.getConfig('storeLocator.showStreetViewControl'),
						center: latLng,
						mapTypeControl: app.util.getConfig('storeLocator.showMapTypeControl'),
						mapTypeId: google.maps.MapTypeId.ROADMAP,
						scrollwheel: false,
						styles: app.util.getConfig('storeLocator.styledMap') ? [{
							stylers: [{
								saturation: -100
							}]
						}] : ''
					});

					var gMarker = new google.maps.Marker();

					GoogleMap = {
						map: gMmap,
						marker: gMarker,
					};
				} else {
					setAllMap(null);
					markers = [];
					var gMarker = new google.maps.Marker({
						position: latLng,
						map: gMmap,
						title: mTitle,
					});
					markers.push(gMarker);

					bounds = new google.maps.LatLngBounds();
					GoogleMap.marker.setPosition(latLng);
					GoogleMap.map.panTo(latLng);
					GoogleMap.map.setCenter(latLng);
					GoogleMap.map.setZoom(Number(app.preferences.storeLocatorMapZoom));
				}
				if (!centerOnly) {
					markers = [];
					infoWindows = [];
					$($cache.storesListSel).find('a.js-gmap').each(function() {
						mapLat = $(this).data('latitude');
						mapLng = $(this).data('longitude');
						mTitle = $(this).data('title');
						var mInfo = !$cache.isCC ? $(this).data('info') : '';
						var storeID = $(this).data('id');
						var letter = $(this).data('letter');
						latLng = new google.maps.LatLng(mapLat, mapLng);
						var icon = app.preferences.storeLocatorStoreIcon.replace('pinLetter', letter);
						if (app.configs.storeLocator.pinicon.custom) {
							var typeStore = $(this).data('store-type');
							var path;
							if (app.configs.storeLocator.pinicon.icons.staticIcons) {
								path = app.urls.staticPath + 'images/' + [typeStore] + '.png';
							} else {
								path = app.configs.storeLocator.pinicon.icons[typeStore];
							}
							if (path) {
								icon = path.replace('{0}', letter);
							}
						}
						if ($cache.isCC) {
							mInfo = $('#' + storeID + ' .js-store-address').html();
							mInfo +=
								'<div class="s-select-store_wrap"><button class="js-select-store" data-id="' +
								storeID +
								'">' +
								app.resources['SELECT'] +
								'</button></div>';
						}
						if ($('.js-showcity').val() === 'true') {
							if ($($cache.postalCodeSel).val() === '') {
								var gMarker = new google.maps.Marker({
									position: latLng
								});
							} else {
								var gMarker = new google.maps.Marker({
									position: latLng,
									map: GoogleMap.map,
									title: mTitle,
									icon: icon
								});
							}
						} else {
							var gMarker = new google.maps.Marker({
								position: latLng,
								map: GoogleMap.map,
								title: mTitle,
								icon: icon
							});
						}

						var infoWindowContent = $('<div/>').addClass($cache.infoWrapperClass)
							.wrap($('<div/>').addClass($cache.infoWindowClass + ' ' + $cache.infoWindowClass + '--' + storeID)).html(mInfo).parent()[0];

						var infowindow = new google.maps.InfoWindow({
							content: infoWindowContent
						});

						var $selectedStore = $('#' + storeID).find($cache.storeAddressName);

						markers.push(gMarker);
						infoWindows.push(infowindow);

						google.maps.event.addListener(gMarker, 'click', function() {
							for (var i = 0; i < infoWindows.length; i++) {
								infoWindows[i].close();
							}

							$($cache.storesListSel).find($cache.storeAddressName).each(function() {
								$(this).removeClass($cache.selectClass);
							});

							document.dispatchEvent(new CustomEvent('store.selected', { detail: storeID }));
							showInfoWindow(GoogleMap.map, infowindow, gMarker, storeID);
							$selectedStore.addClass($cache.selectClass);
							$($cache.selectedStoreList).animate({
								scrollTop: $($selectedStore).position().top
							});
						});

						if (app.util.getConfig('storeLocator.linkStoreName')) {
							$(this).closest('.b-storelocator-results_item').find($cache.storeAddressName).on('click', function(e) {
								e.preventDefault();

								for (var i = 0; i < infoWindows.length; i++) {
									infoWindows[i].close();
								}

								$($cache.storesListSel).find($cache.storeAddressName).each(function() {
									$(this).removeClass($cache.selectClass);
								});
								$selectedStore.addClass($cache.selectClass);
								GoogleMap.map.setZoom(Number(app.preferences.storeLocatorMapZoom));
								GoogleMap.map.setCenter(gMarker.getPosition());
								infowindow.open(GoogleMap.map, gMarker);
								$cache.htmlBody.animate({
									scrollTop: $($cache.storelocatorWrapSel).offset().top - $cache.header.outerHeight()
								});
							});
						} else {
							$(this).on('click', function(e) {
								e.preventDefault();

								for (var i = 0; i < infoWindows.length; i++) {
									infoWindows[i].close();
								}

								GoogleMap.map.setZoom(Number(app.preferences.storeLocatorMapZoom));
								GoogleMap.map.setCenter(gMarker.getPosition());
								infowindow.open(GoogleMap.map, gMarker);

								if (!$cache.showMapOnMobile) {
									$cache.htmlBody.animate({
										scrollTop: $($cache.storelocatorWrapSel).offset().top - $cache.header.outerHeight()
									});
								}
							});
						}

						bounds.extend(gMarker.position);
					});
					// don't fit bounds in case single marker. A single-marker map will use previously set zoom
					if (storeItems.length !== 1) {
						GoogleMap.map.fitBounds(bounds);
					}
				} else {
					GoogleMap.map.setZoom(COUNTRY_LEVEL_ZOOM);
				}
				$($cache.storelocatorMapSel).removeClass($cache.hideClass);
			} else {
				// if postal code entered is inexistent
				// clear map and center on searched city/ show error
				setAllMap(null);
				if (GoogleMap != null && lat != null && lng != null) {
					latLng = new google.maps.LatLng(lat, lng);
					GoogleMap.map.setCenter(latLng);
					GoogleMap.map.setZoom(10);
				} else {
					errorShow($cache.errors.zip);
				}
			}

			$($cache.findButtonSel).trigger($cache.events.storelocatorMapDisplayed);
		}
	}

	function sendZip(findByCountry, centerOnly) {
		var postalCode = $($cache.postalCodeSel).val();
		var maxDistance = app.preferences.storeLocatorDistance;
		var countryCode = $cache.isCC ? $($cache.currentLocationCountrySel).val() : $cache.countrySelector.val();

		if (postalCode !== '' || findByCountry) {
			var locatorUrl = app.urls.findByZip,
				params = {
					postalCode: postalCode,
					countryCode: countryCode,
					maxDistance: maxDistance,
				};
			errorHide();
			if ($cache.isCC) {
				locatorUrl = app.util.appendParamToURL(locatorUrl, 'clickandcollect', true);
			}
			locatorUrl = app.util.appendParamsToUrl(locatorUrl, params);
			$.ajax({
				url: locatorUrl,
				success: function(response) {
					$(document).trigger('storelocator.search', response);
					if ($(response).find('.js-gmap').length == 0 && app.preferences.storeLocatorShowZipErrorResult) {
						var locatorUrl = location.href;
						locatorUrl = app.util.appendParamToURL(app.urls.getStoreList, 'countryCode', countryCode);
						if ($cache.isCC) {
							locatorUrl = app.util.appendParamToURL(locatorUrl, 'clickandcollect', true);
						}
						$.ajax({
							url: locatorUrl,
							success: function(response) {
								storeLocatorDisplayMap(response, null, null, centerOnly);
							},
						});
					} else {
						storeLocatorDisplayMap(response, null, null, centerOnly);
					}
				},
			});
		}
	}

	function findByCountryOnly(centerOnly) {
		var selectedOption = $cache.countrySelector.find('option:selected'),
			countryCode = $cache.isCC
				? $($cache.currentLocationCountrySel).val()
				: selectedOption.is(':disabled') ? null : selectedOption.val(),
			countryName = selectedOption.is(':disabled') ? '' : ' ' + selectedOption.text();

		var locatorUrl = app.urls.findByCountryOnly;

		errorHide();
		if ($cache.isCC) {
			locatorUrl = app.util.appendParamToURL(locatorUrl, 'clickandcollect', true);
		}
		locatorUrl = app.util.appendParamToURL(locatorUrl, 'countryCode', countryCode);

		// search google maps for latitude and longitude
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({ address: countryName }, function(results, status) {
			if (status === google.maps.GeocoderStatus.OK) {
				$.ajax({
					url: locatorUrl,
					success: function(response) {
						storeLocatorDisplayMap(response, null, null, centerOnly);
					},
				});
			} else {
				if (app.preferences.storeLocatorFindInCountry) {
					sendZip();
				} else {
					jQuery.ajax({
						url: locatorUrl,
						success: storeLocatorDisplayMap,
					});
				}
			}
		});
	}

	function findByCity() {
		var city = $.trim($($cache.postalCodeSel).val());
		var selectedOption = $cache.countrySelector.find('option:selected');
		var countryCode;
		var locatorUrl = app.urls.findByCity;
		var params = {
			countryCode: countryCode,
			city: city.toUpperCase()
		};

		if ($cache.isCC) {
			countryCode = $($cache.currentLocationCountrySel).val();
		} else if (selectedOption.is(':disabled')) {
			countryCode = null;
		} else if (localStorage.getItem('currentLocationCountry')) {
			countryCode = localStorage.getItem('currentLocationCountry');
		} else {
			countryCode = selectedOption.val();
		}

		errorHide();
		if ($cache.isCC) {
			locatorUrl = app.util.appendParamToURL(locatorUrl, 'clickandcollect', true);
		}
		locatorUrl = app.util.appendParamsToUrl(locatorUrl, params);

		// search google maps for latitude and longitude
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode(
			{
				componentRestrictions: {
					country: countryCode,
					locality: city,
				},
			},
			function(results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					var gCountry = getCountry(results);
					if (gCountry && gCountry === countryCode.toLowerCase()) {
						if (app.preferences.storeLocatorFindInCountry && city == '') {
							sendZip(app.preferences.storeLocatorFindInCountry);
						}
						var lat = results[0].geometry.location.lat(),
							lng = results[0].geometry.location.lng();

						locatorUrl = app.util.appendParamsToUrl(locatorUrl, { latitude: lat, longitude: lng });
						jQuery.ajax({
							url: locatorUrl,
							success: function(response) {
								storeLocatorDisplayMap(response, lat, lng);
							},
						});
						return;
					}
				}
				setAllMap(null);
				$($cache.storesListSel).empty();
				errorShow($cache.errors.city);
			}
		);
	}

	function findByMultiSelect(params) {
		if (!params) {
			return;
		}

		// filter out falsy params
		var sourceParams = {};
		for (var i in params) {
			if (params[i]) {
				sourceParams[i] = params[i];
			}
		}

		var locationUrl = app.util.appendParamsToUrl(app.urls.findByMultiselect, sourceParams),
			geocoder = new google.maps.Geocoder(),
			componentRestrictions = {
				country: sourceParams.countryCode,
			};

		if (sourceParams.city) {
			componentRestrictions['locality'] = sourceParams.city;
		}

		geocoder.geocode(
			{
				componentRestrictions: componentRestrictions,
			},
			function(results, status) {
				if (status === google.maps.GeocoderStatus.OK) {
					$.ajax({
						url: locationUrl,
						success: function(response) {
							storeLocatorDisplayMap(response, null, null);
						},
					});
				}
			}
		);
	}

	function getCountry(results) {
		for (var i = 0; i < results[0].address_components.length; i++) {
			var type = results[0].address_components[i].types;
			if (type.indexOf('country') != -1) {
				var shortName = results[0].address_components[i].short_name;
				return shortName.length ? shortName.toLowerCase() : null;
			}
		}
		return null;
	}

	function checkZipOrCity() {
		validateZip();
		if (!$cache.isUpsAP) {
			var selectedOption = $cache.countrySelector.find('option:selected'),
				countryCode = $cache.isCC
					? $($cache.currentLocationCountrySel).val()
					: selectedOption.is(':disabled') ? null : selectedOption.val(),
				selectedStateOption,
				stateCode,
				selectedPostalOption,
				postalCode,
				selectedTypeOption,
				storeType,
				multiselectParams;

			if (app.preferences.storelocatorMultiselectEnable && app.page.ns !== 'checkout') {
				selectedStateOption = $cache.stateSelector.find('option:selected');
				stateCode = selectedStateOption.is(':disabled') ? null : selectedStateOption.val();
				selectedPostalOption = $cache.citySelector.find('option:selected');
				postalCode = selectedPostalOption.is(':disabled') ? null : selectedPostalOption.val();
				selectedTypeOption = $cache.storeTypeSelector.find('option:selected');
				storeType = selectedTypeOption.attr('value') === 'alltypes' ? null : selectedTypeOption.val();

				multiselectParams = {
					countryCode: countryCode,
					stateCode: stateCode,
					city: postalCode,
					multiselectStoreType: storeType,
				};
				findByMultiSelect(multiselectParams);
			} else {
				postalCode = $($cache.postalCodeSel).length ? $($cache.postalCodeSel).val() + '' : '';
				if (!postalCode) {
					findByCountryOnly();
				} else {
					// detect zipCode
					if (/\d/.test(postalCode)) {
						if (app.validator.validateZipByCountry(countryCode, postalCode)) {
							sendZip();
						} else {
							errorShow($cache.errors.zip);
						}
					} else {
						findByCity();
					}
				}
			}
		} else {
			$($cache.findButtonSel).trigger($cache.events.upsapSearch);
		}
	}

	function validateStoreType() {
		if (app.preferences.storelocatorMultiselectEnable && app.preferences.emptyStoreTypeInclude) {
			return $cache.storeTypeSelector.find('option:selected').is(':not(:disabled)');
		}
		return true;
	}

	function validateStoreSearchParams() {
		if (!validateStoreType()) {
			return;
		}
		checkZipOrCity();
	}

	function isStoreNotSelected() {
		if ($cache.isCC || $cache.isUpsAP) {
			if (
				!$($cache.selectedStoreSel).children().length
				|| ($cache.address1Field.length && $cache.address1Field.val().length < 3)
			) {
				errorShow($cache.errors.notselected);
				scrollToStoreError();
				hideProgress();
				return false;
			}
		}
		return true;
	}

	/*************** app.components.global.storelocator public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.storelocator = {
		// initializations
		// called by google maps callback parameter
		init: function(params) {
			app.storelocator.init();
			initializeCache();
			initializeDom();
			initializeEvents();
		},
		fetchStoreDependencies: fetchStoreDependencies,
		isStoreNotSelected: isStoreNotSelected,
		storeLocatorDisplayMap: storeLocatorDisplayMap,
		getMarkers: function() {
			return markers;
		},
		getInfoWindows: function() {
			return infoWindows;
		},
		showInfoWindow: showInfoWindow
	};
})((window.app = window.app || {}), jQuery);
