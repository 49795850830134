(function (app, $) {

	var $cache = {},
		event = 'click';

	function initializeCache() {
		$cache = {
			document : $(document),
			toggler : $('.js-toggler'),
			activeToggle : null,
			activeToggleElement : null,
			defaultToggleToHeightClass : 'h-minimized'
		};
	}

	function initializeEvents(toggler) {
		$(toggler).each(function(){
			var $this = $(this),
				toggleToHeightClass = $this.data('toggle-class') || $cache.defaultToggleToHeightClass,
				toggleElementClass = !!$this.data('toggle-elem-class') ? $this.data('toggle-elem-class') : '',
				$toggleElement = $($this.data('slide')),
				$closeElem = !!$this.data('close-element') ? $toggleElement.find($this.data('close-element')) : '',
				$cloneElm = !!$this.data('clone'),
				isToggled = toggleElementClass && $this.hasClass(toggleElementClass),
				closeOnOutsideClick = $this.data('toggle-closeonoutsideclick') === 'yes',
				closeOnesc = $this.data('toggle-closeonesc') === 'yes';

			$this.on('toggleOff', toggleOff);
			$this.on('toggleOn', toggleOn);

			function documentClickEvent(e) {
				if(
					e.target !== $this
					&& $this.hasClass(toggleElementClass)
					&& !$toggleElement.is(e.target) // if the target of the click isn't the container...
					&& $toggleElement.has(e.target).length === 0 // ... nor a descendant of the container
					&& (
						!$cache.activeToggle
						|| (
							!$cache.activeToggle.is(e.target) // if the target of the click isn't the container...
							&& $cache.activeToggle.has(e.target).length === 0 // ... nor a descendant of the container
						)
					)
				) {
					toggleOff();
				}
			}

			function escElement(e) {
				if (e.type === 'keydown' && e.which === 27) {
					toggleOff();
				}
			}

			function closeElementEvent() {
				$cache.document.trigger('close.element.toggle', $closeElem);
				toggleOff();
			}

			function toggleOn() {
				if(toggleElementClass) {
					$this.addClass(toggleElementClass);
				}
				$toggleElement.removeClass(toggleToHeightClass);

				if($this.data('less')){
					$this
						.html($this.data('more'))
						.addClass(toggleToHeightClass + '-switcher');
				}

				if($closeElem.length) {
					$closeElem.bind('click', closeElementEvent);
				}

				$cache.activeToggle = $this;
				$cache.activeToggleElement = $toggleElement;
				isToggled = true;

				$cache.document.trigger('toggle.finished', {
					target : $toggleElement
				});

				if(closeOnOutsideClick){
					$cache.document.bind('click', documentClickEvent)
				}

				if(closeOnesc){
					$cache.document.bind('keydown', escElement)
				}
			}

			function toggleOff() {
				if(toggleElementClass) {
					$this.removeClass(toggleElementClass);
				}
				$toggleElement.addClass(toggleToHeightClass);

				if($this.data('less')){
					$this
						.html($this.data('less'))
						.removeClass(toggleToHeightClass + '-switcher');
				}
				if($closeElem.length) {
					$closeElem.unbind('click', toggleOff);
				}
				if(closeOnOutsideClick){
					$cache.document.unbind('click', documentClickEvent)
				}
				if(closeOnesc){
					$cache.document.unbind('keydown', escElement)
				}

				$cache.document.trigger('toggle.finished', {
					target : $toggleElement
				});

				$cache.activeToggle = null;
				$cache.activeToggleElement = null;
				isToggled = false;
			}

			if( !$this.data('toggle-slide') ) {
				$this.on(event, function(e) {
					if(e.currentTarget !== e.target && $(e.currentTarget).has(e.target).length === 0) {
						return;
					}
					if($cloneElm){
						$cloneElm.trigger(event);
					}
					if(isToggled && !$toggleElement.hasClass(toggleToHeightClass)){
						toggleOff();
					}
					else{
						if($cache.activeToggle && $cache.activeToggle !== $this){
							$cache.activeToggle.trigger('toggleOff');
						}
						toggleOn();
					}

					e.stopPropagation();
				});

				$this.data('toggle-slide', true);
			}
		});
	}

	/*************** app.components.global.toggler public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.toggler = {
		init : function () {
			initializeCache();
			initializeEvents($cache.toggler);
		},
		reinit : function (elements) {
			initializeEvents(elements || $cache.toggler);
		}
	};
})(window.app = window.app || {}, jQuery);
