import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			listItemPage: $('.js-search_result-content'),
			wishlistBlock: $('.js-wishlistblock'),
			wishlistBlockTitleSel: '.js-wishlistblock-title',
			wishlistBlockEmptySel: '.js-wishlist-empty-title',
			wishlistBlockContentSel: '.js-wishlistblock-content',
			wishlistAddToCartButton: '.js-wishlistblock .js-add_to_cart_button',
			wishlistRemoveButton: '.js-wishlistblock .js-wishlistblock-remove',
			wishlistBlockLoginSel: '.js-wishlistblock-login',
			showCheckoutLoginSel: '.js-wishlistblock-login-container',
			notifyMePopupSel: '.js-notifyme_popup',
			hHidden: 'h-hidden',
			hHiddenForCarousel: 'h-minimized',
			activeClass: 'active',
			carouselClass: 'js-owl_carousel',
			selectors: {
				wishlistTiles: '.js-wishlistblock-content_tiles'
			}
		};
	}

	function initializeDOM() {
		displayWishlistBlock();
	}

	function initializeEvents() {
		$cache.document.on('wishlist.added wishlist.removed', function() {
			displayWishlistBlock();
		});

		$cache.document.on('cart.updateModels', function() {
			var wishlistBlockTitle = $($cache.wishlistBlockTitleSel);

			if (wishlistBlockTitle.find($cache.wishlistBlockEmptySel).length) {
				wishlistBlockTitle.removeClass($cache.activeClass).addClass('empty');
			}
			displayWishlistBlock();
		});

		$cache.document.on(Modernizr.touchevents ? 'touchstart' : 'click', $cache.wishlistAddToCartButton, function(e) {
			e.preventDefault();
			var $this = $(this);
			var contentTile = $this.closest('.js-wishlistblock-content_tile');
			var pid = $this.data('pid');
			var pliid = contentTile.data('item');
			var pliidQty = contentTile.data('itemQty');
			var self = this;

			if ($cache.wishlistBlock.data('animated')) {
				var removeButton = contentTile.children('.js-wishlistblock-remove');
				var productTile = $this.closest('.js-product_tile');
				var addedMessage = contentTile.children('.js-wishlistblock-content-added_message');

				removeButton.animate({ opacity: 0 }, 1000);
				productTile.animate({ opacity: 0 }, 1000, function() {
					addedMessage.delay(200).fadeIn(500).delay(1500).fadeOut(500, function() {
						removeButton.addClass($cache.hHidden);
						removeProductFromWishlistBlock(pliid);
						productTile.addClass($cache.hHidden);
						if (app.page.ns !== 'checkout') {
							addToCartFromWishlistBlock(pid, pliidQty, pliid);
						} else {
							$cache.document.trigger('wishlist.cart.add', self);
						}
					});
				});
			} else if (app.page.ns !== 'checkout') {
				addToCartFromWishlistBlock(pid, pliidQty, pliid);
			} else {
				$cache.document.trigger('wishlist.cart.add', this);
			}
		});

		$cache.document.on('click', $cache.wishlistRemoveButton, function(e) {
			e.preventDefault();
			var $this = $(this);
			var pliid = $this.data('item');

			if (app.page.ns !== 'checkout' && pliid) {
				removeProductFromWishlistBlock(pliid);
			}
		});

		$cache.document.on(Modernizr.touchevents ? 'touchstart' : 'click', $cache.wishlistBlockTitleSel, function() {
			var $this = $(this);

			if (!$this.find($cache.wishlistBlockEmptySel).length) {
				var content = $this.next($cache.wishlistBlockContentSel);
				var isActive = $this.toggleClass($cache.activeClass).hasClass($cache.activeClass);

				content.toggleClass($cache.hHiddenForCarousel);
				if (isActive) {
					$.cookie($this.data('type'), 'true', { path: '/' });
				} else {
					$.removeCookie($this.data('type'), { path: '/' });
				}
			}
		});

		$cache.document.on('click', $cache.wishlistBlockLoginSel, function(e) {
			e.preventDefault();
			if (app.page.ns !== 'checkout' && app.device.currentDevice() !== 'mobile') {
				e.stopPropagation();
				app.components.account.fakelogin.show(e);
			}
		});
	}

	function displayWishlistBlock() {
		if ($cache.wishlistBlock.length) {
			var url = app.urls.getFlyoutWishlist;

			url = app.util.appendParamsToUrl(url, { wishlistblock: true, ns: app.page.ns });

			app.ajax.load({
				url: url,
				callback: function(data) {
					if (data) {
						$cache.wishlistBlock.html(data);

						if (!$cache.wishlistBlock.find('.js-product_tile').length) {
							return;
						}

						var $wishListProducts = $cache.wishlistBlock.find($cache.notifyMePopupSel);

						if ($wishListProducts.length >= 1) {
							$wishListProducts.on('click', app.product.openNotifyMePopup);
						}
						if (app.page.ns === 'checkout' || app.device.currentDevice() === 'mobile') {
							$($cache.wishlistBlockLoginSel).attr('data-show', $cache.showCheckoutLoginSel);
						}
						if ($cache.wishlistBlock.attr('data-settings')) {
							var carouselConfig = $cache.wishlistBlock.attr('data-settings');
							var $tilesContainer = $($cache.selectors.wishlistTiles);

							$tilesContainer.addClass($cache.carouselClass).attr('data-settings', carouselConfig);
							app.owlcarousel.initCarousel($tilesContainer);
						}

						document.dispatchEvent(new CustomEvent('lazyload-reinit'));
						document.dispatchEvent(new CustomEvent('price-reinit'));
						callTitleTrigger();
					}
				}
			});
		}
	}

	function removeProductFromWishlistBlock(pliid, wishlistBlockSel, pid) {
		var data;

		if (pliid) {
			data = {
				pliid: pliid,
				format: 'ajax',
				wishlistblock: true
			};

			$.ajax({
				url: app.urls.removeProdyctFromWishlist,
				type: 'GET',
				data: data
			}).done(function(responseData) {
				if (responseData) {
					if (wishlistBlockSel) {
						$(wishlistBlockSel).html(responseData);
						$cache.document.trigger('wishlist.updated', ['wishlistflyout']);
					} else {
						$cache.wishlistBlock.html(responseData);
						$cache.document.trigger('wishlist.updated', ['wishlistblock']);
						callTitleTrigger();
					}
				}

				if (pid) {
					app.wishlist.deleteFromStorage(pid);
					app.wishlist.updateWishlistButtons($cache.listItemPage);
				}
			});
		}
	}

	function addToCartFromWishlistBlock(pid, quantity, pliid, wishlistBlockSel, customproducts) {
		quantity = quantity || '1';
		const $document = $(document);

		if (pid) {
			const postdata = {
				Quantity: quantity,
				cartAction: 'add',
				pid: pid
			};

			if (typeof customproducts !== 'undefined' && customproducts !== null) {
				postdata.customProducts = customproducts;
			}

			CSRF.populatePayloadByCSRFToken(postdata)
				.then((payload) => app.cart.update(payload, function(response) {
					app.minicart.update(response);
					app.flyoutMgr.open('minicart', {
						autoClose: true
					});

					$document.trigger('minicart.afterload');
					$document.trigger('tracking.cartItemAdded');

					if (pliid) {
						removeProductFromWishlistBlock(pliid, wishlistBlockSel, postdata.pid);
					}
				}));
		}
	}

	function callTitleTrigger() {
		var title = $($cache.wishlistBlockTitleSel);
		var cookieName = title.data('type');
		var cookie;

		if (cookieName) {
			cookie = $.cookie(cookieName);
			if (!cookie) {
				closeWishlistBlockContent();
			}
		}
	}

	function closeWishlistBlockContent() {
		var title = $($cache.wishlistBlockTitleSel);
		var content = title.next($cache.wishlistBlockContentSel);

		title.removeClass($cache.activeClass);
		content.addClass($cache.hHiddenForCarousel);
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.wishlistblock = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		},
		addToCartFromWishlistBlock: addToCartFromWishlistBlock
	};
})(window.app = window.app || {}, jQuery);
