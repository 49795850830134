(function (app, $) {
	var $cache = {};
	
	function initializeCache() {  
		$cache.addBonusProductsButton = $(".js-add_bonus_products");		
	}

	function initializeEvents() {
		var initBonusProductPopupEvents = (function(){
			var inited = false,
				$popupContainer, $selectedQty, $maxQty, $carousel, $navCarousel,
				$selectedProductName, $selectedProductColor, $selectedProductSize;
			
			return function(){
				var selectedListItemTemplate = $(".js-selected_bonus_product_list_item_template").html();
				$selectedProductName = $(".js-selected_bonus_product_name");
				$selectedProductColor = $(".js-selected_bonus_product_color");
				$selectedProductSize = $(".js-selected_bonus_product_size");
				$popupContainer = $(".fancybox-inner");
				$selectedQty = $popupContainer.find(".js-bonus_products_selected");
				$maxQty = $popupContainer.find(".js-max_bonus_items");
				$navCarousel = $popupContainer.find(".js-bonus_product_nav_carousel");
				$carousel = $popupContainer.find(".js-bonus_product_carousel");
				app.owlcarousel.initCarousel($carousel);
				app.owlcarousel.initCarousel($navCarousel);
				if(!inited) {
					inited = true;
					$(document).on("click", ".js-bonus_product_nav_item", function(){
						$carousel.trigger("to.owl.carousel", $(this).data("slide"), true, 1);
						$(".js-bonus_product_nav_item").removeClass("selected");
						$(this).addClass("selected");
					});
					$(document).on("click", ".js-bonus_swatchanchor", function(e){
						e.preventDefault();		
						var anchor = $(this),
							bpItem = anchor.closest(".js-bonus_product_item"),
							bpForm = bpItem.find(".js-bonus_product_form"),
							qty = bpForm.find(".js-product_quantity").first().val(),
							params = {
								Quantity : isNaN(qty) ? "1" : qty,
								format : "ajax",
								source : "bonus"
							};
				
						var url = app.util.appendParamsToUrl(this.href, params);
				
						app.progress.show(bpItem);
						app.ajax.load({
							url: url,
							callback : function (data) {
								bpItem.html(data);
								
							}
						});
					});
					

					$(document).on("click", ".js_bonus-product-select", function(){
						var $this = $(this);
						if(+$selectedQty.text() >= +$maxQty.val() 
								|| $(".js-selected_list_item[data-pid="+$this.data("pid")+"]:visible").length > 0) {
							return false;
						}
						$this.addClass("selected b-bonus_product-selected_button");
						$popupContainer.find(".js-remove_bonus_product").removeClass("h-hidden")
						$selectedQty.text(+$selectedQty.text() + 1);
						$(".js-js-selected_list:visible").append(app.util.renderTemplate(selectedListItemTemplate, {
							id : $this.data("pid"),
							name : $this.data("name"),
							size : $this.data("size"),
							color : $this.data("color")
						}));
					});
					
					$(document).on("click", ".js-remove_bonus_product", function(){
						var $thisItem = $(this).closest(".js-selected_list_item");
							pid = $thisItem.data("pid");
						$selectedQty.text(+$selectedQty.text() - 1);
						$(".js_bonus-product-select[data-pid="+pid+"]")
							.removeClass("selected b-bonus_product-selected_button");
						$thisItem.remove();
					});
					
					$(document).on("click", ".js-add_to_cart_bonus", function(){
						var pids = [],
							url = app.urls.addBonusProduct;
						$(".js-js-selected_list:visible").find(".js-selected_list_item").each(function(){
							var pid = $(this).data("pid");
							pids.indexOf(pid) == -1 && pids.push(pid);
						});
						$.ajax({
							type : "POST",
							url : url,
							data : {
								pids : pids.join(",")
							}
						})
						.done(function(resp){
							app.page.refresh();
						})
						.always(function () {
							app.fancybox.close();
						});
					})
					
					
				}		
			}
		})();
		
		$(document).on('minicart.afterload', function() {
			var $bonusProductContainer = $('.js-bonus_discount_container');
			if($bonusProductContainer.length) {
				app.fancybox.open($bonusProductContainer.html());
				initBonusProductPopupEvents();
			}
		});
		$(document).on("click", ".js-select_bonus_btn", function(){
			app.fancybox.open($(".js-bonus_products_container").html(), {
				height : "700px"
			});
			initBonusProductPopupEvents();
		});
	}
	
	
	
/*************** app.components.global.newsletter public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.bonusproducts = {
      // initializations
		init : function (params) {
			initializeCache();
			initializeEvents();
		}
  };
})(window.app = window.app || {}, jQuery);
