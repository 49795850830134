//app.components.checkout.customerdata
(function(app, $) {
	var $cache = {},
		waitingSavedData = new $.Deferred();

	function initCache() {
		$cache = {
			window: $(window),
			document: $(document),
			allowedNames: [
				'_country',
				'_emailAddress',
				'_email',
				'_firstName',
				'_lastName',
				'_taxID',
				'_companyName',
				'_address1',
				'_address2',
				'_zip',
				'_city',
				'_state',
				'_street',
				'_district',
				'_building',
				'_addressNumber',
				'_prefecture',
				'_phoneCode',
				'_phone',
				'_vatNumber',
				'_number',
				'_month',
				'_year',
				'_owner',
				'_gender',
				'_day',
				'_giropayiban',
				'_iban',
				'_addressSuggestion'
			],
			customerBlockSel: '.js-data_customer',
			fields: {}
		};
	}

	function initEvents() {
		$cache.window.on('unload', function() {
			Data.save();
		});

		$cache.document.on('address-cloned', function(e, addressType, $inputfield) {
			if (!$.isEmptyObject($cache.fields) && $cache.fields[addressType] && $cache.fields[addressType]['_address1']) {
				if ($inputfield && $inputfield.length) {
					useNode($inputfield, addressType, '_address1');
					$inputfield.trigger('focusout');
				}
			}
		});

		$($cache.customerBlockSel).each(function() {
			var $this = $(this),
				$element,
				values,
				value,
				groupInfo = $this.data('customer_group_info');

			var key = getKeyByGroupLabel($this, groupInfo.selector);
			if (groupInfo.group && groupInfo.group.length) {
				values = Data.getByGroupByKey(groupInfo.group, key);
				$cache.fields[groupInfo.group] = {};
				for (var i = 0, l = $cache.allowedNames.length; i < l; i++) {
					$element = $this.find('[name$=' + $cache.allowedNames[i] + ']');
					if ($element.length) {
						if (values && values[$cache.allowedNames[i]]) {
							value = $element.val();
							if (!value) {
								$element.val(values[$cache.allowedNames[i]]);
							}
						}
						useNode($element, groupInfo.group, $cache.allowedNames[i]);
					}
				}
			}

			$cache.document.trigger('addressfield.autofill');
			$cache.document.trigger('creditcard.validate');
		});

		document.dispatchEvent(new Event('customer.data.received'));
	}

	function focusout() {
		var $this = $(this);
		var $group = $this.closest($cache.customerBlockSel);
		var groupInfo = $group.data('customer_group_info');
		var value = $this.val();
		var key = getKeyByGroupLabel($group, groupInfo.selector);
		var groupFields;
		var keyFields;
		var elValue;
		var $elem;
		var $newElem;

		if (groupInfo.group && groupInfo.group.length) {
			groupFields = $cache.fields[groupInfo.group];
			if (groupFields) {
				keyFields = Object.keys(groupFields);
				for (var i = 0, l = keyFields.length; i < l; i++) {
					$elem = groupFields[keyFields[i]];

					var additionalName = $elem.data('customerdataName');

					if (!$elem) {
						$newElem = $group.find('[name$="' + keyFields[i] + '"]');
						useNode($newElem, groupInfo.group, keyFields[i]);
						$elem = $newElem;
					}
					elValue = $elem.val();
					if (elValue) {
						Data.setByGroupByKeyByFieldName(groupInfo.group, key, keyFields[i], elValue);

						if (additionalName !== undefined) {
							Data.setByGroupByKeyByFieldName(groupInfo.group, key, additionalName, elValue);
						}
					}
				}
			} else if (value && value.length) {
				Data.setByGroupByKeyByFieldName(groupInfo.group, key, $this.data('field_label'), value);
			}
		}
	}

	function useNode($element, groupLabel, fieldLabel) {
		$element.on('focusout', focusout);
		$element.data('field_label', fieldLabel);
		$cache.fields[groupLabel][fieldLabel] = $element;
	}

	function getKeyByGroupLabel($block, selector) {
		var key;
		$element = $block.find(selector);
		if ($element.val() !== '') {
			key = $element.val();
		} else if ($element.attr('id') !== '') {
			key = $element.attr('id');
		}
		return key || 'DEF';
	}

	var Data = {
		data: {},
		sessionStorageEnable: document.cookie.length !== 0,
		fetch: function() {
			if (this.sessionStorageEnable) {
				var jsonString = sessionStorage.getItem('customerdata_' + app.user.id);
				if (typeof jsonString === 'string') {
					this.data = JSON.parse(jsonString);
				}
				waitingSavedData.resolve();
			} else {
				var self = this;
				$.get(app.urls.customerDataGet).done(function(response) {
					if (!response.empty) {
						self.data = JSON.parse(response.data);
					}
					waitingSavedData.resolve();
				});
			}
		},
		save: function() {
			if ($.isEmptyObject(this.data)) {
				return;
			}
			var stringData = JSON.stringify(this.data);
			if (this.sessionStorageEnable) {
				sessionStorage.setItem('customerdata_' + app.user.id, stringData);
			} else {
				if ('sendBeacon' in navigator) {
					navigator.sendBeacon(app.urls.customerDataSet, stringData);
				} else {
					$.ajax({ type: 'POST', async: false, url: app.urls.customerDataSet, data: { data: stringData } });
				}
			}
		},
		getByGroupByKey: function(group, key) {
			if (!$.isEmptyObject(this.data) && !$.isEmptyObject(this.data[group])) {
				return this.data[group][key];
			}
			return false;
		},
		setByGroupByKeyByFieldName: function(group, key, fieldName, value) {
			this.data = this.data || {};
			this.data[group] = this.data[group] || {};
			this.data[group][key] = this.data[group][key] || {};
			this.data[group][key][fieldName] = value || {};
		}
	};

	/**
	 * @namespace app.components.global.customerdata public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.customerdata = {
		init: function() {
			initCache();
			Data.fetch();
			waitingSavedData.done(function() {
				initEvents();
			});
		},
		getByGroupByKey: Data.getByGroupByKey.bind(Data)
	};
})((window.app = window.app || {}), jQuery);
