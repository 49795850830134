const hideExpiredShippingMethods = (childNodes) => {
	if (childNodes) {
		const currentHour = new Date().getHours();

		if (childNodes.length) {
			childNodes.forEach((childElement) => {
				const childClassList = childElement.classList;

				if (childClassList && childClassList.contains('js-cart_shipping_method-list_wrapper')) {
					const cutOffData = childElement.dataset.cutoff_delivery;

					if (cutOffData && cutOffData <= currentHour) {
						childClassList.add('h-hidden');
					}
				}
			});
		}
	}
};

const showMessageIfDynamicZipCodeChanged = (shippingAddressPostalCode) => {
	const dynamicShippingZipCode = shippingAddressPostalCode.dataset.dynamicshippingzipcode;
	const shippingAddressPostalCodeValue = shippingAddressPostalCode.value;
	const oldDynamicShippingMessage = document.querySelector('.js-cart_dynamic_shipping_message');

	if (oldDynamicShippingMessage) {
		oldDynamicShippingMessage.remove();
	}

	if (dynamicShippingZipCode && dynamicShippingZipCode !== shippingAddressPostalCodeValue) {
		const parentPostalCode = shippingAddressPostalCode.parentNode;
		const newDynamicShippingMessage = document.createElement('span');

		['b-cart_dynamic_shipping_message', 'js-cart_dynamic_shipping_message'].forEach((classItem) => {
			newDynamicShippingMessage.classList.add(classItem);
		});

		newDynamicShippingMessage.innerHTML = app.resources.DYNAMIC_SHIPPING_ZIP_CODE_DIFF;
		parentPostalCode.appendChild(newDynamicShippingMessage);
	}
};

const toggleShowMobileTitle = (toggleType) => {
	if (app.device.isMobileView()) {
		const titleShippingMethodBlock = document.querySelector('.js-cart_shipping_method-block_title');

		if (titleShippingMethodBlock) {
			if (toggleType === 'show') {
				titleShippingMethodBlock.classList.remove('h-hidden');
			} else {
				titleShippingMethodBlock.classList.add('h-hidden');
			}
		}
	}
};

const showDynamicZipCodeForm = (dynamicShippingZipCodeContainer, shippingCountrySelect, shippingMethodsContainer) => {
	if (dynamicShippingZipCodeContainer) {
		const countriesEnabledValue = dynamicShippingZipCodeContainer.dataset.countries.replace(/\s+/g, '').split(',');

		if (countriesEnabledValue.indexOf(shippingCountrySelect.value) !== -1) {
			const zipCodeTextBox = dynamicShippingZipCodeContainer.querySelector('#dwfrm_dynamiczipcode_dynamicshippingzipcode');

			zipCodeTextBox.value = '';
			dynamicShippingZipCodeContainer.classList.remove('h-hidden');
			shippingMethodsContainer.classList.add('h-hidden');
			askZipCode(dynamicShippingZipCodeContainer);
			toggleShowMobileTitle('hide');
		} else {
			dynamicShippingZipCodeContainer.classList.add('h-hidden');
			shippingMethodsContainer.classList.remove('h-hidden');
			toggleShowMobileTitle('show');
		}
	}
};

const askZipCode = (dynamicShippingZipCodeContainer) => {
	const messageAskZipcode = dynamicShippingZipCodeContainer.querySelector('.js-cart_dynamic_shipping_zipcode-ask_zipcode');
	const messageAskShippingMethod = dynamicShippingZipCodeContainer.querySelector('.js-cart_dynamic_shipping_zipcode-ask_shippingmethod');

	messageAskZipcode.classList.add('h-show');
	messageAskShippingMethod.classList.remove('h-show');
};

const askShippingMethod = (dynamicShippingZipCodeContainer) => {
	const messageAskZipcode = dynamicShippingZipCodeContainer.querySelector('.js-cart_dynamic_shipping_zipcode-ask_zipcode');
	const messageAskShippingMethod = dynamicShippingZipCodeContainer.querySelector('.js-cart_dynamic_shipping_zipcode-ask_shippingmethod');

	messageAskZipcode.classList.remove('h-show');
	messageAskShippingMethod.classList.add('h-show');
};

const toggleShowAskZipCodeMessage = (dynamicShippingZipCodeContainer, shippingMethodsContainer) => {
	const dynamicShippingZipCode = dynamicShippingZipCodeContainer.querySelector('#dwfrm_dynamiczipcode_dynamicshippingzipcode');

	if (dynamicShippingZipCode.value !== '') {
		shippingMethodsContainer.classList.remove('h-hidden');
		askShippingMethod(dynamicShippingZipCodeContainer);
	} else {
		shippingMethodsContainer.classList.add('h-hidden');
		askZipCode(dynamicShippingZipCodeContainer);
	}
};

const initializeEvents = (actions) => {
	const checkoutForm = document.querySelector('.js-checkout_form');

	if (checkoutForm) {
		const shippingAddressPostalCode = checkoutForm.querySelector('input[name$="_shippingAddress_addressFields_zip"]');

		if (shippingAddressPostalCode) {
			['change', 'input'].forEach((eventItem) => {
				shippingAddressPostalCode.addEventListener(eventItem, () => {
					showMessageIfDynamicZipCodeChanged(shippingAddressPostalCode);
				});
			});

			$(document).on('addressfield.autofill dialog.showed', () => {
				showMessageIfDynamicZipCodeChanged(shippingAddressPostalCode);
			});
		}
	}

	const shippingMethodsContainer = document.querySelector('[data-model=\'shippingMethods\']');
	const dynamicShippingZipCodeContainer = document.querySelector('[data-countries]');

	if (shippingMethodsContainer && dynamicShippingZipCodeContainer) {
		hideExpiredShippingMethods(shippingMethodsContainer.childNodes);

		new MutationObserver((mutationsList) => {
			mutationsList.forEach((mutation) => {
				if (mutation.type === 'childList') {
					hideExpiredShippingMethods(mutation.target.childNodes);
				}
			});
		}).observe(shippingMethodsContainer, { attributes: false, childList: true, subtree: false });

		const dynamicShippingZipCodeForm = dynamicShippingZipCodeContainer.querySelector('.js-cart_dynamic_shipping_zipcode_form');

		if (dynamicShippingZipCodeForm) {
			toggleShowAskZipCodeMessage(dynamicShippingZipCodeContainer, shippingMethodsContainer);

			dynamicShippingZipCodeForm.addEventListener('submit', (e) => {
				e.preventDefault();

				const dynamicShippingZipCodeSel = '#dwfrm_dynamiczipcode_dynamicshippingzipcode';
				const dynamicShippingZipCodeValue = e.target.querySelector(dynamicShippingZipCodeSel).value;

				if ($(dynamicShippingZipCodeSel).valid() || dynamicShippingZipCodeValue === '') {
					actions.selectShippingCountry();
					toggleShowAskZipCodeMessage(dynamicShippingZipCodeContainer, shippingMethodsContainer);

					if (app.device.isMobileView()) {
						app.fancybox.close();
					}
				}
			});
		}

		const shippingCountrySelectSel = '#dwfrm_singleshipping_shippingAddress_addressFields_country';
		const shippingCountrySelect = document.querySelector(shippingCountrySelectSel);

		if (shippingCountrySelect) {
			showDynamicZipCodeForm(dynamicShippingZipCodeContainer, shippingCountrySelect, shippingMethodsContainer);

			$(shippingCountrySelectSel).on('change', (e) => {
				showDynamicZipCodeForm(dynamicShippingZipCodeContainer, e.currentTarget, shippingMethodsContainer);
			});
		}
	}
};

class DynamicShippingMethod {
	constructor(actions) {
		if (app.preferences.isEnabledDynamicShippingMethod) {
			initializeEvents(actions);
			this.initializeDOM();
		}

		toggleShowMobileTitle(app.preferences.isEnabledDynamicShippingMethod ? 'hide' : 'show');
	}

	initializeDOM() {
		this.submitDynamicZipCodeButton = document.querySelector('.js-cart_dynamic_shipping-button');
	}

	hideApplyButtonLoader() {
		if (this.submitDynamicZipCodeButton) {
			this.submitDynamicZipCodeButton.classList.remove('b-submitted');
			app.progress.hide();
		}
	}

	showApplyButtonLoader() {
		if (this.submitDynamicZipCodeButton) {
			this.submitDynamicZipCodeButton.classList.add('b-submitted');
			app.progress.show($('.js-cart_dynamic_shipping-button'));
		}
	}
}

export default DynamicShippingMethod;
