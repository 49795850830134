/**
 * @class app.search
 */
(function(app, $) {
	var $cache = {};
	var popped = 'state' in window.history && window.history.state !== null;

	/**
	 * @private
	 * @function
	 * @description Fix for ie8 Infinite Scroll Bar issue and QuickView Fix
	 *				(along with CSS changes)
	 *
	 * @typedef {Object} StateObject - A custom type definition for the state object from the browser history API.
	 * @property {Number} [infiniteLoads] - The number of infinite loads that have been performed.
	 * @property {Object} [position] - The position of the user's scroll.
	 * @property {string} [url] - The current URL.
	 * @property {Object} [backPosition] - The position to scroll back to when the user navigates back.
	 * @property {boolean} [preventPageReload] - Whether to prevent the page from reloading when the user navigates back.
	 */

	function initializeCache() {
		$cache = {
			document: $(document),
			window: $(window),
			main: $('main'),
			items: $('#search-result-items'),
			feedGreedIcon: $('.js-feed_button, .js-grid_button'),
			subViewConteinerSel: '.js-sub-view-conteiner',
			footer: $('footer'),
			tileSel: '.js-product_tile',
			tileContainerSel: '.js-product_tile_container',
			loadrClassKey: 'searchLoaderClass',
			hHidden: 'h-hidden',
			pageContainer: '.js-list_item_page, .experience-region',
			seoLastPage: '.js-last_page',
			searchResultContainer: '.js-search_result-content',
			infiniteScrollLoadingCircle: 'js-infinite_scroll-loading b-infinite_scroll_icon',
			infiniteScrollContainerSel: '.js-infinite_scroll_container',
			viewUnloadFirstSel: '.js-sub-view-conteiner.unloaded:first',
			viewFirstLoadedSel: '.js-sub-view-conteiner.firstLoaded:first',
			refinementSel: '.js-refinement',
			stickyRefinementsClass: '.js-refinements-sticky',
			refinementListWrapper: '.js-refinement-list-wrapper',
			refinementFilters: '.js-filter .js-refinement-filter',
			relaxRefineLinkClass: 'js-breadcrumb_refinement-link',
			refinementSectionSelector: app.device.isMobileUserAgent() ? '.js-search_actions_top_bar'
				: '.js-search_result-options',
			noHitsPlaceholderSelector: '.js-nohits-refinements-placeholder',
			lastProductClass: 'b-product_tile-last',
			last_scroll: 0,
			scrollUp: 0,
			scrollDown: 0,
			productWasClicked: false,
			lastScrollTime: 0,
			minScrollTime: 150, //ms
			filterParamURL: 'prefn1',
			lastHistoryHref: null,
			ajaxUpdatingPLP: {},
			subviewInfiniteLoadsSel: '.js-subview-infinite-loads',
			loadNextCtrlSel: '.js-load-next-control',
			loadNextPageSel: '.js-load_next_page',
			loadedHitsTextSel: '.js-paging-controls-loaded',
			filtersBlockSel: '.js-min_refinement_selector',
			showLoadBtnCls: 'show-load-btn',
			pageParameterPattern: '[?&]page=([^&#]*)',
			baseUrl: window.location.href,
			header: $('.js-header_main'),
			scrollTimeout: false,
			listItemPage: $('.js-search_result-content'),
			buttonWithLoadingClass: app.util.getConfig('plp.loadMoreBtnCls'),
			progressBarSel: '.js-progress_bar',
			loadingIconSel: '.js-loading_icon',
			plpMobilePreload: app.configs.plpPreload && app.device.isMobileView(),
			browser: app.device.currentBrowser(),
			isCached: false,
			sliderFirstImgSel: '.js-owl_carousel-image--first',
			pageDesigner: {
				containerSelector: '.storepage',
				staticRegionOnlyClass: 'm-dynamic_empty',
				staticRegionSel: '.experience-staticRegion',
			},
			$plpCategoryTopBanner: $('.js-category-banner')
		};
		$cache.headerHeight = $cache.header.css('position') === 'fixed' ? $cache.header.height() : 0;
		$cache.content = $cache.main.find($cache.searchResultContainer);
		$cache.infinityScrollShiftTop = $cache.plpMobilePreload ? $cache.window.height() * 10 : app.preferences.infinityScrollShiftTop;
		$cache.isViewportExpanded = $cache.plpMobilePreload && app.configs.enableLoadMorePlpMobile;
		initTextCache();
	}

	function initGrid() {
		app.wishlist.updateWishlistButtons($cache.listItemPage);
	}

	function initTextCache() {
		$cache.loadedHitsText = $($cache.subviewInfiniteLoadsSel).data('loaded-msg');
		$cache.loadedHitsCount = parseInt(
			$($cache.viewUnloadFirstSel)
				.find($cache.loadedHitsTextSel)
				.data('curpagesize')
		);
	}

	function initInfiniteScroll_ie8() {
		$(window).scroll(function() {
			// getting the hidden div, which is the placeholder
			// for the next page
			var loadingPlaceHolder = $('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]');

			if (
				loadingPlaceHolder.length == 1 &&
				app.util.elementInViewport(loadingPlaceHolder.get(0), $cache.infinityScrollShiftTop)
			) {
				app.search.init();
				// switch state to 'loading'
				// - switches state, so the above selector is
				// only matching once
				// - shows loading indicator
				loadingPlaceHolder.attr('data-loading-state', 'loading');
				loadingPlaceHolder.addClass('js-infinite_scroll-loading b-infinite_scroll_icon');

				// get url hidden in DOM
				var gridUrl = loadingPlaceHolder.attr('data-grid-url');

				/**
				 * named wrapper function, which can either be
				 * called, if cache is hit, or ajax repsonse is
				 * received
				 */
				var fillEndlessScrollChunk = function(content) {
					var $content = $(content);
					app.wishlist.updateWishlistButtons($content);

					loadingPlaceHolder.removeClass('js-infinite_scroll-loading b-infinite_scroll_icon');
					loadingPlaceHolder.attr('data-loading-state', 'loaded');
					$cache.content.append($content);
					$cache.document.trigger('grid-update', {
						container: $content
					});
				};

				jQuery.ajax({
					type: 'GET',
					dataType: 'html',
					url: gridUrl,
					success: function(response) {
						fillEndlessScrollChunk(response);
						$cache.document.trigger('nextpage-loaded');
					}
				});
			}
		});
	}

	/**
	 * @private
	 * @function
	 * @description replaces breadcrumbs, lefthand nav and product listing with
	 *			  ajax and puts a loading indicator over the product listing
	 */
	function updateProductListing(url, inHistory, extParams, loaderClassKey, showLoader, onProductListingUpdated) {
		var hash = encodeURI(decodeURI(window.location.hash)),
			showLoader = typeof showLoader === 'undefined' ? true : showLoader,
			currentNoHitsPlaceholder = $cache.main.find($cache.noHitsPlaceholderSelector);
		if (hash === '#results-content' || hash === '#results-products') {
			return;
		}

		if (!inHistory) {
			app.search.updateUrl(url);
			if (!app.search.pushAvailable) {
				return;
			}
		}

		var refineUrl = url;

		if (!refineUrl) {
			return;
		}

		function isStaticGridActive() {
			var $pageDesignerRegionsContainer = $('main').find($cache.pageDesigner.containerSelector);

			return (
				$pageDesignerRegionsContainer.length > 0
				&& $pageDesignerRegionsContainer.parent().hasClass('active')
				&& $pageDesignerRegionsContainer.hasClass($cache.pageDesigner.staticRegionOnlyClass));
		}

		//could not be cached because content is replaced by AJAX
		//@TODO: refactor PLP AJAX update part
		if (showLoader) {
			var progressContainerSelector = !isStaticGridActive() ? $cache.searchResultContainer : $cache.pageDesigner.staticRegionSel;

			app.progress.show($('main').find(progressContainerSelector), loaderClassKey);
		}

		$.get(app.util.appendParamToURL(refineUrl, 'format', 'ajax'), function(response) {
			var responseObj = $(response),
				responseRefinementPlaceholder = responseObj.find($cache.noHitsPlaceholderSelector),
				refinementListWrapper;

			if(responseRefinementPlaceholder.length){
				var refinementsToTake = currentNoHitsPlaceholder.length ? currentNoHitsPlaceholder.children() : $($cache.refinementSectionSelector);
				refinementsToTake.appendTo(responseRefinementPlaceholder);
				refinementListWrapper = $cache.main.find($cache.refinementListWrapper);
				extParams = $.extend({}, extParams, {noHitsPage: true});
			}
			else{
				refinementListWrapper = responseObj.find($cache.refinementListWrapper);
			}

			$cache.document.trigger('hide-ajax-refinement-delay', [refinementListWrapper]);
			$cache.main.html(responseObj);
			$($cache.searchResultContainer).addClass($cache.hHidden);
			app.componentsMgr.loadComponent('search.priceslider');
			showLoader && app.progress.hide();
			$cache.document.trigger('refinements-update', extParams);
			$($cache.searchResultContainer).removeClass($cache.hHidden);
			if (app.clientcache.LISTING_INFINITE_SCROLL) {
				$cache.document.trigger('grid-update', {
					container: responseObj
				});
			}
			if ($cache.feedGreedIcon.length) {
				var feedGrid = $($cache.feedGreedIcon);
				for (var i = 0; i < feedGrid.length; i++) {
					if ($(feedGrid[i]).hasClass('m-active_header_icon')) {
						$(feedGrid[i]).trigger('click');
						break;
					}
				}
			}
			$cache.document.one('mousemove', function(evt) {
				$cache.document.trigger('track-updated-refinement', [evt.pageX, evt.pageY, refinementListWrapper]);
			});
			initTextCache();
			$cache.document.trigger('refinements-loaded', extParams);
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
			document.dispatchEvent(new CustomEvent('price-reinit'));

			if (typeof onProductListingUpdated === 'function') {
				onProductListingUpdated();
			}
		});
	}
	/**
	 * @private
	 * @function
	 * @description
	 */

	function abortAjaxUpdatingPLP() {
		for (var key in $cache.ajaxUpdatingPLP) {
			$cache.ajaxUpdatingPLP[key].abort();
		}
	}
	function initInfiniteScroll() {

		$cache.document.on('scroll grid-update scrolldown.finished grid-preload-update grid-update-afterLoad', function(e) {
			// getting the hidden div, which is the placeholder
			// for the next page
			var eventType = e.type,
				subviewLoadsElem = $($cache.subviewInfiniteLoadsSel),
				loadingPlaceHolder = null,
				subCategoryContainer = null,
				subCategoryID = '';
			if (
				$($cache.subViewConteinerSel).length &&
				(!app.preferences.subViewFilterDisable || location.search.indexOf($cache.filterParamURL) === -1)
			) {
				if (app.search.lockLoading) {
					return false;
				}
				firstLoadedSubCategoryContainer = $($cache.viewFirstLoadedSel);
				subCategoryContainer = firstLoadedSubCategoryContainer.prev($cache.viewUnloadFirstSel);
				if (!subCategoryContainer.length) {
					subCategoryContainer = $($cache.viewUnloadFirstSel);
				}
				if (subCategoryContainer.find('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]').length === 0) {
					subCategoryContainer.removeClass('unloaded').addClass('loaded');
					subCategoryContainer.find($cache.tileSel + ':last').addClass($cache.lastProductClass);
					subCategoryContainer = $($cache.viewUnloadFirstSel);
				}
				loadingPlaceHolder = subCategoryContainer
					.find('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]')
					.first();
				subCategoryID = loadingPlaceHolder.data('subcategory');
			} else {
				loadingPlaceHolder = $('.js-infinite_scroll-placeholder[data-loading-state="unloaded"]');
			}

			if (
				app.preferences.enableInfiniteScrollForSEO &&
				eventType === 'scroll' &&
				(!subviewLoadsElem.length || app.util.getConfig('seo.paginationOnUrl'))
			) {
				var scroll_pos = getWindowScrollTop();

				//Disable anchor back functional if user manual scroll page
				$cache.document.off('grid-preload-updated');

				// Adjust the URL based on the top item shown
				// for reasonable amounts of items
				if (Math.abs(scroll_pos - $cache.last_scroll) > 0 && !$cache.productWasClicked) {
					$cache.last_scroll = scroll_pos;
					var currentUrlParameters = new RegExp($cache.pageParameterPattern).exec(window.location.href),
						currentPage = currentUrlParameters ? currentUrlParameters[1] : 0,
						scrollBottom = scroll_pos + getWindowInnerHeight(),
						dataGridUrl,
						dataPageNumber;

					if (
						!loadingPlaceHolder.length &&
						$($cache.seoLastPage).length &&
						currentPage !== $($cache.seoLastPage).data('page') &&
						getWindowScrollTop() + getWindowInnerHeight() >= $cache.document.height()
					) {
						dataGridUrl = encodeURI($($cache.seoLastPage).data('grid-url') || '');

						if ($cache.lastHistoryHref !== dataGridUrl) {
							dataGridUrl = app.util.appendParamFromLocationHref(dataGridUrl, 'isFilterOpened');

							window.history.replaceState({}, '', dataGridUrl);
							$cache.lastHistoryHref = dataGridUrl;
						}

						return false;
					} else {
						clearTimeout($cache.scrollTimeout);
						$cache.scrollTimeout = setTimeout(function() {
							var elementsCount = $($cache.pageContainer).length;

							$($cache.pageContainer).each(function(key) {
								if (mostlyVisible(this, key + 1, elementsCount)) {
									var stateObject = {};

									stateObject.distance = getWindowScrollTop() + $cache.headerHeight * 2 - $(this).offset().top;

									if (!subviewLoadsElem.length) {
										dataGridUrl = $(this).data('grid-url');
									} else {
										dataGridUrl = app.util.appendParamToURL(
											app.util.removeParamFromURL($cache.baseUrl, 'subcategoryID'),
											'subcategoryID',
											$(this).data('subcategory-id')
										);
									}

									dataPageNumber = $(this).data('page');

									if (dataPageNumber > 1) {
										history.replaceState(stateObject, '', encodeURI(dataGridUrl || ''));
									} else {
										history.replaceState(
											stateObject,
											'',
											encodeURI(dataGridUrl.replace(new RegExp($cache.pageParameterPattern), ''))
										);
									}

									$cache.lastHistoryHref = dataGridUrl;

									return false;
								}
							});
						}, 100);
					}
				}
			}

			loadingPlaceHolder.each(function(index) {
				var $that = $(this);
				var previousListItem = $that.prevAll($cache.pageContainer + ':first');

				if ($that.data('direction') == 'prev' && previousListItem.data('gridUrl')) {
					var pageNumberInPlaceholder = app.util.getQueryStringParams($that.data('gridUrl')).page;
					var pageNumberInClosestPreviousListItem = app.util.getQueryStringParams(previousListItem.data('gridUrl'))
						.page;

					if (pageNumberInPlaceholder < pageNumberInClosestPreviousListItem) {
						$that.attr('data-loading-state', 'loaded');
						return true;
					}
				}
				if (
					app.util.elementInViewport($that.get(0), $cache.infinityScrollShiftTop)
					|| eventType === 'grid-preload-update' || $cache.isViewportExpanded
				) {
					// switch state to 'loading'
					// - switches state, so the above selector is
					// only matching once
					// - shows loading indicator
					var $this = $(this);
					if ($($cache.subViewConteinerSel).length) {
						app.search.lockLoading = true;
					}
					$this.add(subviewLoadsElem).attr('data-loading-state', 'loading');
					$this.addClass($cache.infiniteScrollLoadingCircle);
					$cache.document.trigger('search.gridupdate.start');

					// get url hidden in DOM
					var gridUrl = $this.data('gridUrl');

					//can be used custom tiles container
					var customContainer =
						$($cache.subViewConteinerSel).length &&
						(!app.preferences.subViewFilterDisable || location.search.indexOf($cache.filterParamURL) === -1)
							? subCategoryContainer
							: $($this.data('gridContainer'));
					var gridContainer = customContainer.length ? customContainer : $this.parents($cache.searchResultContainer);

					/**
					 * named wrapper function, which can either be
					 * called, if cache is hit, or ajax repsonse is
					 * received
					 */
					var fillEndlessScrollChunk = function(content) {
						var $content = $(content);
						app.wishlist.updateWishlistButtons($content);
						var $subCategoryContainer = $('.js-subcategory-' + subCategoryID);
						var $containerToUpdate = $subCategoryContainer.length > 0 ? $subCategoryContainer : gridContainer;
						var infiniteScrollContainer = $that.parent($cache.infiniteScrollContainerSel);
						$subCategoryContainer.removeClass($cache.hHidden);
						$that.removeClass($cache.infiniteScrollLoadingCircle);

						if (infiniteScrollContainer.length) {
							infiniteScrollContainer.addClass($cache.hHidden);
						}

						$that.add(subviewLoadsElem).attr('data-loading-state', 'loaded');
						if ($that.hasClass('js-next')) {
							$containerToUpdate.append($content);
							$cache.document.trigger('infiniteScroll.content.appended', {
								container: $containerToUpdate,
								content: content
							});
							contentPostLoad();
							var contentImages = [];

							$content.find('img').each(function(key) {
								contentImages[key] = key;

								$(this).load(function() {
									contentImages.splice(contentImages.indexOf(key), 1);

									if (contentImages.length === 0) {
										contentScrollDown();
									}
								});
							});
						} else {
							var currentScrollTop = getWindowScrollTop();
							gridContainer.prepend($content);

							$cache.document.trigger('infiniteScroll.content.prepended', {
								container: gridContainer,
								content: content
							});

							if ($cache.scrollUp < 0) {
								window.scrollTo(0, 0);
							}
							if (currentScrollTop === getWindowScrollTop()) {
								var positionFirst = $($cache.pageContainer).first().offset();
								var	positionSecond = $($cache.pageContainer).eq(1).length ? $($cache.pageContainer).eq(1).offset() : { top: 0 };
								window.scrollBy(0, (positionSecond.top !== 0 ? (positionSecond.top - positionFirst.top) : 0) + $cache.scrollUp);
							}

							$cache.scrollUp = 0;
							contentPostLoad();
							$cache.document.trigger('nextpage-loaded');
						}

						function contentScrollDown() {
							if ($cache.scrollDown) {
								if (typeof $cache.scrollDown === 'function') {
									$cache.scrollDown = $cache.scrollDown();
								}
								window.scrollBy(0, $cache.scrollDown);
								$cache.scrollDown = 0;
							}
						}

						function contentPostLoad() {
							$that.remove();
							if (subviewLoadsElem.length) {
								subviewLoadsElem.data('infinite-loads', new Number(subviewLoadsElem.data('infinite-loads')) + 1);
							}
							$cache.document.trigger('grid-update', {
								content: content,
								container: $content
							});
							app.search.lockLoading = false;
							// Next event generate a special for anchor Back function.
							$cache.document.trigger('grid-preload-updated');
							document.dispatchEvent(new CustomEvent('lazyload-reinit'));
						}
					};

					$cache.ajaxUpdatingPLP[$that.data('direction')] = $.ajax({
						url: gridUrl,
						data: {
							format: 'page-element',
							direction: $that.attr('data-direction'),
							position: $that.attr('data-position'),
							subview: $that.attr('data-subview'),
							loadall: $that.attr('data-loadall'),
							comparecgid: $that.attr('data-comparecgid'),
							subcategory: $that.attr('data-issubcategory')
						}
					}).done(function(response) {
						fillEndlessScrollChunk(response);
						$cache.document.trigger('nextpage-loaded');
						$cache.document.trigger('scroll-loaded');
					}).fail(function(xhr, textStatus) {
						if (textStatus === 'parsererror') {
							window.alert(resources.BAD_RESPONSE);
						}
					}).always(function() {
						app.progress.hide();
					});
				}
			});
		});

		// fix behavior for SEO pagination if AnchorBack enabled this style attr was added in appresources.isml
		// TODO: Refactoring: remove add style attr in appresources.isml
		$cache.content.removeAttr('style');
		//SEO initInfiniteScroll ancor Back functionality
		if (app.preferences.enableInfiniteScrollForSEO) {
			var subCatId = app.util.getParamFromUrl(window.location.href, 'subcategoryID');

			var historyState = (window.history && window.history.state) || {};
			var updateAfterLoad = true;
			if (subCatId !== 0) {
				var element = $('*[data-subcategory-id="' + subCatId + '"]');

				if (element.length) {
					var distance = 0;
					var elementPosition = 0;
					if ('distance' in historyState) {
						distance = window.history.state.distance;
						elementPosition = distance;
					}
					elementPosition += element.offset().top - $cache.headerHeight;
					$cache.window.scrollTop(elementPosition);

					$cache.scrollDown = function() {
						return element.offset().top - $cache.headerHeight * 2 - getWindowScrollTop() + distance;
					};
				}
			} else if (historyState.backPosition) {
				var $searchResultContainer = $($cache.searchResultContainer);

				if (historyState.backPosition.async) {
					$cache.document.on('scroll-loaded', function() {
						scrollToHistoryStateBackPosition(historyState);
						$cache.document.off('scroll-loaded');
					});
				} else {
					scrollToHistoryStateBackPosition(historyState);
				}
			} else if ('url' in historyState && historyState.url === window.location.href) {
				//AnchorBack for "Sub category view" case without #anchorBack.
				if (historyState.infiniteLoads) {
					$cache.document.on('grid-preload-updated', function() {
						if ($($cache.subviewInfiniteLoadsSel).data('infiniteLoads') < historyState.infiniteLoads) {
							anchorBackLoadNextPage();
						} else if (historyState.position) {
							window.scrollTo(0, historyState.position);
							//Disable anchor back functional after done
							$cache.document.off('grid-preload-updated');
						}
					});
					anchorBackLoadNextPage();
					updateAfterLoad = false;
				} else if (historyState.position) {
					//Scroll to element on first page if no pages was loaded
					window.scrollTo(0, historyState.position);
				}
			}
			//Update grid after document is ready
			updateAfterLoad && $cache.document.trigger('grid-update-afterLoad');
		}

		function scrollToHistoryStateBackPosition(historyStateObj) {
			if (historyStateObj.backPosition.y > 0) {

				if (historyStateObj.backPosition.async) {
					window.history.state.backPosition.async = false;
				}

				window.scrollTo(0, historyStateObj.backPosition.y);
				$cache.scrollDown = historyStateObj.backPosition.y - getWindowScrollTop() || 0;

				if (popped && $cache.browser === 'safari') {
					// Safari when starts from a position >0 doesn't load the images above the current position
					$cache.window.trigger('lazyloadall');
				}
			} else {
				$cache.scrollUp = historyStateObj.backPosition.y;
			}
		}

		function anchorBackLoadNextPage() {
			if (app.preferences.isHitsAutoLoad || $($cache.subViewConteinerSel).length) {
				$cache.document.trigger('grid-preload-update');
			} else {
				app.search.loadNextPage($($cache.loadNextPageSel)[0] || null);
			}
		}

		function mostlyVisible(element, elementNumber, elementsCount) {
			// if ca 25% of element is visible
			var $elem = $(element),
				$next = $elem.nextAll($cache.pageContainer + ':first'),
				scroll_pos = getWindowScrollTop(),
				el_top = $elem.offset().top,
				el_height = $elem.height() || ($next.length && $next.offset().top - el_top),
				el_bottom = el_top + el_height,
				ret = el_bottom - el_height * 0.25 > scroll_pos && el_top < scroll_pos + 0.5 * getWindowInnerHeight();

			if (elementNumber === 1) {
				ret = ret || scroll_pos < el_top;
			} else if (elementNumber === elementsCount) {
				ret = ret || (scroll_pos > el_bottom && el_top <= scroll_pos);
			}

			return ret;
		}


	}
	/**
	 * @private
	 * @function
	 * @description Initializes events for the following elements:<br/>
	 *	<p>
	 *		refinement blocks
	 *	</p>
	 *	<p>
	 *		updating grid: refinements, pagination, breadcrumb
	 *	</p>
	 *	<p>
	 *		item click
	 *	</p>
	 *	<p>
	 *		sorting changes
	 *	</p>
	 */

	/*
	 * Next function return current scroll top
	 */
	function getWindowScrollTop() {
		return (
			window.scrollY ||
			window.pageYOffset ||
			document.body.scrollTop + ((document.documentElement && document.documentElement.scrollTop) || 0)
		);
	}

	function getMaxOccupiedLocation($elem) {
		var maxX = 0;
		var maxY = 0;

		maxX = $elem.offset().left + $elem.width();
		maxY = $elem.offset().top + $elem.height();

		return { x: maxX, y: maxY };
	}

	/*
	 * Next function return inner window height
	 */
	function getWindowInnerHeight() {
		return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	}

	/**
	 * Sets the state of the product listing page (PLP) for browser history navigation.
	 * @param {jQuery} $currentLink - The current link element as a jQuery object.
	 */
	const setPlpState = ($currentLink) => {
		/**
		 * The infinite loads element.
		 * @type {HTMLElement}
		 */
		const infiniteLoadsEl = document.querySelector($cache.subviewInfiniteLoadsSel);
		/**
		 * The state object from the browser history API.
		 * @type {StateObject}
		 */
		const stateObject = window.history?.state ?? {};
		const DEFAULT_VALUE_PAGE_INDEX = 0;

		if (!app.preferences.enableInfiniteScrollForSEO) {
			return;
		}

		if (infiniteLoadsEl) {
			/**
			 * The new state object for the browser history API.
			 * @type {StateObject}
			 */
			const newState = {
				...stateObject,
				infiniteLoads: +infiniteLoadsEl.dataset.infiniteLoads,
				position: getWindowScrollTop(),
				url: window.location.href
			};

			window.history.replaceState(newState, '', window.location.href);

			return;
		}

		/**
		 * The closet page container as a jQuery object.
	 	 * @type {jQuery | null}
		 */
		let $closestPageCntr = $currentLink.closest($cache.pageContainer);

		if (!$closestPageCntr.length) {
			$closestPageCntr = $currentLink.closest($cache.tileSel).prevAll($cache.pageContainer).filter(':first');
		}

		if (!$closestPageCntr.length) {
			$closestPageCntr = $currentLink.parents($cache.tileContainerSel).prevAll($cache.pageContainer).filter(':first');
		}

		const pageSEOIndex = +$closestPageCntr?.data('page') || DEFAULT_VALUE_PAGE_INDEX;
		/**
		 * The new state object for the browser history API.
		 * @type {StateObject}
		 */
		const newState = {
			...stateObject,
			backPosition: getBackPositionOffsetObj(pageSEOIndex, $currentLink, $closestPageCntr),
			preventPageReload: true
		};

		window.history.replaceState(newState, '', encodeURI($closestPageCntr?.data('grid-url') || ''));
	}

	function getBackPositionOffsetObj(pageSEOIndex, $currentLink, $closestPageCntr) {
		var offsetObj = {};

		function getPreviousBatchTileTopOffset() {
			return $closestPageCntr.prevAll($cache.pageContainer + ':first').next().offset().top;
		}

		const isNotPlpTopBannerEmpty = $cache.$plpCategoryTopBanner.children().length > 0;
		const isPageSEOIndexNotEqualZero = isNotPlpTopBannerEmpty ? pageSEOIndex > 1 : pageSEOIndex >=1;

		if (isPageSEOIndexNotEqualZero && app.preferences.enableInfiniteScroll) {
			var productTileTopOffset = $currentLink.offset().top;
			var $tileContainerElement = $closestPageCntr.next().is($cache.tileContainerSel) ? $closestPageCntr.next() : $closestPageCntr.find($cache.tileContainerSel).first();
			var relativeTileOffsetInBatch = productTileTopOffset - $tileContainerElement.offset().top;

			offsetObj.y = relativeTileOffsetInBatch || (productTileTopOffset - getPreviousBatchTileTopOffset());
			offsetObj.async = relativeTileOffsetInBatch === 0;
		} else {
			var firstPos = $($cache.pageContainer + ':first').offset().top;
			var elementPos = $closestPageCntr.offset().top;

			offsetObj.y = firstPos + getWindowScrollTop() - elementPos;
		}

		return offsetObj;
	}

	function setSearchParamsToStorage($currentLink) {
		var plpParams = app.util.getQueryStringParams(window.location.search.substr(1));
		var $tile = $currentLink.closest($cache.tileSel);
		var index = null;

		if (app.preferences.enableInfiniteScrollForSEO) {
			index = $currentLink
				.closest($cache.searchResultContainer)
				.find($cache.tileSel)
				.index($tile);
		} else {
			index = $cache.content.find($cache.tileSel).index($tile);
		}

		plpParams.start = index > 0 ? (+index + 1) : 1;
		plpParams.page = plpParams.page || 1;

		if (!plpParams.cgid) {
			var cgid = app.page.pageData && app.page.pageData.currentCategoryID;

			if (cgid && typeof cgid !== 'object') {
				plpParams.cgid = cgid;
			}
		}

		try {
			sessionStorage.setItem('searchParams', app.util.convertMapToQueryString(plpParams));
			return true;
		} catch (e) {
			return false;
		}
	}

	function initializeEvents() {
		// compare checked
		$cache.main.on('click', "input[type='checkbox'].compare-check", function(e) {
			var cb = $(this);
			var tile = cb.closest('.js-product_tile');
			var func = this.checked ? app.product.compare.addProduct : app.product.compare.removeProduct;
			var itemImg = tile.find('div.product-image a img').first();
			func({
				itemid: tile.data('itemid'),
				uuid: tile[0].id,
				img: itemImg,
				cb: cb,
			});
		});

		// handle load next items
		$cache.main.on('click', $cache.loadNextPageSel, function(e) {
			e.preventDefault();
			app.search.loadNextPage(this);

			var button = $(this);
			if($cache.buttonWithLoadingClass && button.hasClass($cache.buttonWithLoadingClass)) {
				app.progress.show(button);
			}

			return false;
		});

		// handle toggle refinement blocks
		$cache.main.on('click', '.js-refinement_title', function(e) {
			$(this)
				.toggleClass('expanded')
				.siblings('ul')
				.toggle();
		});

		// handle events for updating grid
		$cache.main.on('click', '.js-refinements a, .js-pagination-link a', function(e) {
			var $this = $(this),
				catparent = $this.parents('.js-category_refinement'),
				folderparent = $this.parents('.js-folder_refinement'),
				extParams = {};

			if (!$this.hasClass($cache.relaxRefineLinkClass)) {
				extParams.refineParent = $this.closest($cache.refinementSel);
			}

			if ($this.parent().hasClass('js-unselectable')) {
				return;
			}

			// if the anchor tag is uunderneath a div with the
			// class names & , prevent the double encoding of
			// the url
			// else handle the encoding for the url
			if (catparent.length > 0 || folderparent.length > 0) {
				return true;
			} else {
				e.preventDefault();
				var uri = app.util.getUri(this);

				if (uri.query.length > 1) {
					updateProductListing(this.href, false, extParams, $cache.loadrClassKey);
				} else {
					window.location.href = this.href;
				}
				return false;
			}
		});

		// handle clicking on search refinement filters
		$cache.main.on('click', $cache.refinementFilters, function() {
			if (this.dataset.href) {
				window.location.href = this.dataset.href;
			} else if (this.href) {
				window.location.href = this.href;
			}
		});

		// handle events item click. append params.
		$cache.main.on('click', '.js-product_tile a', function(e) {
			const $this = $(this);

			$cache.productWasClicked = true;
			$cache.document.trigger('product.tile.click', $this);

			if ($this.data('href') || this.href) {
				const origSearchParam = app.util.getQueryStringParams(this.search);

				if (!origSearchParam.cgid && app.util.getConfig('plp.addCgidParamToPdpUrls')) {
					const cgid = app.page.pageData && app.page.pageData.currentCategoryID;

					if (typeof cgid === 'string') {
						if ($this.data('href')) {
							var newLink = app.util.appendParamToURL($this.data('href'), 'cgid', cgid);
							$this.attr('data-href', newLink);
							if (!e.ctrlKey) {
								this.href = newLink;
							}
						} else {
							this.href = app.util.appendParamToURL(this.href, 'cgid', cgid);
						}
					}
				}
			}

			setPlpState($this);
			setSearchParamsToStorage($this);
		});

		if (app.preferences.anchorBackEnable && !app.preferences.enableInfiniteScroll) {
			$cache.window.load(function() {
				var historyState = (window.history && window.history.state) || {};

				if (historyState.backPosition) {
					if (historyState.backPosition.y > 0) {
						window.scrollTo(0, historyState.backPosition.y);
						$cache.scrollDown = historyState.backPosition.y - getWindowScrollTop() || 0;
					} else {
						$cache.scrollUp = historyState.backPosition.y;
					}
				}
			});
		}

		// handle sorting change
		$cache.main
			.on('change', '.sort-by select', function(e) {
				e.preventDefault();
				updateProductListing(
					$(this)
						.find('option:selected')
						.val(),
					false
				);
			})
			.on('change', '.items-per-page select', function(e) {
				var refineUrl = $(this)
					.find('option:selected')
					.val();
				if (refineUrl == 'INFINITE_SCROLL') {
					jQuery('html').addClass('infinite-scroll');
					jQuery('html').removeClass('disable-infinite-scroll');
				} else {
					jQuery('html').addClass('disable-infinite-scroll');
					jQuery('html').removeClass('infinite-scroll');
					var uri = app.util.getUri(refineUrl);
					window.location.hash = uri.query.substr(1);
				}
				return false;
			})
			.on('click', '.js-sort_select-link', function(e) {
				e.preventDefault();
				updateProductListing(this.href, false, null, $cache.loadrClassKey);
			})
			.on('click', '.js-clear_search_filters-link', function(e) {
				e.preventDefault();
				var filtersBlock = $(this).closest($cache.filtersBlockSel);
				var tabCls = filtersBlock.data('tab-class');
				var tabFilters = app.components.search.filters.getTabRefinements(tabCls);
				var url = tabCls ? window.location.href : (this.href || $(this).data('url'));
				var params = app.util.getUri(url).queryParams;
				var keys = Object.keys(params);
				for (var i = 0; i < keys.length; i++) {
					var key = keys[i];
					if (key.indexOf('prefn') !== -1 && tabFilters.indexOf(params[key]) !== -1) {
						url = app.util.removeParamsFromURL(url, [key, 'prefv' + key.slice(-1)]);
					} else if ($(this).data(key)) {
						url = app.util.removeParamFromURL(url, key);
					}
				}
				updateProductListing(url, false, { tabClass: tabCls }, $cache.loadrClassKey);
			});

		$cache.window.on('pageshow', function(event) {
			/* Safari <= 13.1 caches regardless of the cache-control header	*/
			if (event.originalEvent.persisted) {
				window.history.scrollRestoration = 'auto';
				$cache.isCached = true;
			}
		});

		$cache.window.on('popstate', function() {
			if ($cache.isCached || $cache.browser === 'safari' || window.location.href.indexOf('#') > -1) {
				return;
			}
			// Solution from http://stackoverflow.com/questions/6421769/popstate-on-pages-load-in-chrome
			var initialPop = !popped && location.href === app.search.startUrl;
			popped = true;
			if (initialPop) {
				return;
			}

			if (app.configs.filters.updateFromHistory) {
				updateProductListing(window.location.href, true);
			}
		});

		// handle hash change
		$(window).hashchange(function() {
			if (window.location.hash == '#' || (window.location.hash != '' && window.location.hash.slice(0, 2) != '#!')) {
				return;
			}

			updateProductListing(app.search.getRealUrl(window.location.href), true);
		});

		// Sticky left refinements
		if (app.preferences.stickyLeftNavigation) {
			var bottomOffset = parseInt($cache.main.css('marginBottom')) + parseInt($cache.main.css('paddingBottom'));
			bottomOffset += $cache.footer.outerHeight();
			app.util.fixElement($cache.stickyRefinementsClass);
		}
	} /** ***** app.search public object ******* */
	app.search = {
		init: function() {
			initializeCache();
			initGrid();

			window.history.scrollRestoration = 'manual';

			app.product.compare.init();

			$cache.window.load(function() {
				if (window.pageXOffset == null && app.clientcache.LISTING_INFINITE_SCROLL) {
					initInfiniteScroll_ie8();
				}
				if (window.pageXOffset != null && app.clientcache.LISTING_INFINITE_SCROLL) {
					initInfiniteScroll();
				}
			});
			initializeEvents();
			app.search.startUrl = window.location.href;
		},
		lockLoading: false,
		updateProductListing: updateProductListing,
		abortAjaxUpdatingPLP: abortAjaxUpdatingPLP,
		updateUrl: function(url) {
			if (url.indexOf('?') > -1 && url.indexOf('%') == -1) {
				url = url.replace('+', ' ');
				var _url = url.split('?');
				var _urlParams = [];
				$(String(_url[1]).split('&')).each(function() {
					var parts = this.split('=');
					if (parts.length > 0) {
						_urlParams.push(parts[0] + '=' + (parts.length == 2 ? encodeURI(parts[1]) : ''));
					}
				});
				url = _url[0];
				if (_url.length > 1 && _urlParams.length > 0) {
					url += '?' + _urlParams.join('&');
				}
			}
			if (this.pushAvailable) {
				history.pushState(null, null, url);
			} else {
				app.search.updateHash(url);
			}
		},

		pushAvailable: !!(window.history && history.pushState) && !window.navigator.webdriver,

		updateHash: function(url) {
			var hostAndPath = url.split('?')[0].split('#')[0];
			var query = url.replace(hostAndPath, '');
			var pathParts = hostAndPath.split('/');

			window.location.hash = '!' + pathParts[pathParts.length - 1] + query;
		},

		getRealUrl: function(url) {
			if (url.indexOf('#!') > -1) {
				var pathParts = url.split('/');
				pathParts[pathParts.length - 1] = url.split('#!')[1];
				url = pathParts.join('/');
				return url;
			}

			return url;
		},

		loadNextPage: function(el) {
			if (el) {
				var $this = $(el);
				var $loadNextCtrl = $this.closest($cache.loadNextCtrlSel);
				$this.addClass('js_hide next_page_load_progress');
				$loadNextCtrl.find($cache.loadingIconSel).removeClass($cache.hHidden);
				$loadNextCtrl.find($cache.progressBarSel).addClass($cache.hHidden);

				if ($this.parents($cache.subViewConteinerSel).length) {
					app.progress.show($this.parents($cache.subViewConteinerSel));
				} else {
					app.progress.show($cache.main.find($cache.searchResultContainer));
				}

				$.ajax({
					url: el.href,
					data: {
						format: 'page-element',
						position: $this.attr('data-position'),
						loaderbar: $this.attr('data-loaderbar'),
						subview: $this.attr('data-subview'),
						comparecgid: $this.attr('data-comparecgid'),
						subcategory: $this.attr('data-issubcategory'),
						viewtype: $this.attr('data-viewtype')
					}
				}).done(function(response) {
					if (response) {
						renderResponse(response);
						$cache.document.trigger('nextpage-loaded');
					}
				}).fail(function() {
					location.href = location.href;
				});

				function renderResponse(response) {
					var $response = $(response);
					app.wishlist.updateWishlistButtons($response);

					var pos = getWindowScrollTop();

					if ($loadNextCtrl.closest($cache.subViewConteinerSel).length) {
						var subCategoryContainer = app.preferences.isSubViewHitsAutoLoad ? $this.closest($cache.subViewConteinerSel) : $($cache.viewUnloadFirstSel),
							subViewLoadsEl = $($cache.subviewInfiniteLoadsSel);

						subCategoryContainer.append($response);
						subViewLoadsEl.data('infinite-loads', +subViewLoadsEl.data('infinite-loads') + 1);
						$('.js-subcategory-' + $this.data('subcategory')).removeClass($cache.hHidden);
						$this.closest($cache.loadNextCtrlSel).remove();

						var loadedHitsTextEl = subCategoryContainer.find($cache.loadedHitsTextSel);
						$cache.loadedHitsCount = $cache.loadedHitsCount + parseInt(loadedHitsTextEl.data('curpagesize'));
						if (subCategoryContainer.find($cache.loadNextPageSel + '[data-loading-state="unloaded"]').length == 0) {
							subCategoryContainer.removeClass('unloaded ').addClass('loaded');
							subCategoryContainer.find($cache.tileSel + ':last').addClass($cache.lastProductClass);
							var unloadedCntr = $this.closest($cache.subViewConteinerSel);
							if (unloadedCntr.length) {
								unloadedCntr.addClass($cache.showLoadBtnCls);
								subCategoryContainer.removeClass($cache.showLoadBtnCls);
							}
						}
						if (loadedHitsTextEl.length && $cache.loadedHitsText) {
							loadedHitsTextEl
								.add($($cache.viewUnloadFirstSel).find($cache.loadedHitsTextSel))
								.text($cache.loadedHitsText.replace('|loaded|', $cache.loadedHitsCount));
						}
					} else {
						$loadNextCtrl.replaceWith($response);
						$cache.document.trigger('infiniteScroll.content.appended', {
							container: $response
						});
						$cache.window.scrollTop(pos);
					}
					app.progress.hide(true);

					$cache.document.trigger('grid-preload-updated');
					$cache.document.trigger('grid-update', {
						container: $response
					});
					document.dispatchEvent(new CustomEvent('lazyload-reinit'));
				}
			}
			return false;
		},
	};
})((window.app = window.app || {}), jQuery);
