import ServiceWorkerUtils from 'oneapp/src/classes/service-worker-bridge/utils';

(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			doc: $(document),
			loginRememberMe: $('#dwfrm_login_rememberme'),
			rememberMe: $('#rememberme'),
			accountPass: $('#dwfrm_profile_edit_password'),
			accountPassConfirm: $('#dwfrm_profile_edit_passwordconfirm'),
			// login
			socialLogin: $('.js-social-login'),
			socialLoginSel: '.js-social-login',
			socialLoginProvider: '[name=dwfrm_oauthlogin_oauthprovider]',
			// confirmation && registration
			socialEditProviderSel: '[name=dwfrm_profile_edit_oauthprovider]',
			socialProviderUnlinkSel: '[name=dwfrm_profile_edit_oauthproviderunlink]',
			emailInputSel: '.js-registration-email input',
			socialNetworks: $('.js-social-networks'),
			socialLinkSel: '.js-social-link',
			socialUnlink: $('.js-social-unlink'),
			socialUnlinksSel: '.js-social-unlink:not(.h-hidden)',
			socialButtonLinkedClass: 'b-account-social-link',
			socialButtonUnlinkedClass: 'b-account-social-linked',
			socialUnlinkSuccessMessageClass: 'b-account-social-unlink_success',
			socialUnlinkErrorMessageClass: 'b-account-social-unlink_error',
			socialError: $('.js-social-error'),
			socialAccountError: $('.js-account-socail-error'),
			errorAsset: $('.b-content_asset--social-confirmation-email-error'),
			hiddenClass: 'h-hidden',
			fFieldSel: '.f-field',
			validationRequireClass: 'f-state-required',
			validationErrorClass: 'f-state-error',
			loginIcon: $('.js-login_dropdown-title'),
			loginCheckoutSel: '.js-checkout_login_container',
			loginFormSel: '.js-login_account_form',
			loginAjaxFormSel: '.js-login-ajax-form',
			forgotPasswordLinkSel: '.js-resetpassword_popup',
			validationRegisterPasswordClass: 'js-register_password',
			showPasswordButtonSel: '.js-show-password',
			accountWelcomePopup: $('.js-account-welcome-popup'),
			$registrationForm: $('.js-account_registration-form'),
			registrationAjaxForm: $('.js-registration-ajax-form'),
			registrationEmailField: $('.js-registration-email'),
			accountRegistration: $('.js-account-registration'),
			accountExistence: $('.js-account-existence'),
			accountExistenceAjaxForm: $('.js-account-existence-ajax-form'),
			accountExistenceEmailField: $('.js-account-existence-ajax-field'),
			loginCheckingEmailSel: '.js-login-checking-email',
			htmlBodySel: 'html, body',
			fieldValidationErrorSel: '.js-field-error',
			submitLoginButton: $('.js-login_account_button'),
			loginFormErrorSel: '.js-error_login-form',
			registrationFormError: $('.js-error_registration-form'),
			precheckoutPopup: $('.js-precheckout'),
			accountExistsClass: 'm-account-exists'
		};
	}

	function initializeEvents() {
		function socialLoginClick(el) {
			var settings = $(el).data('settings');

			if (settings.providerId.indexOf('Google', 0) > -1) {
				if (typeof gapi.auth2 === 'undefined') {
					console.error('gapi module auth2 is not included');

					return false;
				}
				if (settings.clientID) {
					gapi.auth2.authorize({
						client_id: settings.clientID,
						scope: 'email profile openid',
						prompt: 'consent',
						response_type: 'id_token permission'
					}, function(response) {
						if (response.error) {
							console.error('Google sign-in error: ' + response.error);
						} else {
							var url = app.util.appendParamsToUrl(app.urls.OAuthReentry, {
								providerId: settings.providerId,
								id_token: response.id_token
							});

							app.page.redirect(url);
						}
					});

					return false;
				}
			}

			return true;
		}

		$cache.socialNetworks.on('click', $cache.socialLinkSel, function() {
			if (!socialLoginClick(this)) {
				return false;
			}

			$(this.form)
				.find($cache.socialEditProviderSel)
				.val($(this).data('provider'));
			// cleaned classes added on unlink last action
			$cache.accountPass
				.removeClass($cache.validationRequireClass + ' ' + $cache.validationRegisterPasswordClass)
				.closest($cache.fFieldSel)
				.removeClass($cache.validationRequireClass + ' ' + $cache.validationErrorClass);
			$cache.accountPassConfirm
				.removeClass($cache.validationRequireClass)
				.closest($cache.fFieldSel)
				.removeClass($cache.validationRequireClass);
		});

		$cache.doc.on('focusout', $cache.emailInputSel, function() {
			if (
				!$(this)
					.closest($cache.fFieldSel)
					.hasClass($cache.validationErrorClass)
				&& $cache.errorAsset.is(':visible')
			) {
				$cache.errorAsset.addClass($cache.hiddenClass);
			}
		});

		$cache.doc.on('click', $cache.socialLoginSel, function() {
			if (!socialLoginClick(this)) {
				return false;
			}

			$(this.form)
				.find($cache.socialLoginProvider)
				.val($(this).val());
		});

		if ($cache.socialError.length) {
			var sourceForm = $cache.socialError.data('sourceform');

			if (sourceForm === 'loginflyout') {
				if (app.page.ns !== 'checkout') {
					$cache.doc.ready(function() {
						$cache.loginIcon.click();
					});
				}
			} else if (sourceForm === 'checkoutflyout') {
				$cache.doc.ready(function() {
					$($cache.loginCheckoutSel).removeClass($cache.hiddenClass);
				});
			}
		}

		if (app.page.ns === 'account' && $cache.socialAccountError.length) {
			var elem = $('.js-message-' + $cache.socialAccountError.data('provider'));

			elem && elem.removeClass($cache.hiddenClass).text(app.resources.SOCIAL_LOGIN_ACCOUNT_ERROR);
		}

		$cache.socialUnlink.on('click', function(e) {
			e.preventDefault();

			// check if this is last network unlink
			if ($($cache.socialUnlinksSel).length === 1) {
				// show message
				$(e.originalEvent.srcElement)
					.parent()
					.next()
					.removeClass($cache.hiddenClass)
					.text(app.resources.SOCIAL_LOGIN_UNLINK_LAST);
				// set pass fields as required
				$cache.accountPass
					.addClass($cache.validationRequireClass)
					.closest($cache.fFieldSel)
					.addClass($cache.validationRequireClass + ' ' + $cache.validationErrorClass);
				$cache.accountPassConfirm
					.addClass($cache.validationRequireClass)
					.closest($cache.fFieldSel)
					.addClass($cache.validationRequireClass);
				// put Provider ID to the profile form
				$($cache.socialProviderUnlinkSel).val($(this).data('provider'));
			} else {
				// unlink via AJAX call
				var provider = $(this).data('provider');

				$.ajax({
					url: app.urls.socialUnlink,
					type: 'POST',
					dataType: 'json',
					data: { oauthprovider: provider }
				}).done(function(data) {
					var $parent = $(e.originalEvent.srcElement).parent();
					var $message = $parent.next();

					$message.removeClass($cache.hiddenClass);

					if (data && data.success) {
						// change button
						$parent
							.find($cache.socialLinkSel)
							.val('link')
							.text(app.resources.SOCIAL_LOGIN_LINK)
							.removeClass($cache.socialButtonUnlinkedClass)
							.addClass($cache.socialButtonLinkedClass)
							.removeAttr('disabled');
						// hide unlink link
						$(e.originalEvent.srcElement).addClass($cache.hiddenClass);
						// show success message
						$message.addClass($cache.socialUnlinkSuccessMessageClass);
						$message.text(app.resources.SOCIAL_LOGIN_UNLINK_SUCCESS);
					} else {
						$message.addClass($cache.socialUnlinkErrorMessageClass);
						$message.text(app.resources.SOCIAL_LOGIN_ACCOUNT_ERROR);
					}
				});
			}
		});

		// toggle the value of the remember me check-box
		$cache.loginRememberMe.on('change', function() {
			$cache.rememberMe.val($cache.loginRememberMe.attr('checked') ? 'true' : 'false');
		});

		if (app.util.getConfig('forms.enableShowPasswordButton')) {
			$cache.doc.on('click', $cache.showPasswordButtonSel, function(e) {
				e.preventDefault();

				var targetFieldSel = $(this).data('target');
				var passwordInput = $(this).closest('form').find(targetFieldSel);

				if (passwordInput.prop('type') === 'password') {
					passwordInput.prop('type', 'text');
				} else if (passwordInput.prop('type') === 'text') {
					passwordInput.prop('type', 'password');
				}
			});
		}

		$cache.$registrationForm.on('submit', function() {
			if ($cache.$registrationForm.valid() && app.page.ns === 'account' && app.util.getConfig('showNotificationMessage')) {
				var text = $cache.$registrationForm.data('account-action') === 'edit' ? app.resources.MY_ACCOUNT_EDIT_PERSONAL_DATA_SUCCESS : app.resources.MY_ACCOUNT_REGISTRATION_SUCCESS;

				app.notificationsMgr.show('general', { text });
			}

			ServiceWorkerUtils.emptyCache();
		});

		$cache.doc.on('submit', $cache.loginFormSel, function() {
			$($cache.loginFormSel).validate();

			if ($($cache.loginFormSel).valid()) {
				this.submit();
				$cache.submitLoginButton.attr('disabled', 'disabled');
				ServiceWorkerUtils.emptyCache();
			}

			return false;
		});

		$cache.doc.on('click', '.js-logout_link', function() {
			ServiceWorkerUtils.emptyCache();
		});

		$cache.doc.on('submit', $cache.loginAjaxFormSel, function(e) {
			e.preventDefault();
			var $loginForm = $(e.target);
			var $loginFormErrorBlockSel = $loginForm.find($cache.loginFormErrorSel);

			$loginForm.validate();

			if ($loginForm.valid()) {
				var params = $loginForm.serializeArray();

				app.ajax.getJson({
					type: 'POST',
					url: $loginForm.attr('action'),
					data: params,
					callback: function(resp) {
						if (resp) {
							if (resp.success) {
								ServiceWorkerUtils.emptyCache();

								$loginForm.trigger('success');
							} else if (resp.redirectURL) {
								app.page.redirect(resp.redirectURL);
							}

							$loginFormErrorBlockSel.html(resp.error);
						}
					}
				});
			}

			return false;
		});

		$cache.doc.on('success', $cache.loginAjaxFormSel, function() {
			if (app.page.ns !== 'checkout') {
				window.location.reload();
			}
		});

		$cache.registrationAjaxForm.on('submit', function() {
			$cache.registrationAjaxForm.validate();

			if ($cache.registrationAjaxForm.valid()) {
				var params = $cache.registrationAjaxForm.serializeArray();

				app.ajax.getJson({
					type: 'POST',
					url: $cache.registrationAjaxForm.attr('action'),
					data: params,
					callback: function(resp) {
						if (resp) {
							if (resp.success) {
								$cache.registrationAjaxForm.trigger('success');
							} else if (resp.redirectURL) {
								app.page.redirect(resp.redirectURL);
							}

							if (resp.accountExists) {
								$cache.precheckoutPopup.addClass($cache.accountExistsClass);
							}

							$cache.registrationFormError.html(resp.error);
						}
					}
				});
			}

			return false;
		});

		$cache.accountExistenceAjaxForm.on('submit', function() {
			$cache.accountExistenceAjaxForm.validate();

			if ($cache.accountExistenceAjaxForm.valid()) {
				app.util.checkUserExist($cache.accountExistenceEmailField, 'login', callbackForAccountExistenceAjaxForm);
			}

			return false;
		});
	}

	function callbackForAccountExistenceAjaxForm(resp) {
		var enteredEmailValue = $cache.registrationEmailField.val();

		if (resp) {
			if (resp.result === false) {
				$cache.accountExistence.remove();
				$cache.accountRegistration.removeClass('h-hidden');

				$cache.registrationEmailField.val(enteredEmailValue);
			}

			if (resp.result === true) {
				fillExistingEmailBlock(enteredEmailValue);
				$cache.precheckoutPopup.addClass($cache.accountExistsClass);
			}

			$cache.formError.html(resp.error);
		}
	}

	function fillExistingEmailBlock(enteredEmail) {
		if ($($cache.loginAjaxFormSel).length) {
			$($cache.loginCheckingEmailSel).text(enteredEmail);
			$($cache.loginAjaxFormSel).get(0).reset();
			$($cache.loginAjaxFormSel).find('.f-email').val(enteredEmail);

			resetFormValidation($($cache.loginAjaxFormSel));

			return true;
		}

		return false;
	}

	function resetFormValidation($form) {
		var $fieldsWithError = $form.find('.f-state-error');

		$form.validate().resetForm();
		$fieldsWithError.removeClass('f-state-error');
		$fieldsWithError.find('input').attr('aria-invalid', false);
	}

	function initializeDom() {
		var fancyboxContent = $cache.accountWelcomePopup;

		if (fancyboxContent.length) {
			fancyboxContent.removeClass($cache.hiddenClass);
			app.fancybox.open($(fancyboxContent));
		}

		var $element = $cache.$registrationForm.find($cache.fieldValidationErrorSel);

		if ($element.length) {
			$($cache.htmlBodySel).animate({
				scrollTop: $($element[0]).offset().top - 100
			}, 500);
		}
	}

	/**
	 * @namespace app.components.account.login public object
	 * */
	app.components = app.components || {};
	app.components.account = app.components.account || {};
	app.components.account.login = {
		init: function(params) {
			initializeCache(params);
			initializeDom(params);
			initializeEvents(params);
		}
	};
})((window.app = window.app || {}), jQuery);
