(function(app, $, dwObject){

	// If the Apple Pay payment processor exists and it is not SAFECHARGE, skip the initialization of the script.
	if (
		app.paymentInfo.applePayMerchantProvider
		&& app.paymentInfo.applePayMerchantProvider !== 'SAFECHARGE'
	) {
		return;
	}

	var $cache = {};
	var $mainConfig = {
		paymentScript: 'sfc',
		requiredData: 'merchantSiteId',
		data: ['merchantSiteId', 'env']
	};
	var config;
	var request = {};
	var updatedRequest;
	var session;
	var redirect;
	var apiVersion;
	var errors = [];
	var paymentScript;
	var requiredRequestField;
	var administrativeValue;
	var billingAdministrativeValue;
	var validationWrapperCls;
	var validationFormCls;
	var shippingCountryIncorrect;
	var storefrontShippingCountry;
	var sectionData;
	
	/** Mappings **/
	var STATUSES = {
		Success: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_SUCCESS,
		Failure: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_FAILURE,
		InvalidBillingPostalAddress: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS,
		InvalidShippingPostalAddress: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
		InvalidShippingContact: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_INVALID_SHIPPING_CONTACT,
		PINRequired: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_PIN_REQUIRED,
		PINIncorrect: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_PIN_INCORRECT,
		PINLockout: typeof ApplePaySession !== 'undefined' && ApplePaySession.STATUS_PIN_LOCKOUT
	};
	
	var fieldMapping;
	
	function initializeConfigs(){
		app.preferences.applePayStateValidation = 'IT,US,CA';

		fieldMapping = {
			'merchantSiteId': (app.preferences.applePayPublicConfigurations && app.preferences.applePayPublicConfigurations.merchantSiteId) || null,
			'env': (app.preferences.applePayPublicConfigurations && app.preferences.applePayPublicConfigurations.env) || null
		};
	}
	
	function initializeCache(){
		$cache = {
			document: $(document),
			applepayGeneralButton: 'dw-apple-pay-button',
			applePayCheckoutButton: 'dw-apple-pay-cart',
			applePayMinicartButton: 'dw-apple-pay-mini-cart',
			applePayPDPButton: 'dw-apple-pay-pdp',
			applePayQuickviewButton: 'dw-apple-pay-quickview',
			applePaySummaryButton: 'dw-applepay-ordersummary-button',
			applePayButtonWrapper: '.js-applepay-button',
			checkoutAlter: '.b-checkout_button-alter',
			payPalButtonWrapper: '.b-express_paypal-btn',
			amazonButtonWrapper: '.b-amazon_button',
			noSizeCls: 'js-no-size',
			errorVariations: '.js-error_variations',
			productWrapper: '.js-product_content',
			validationWrapper: '.js-applepay-validation-form-wrapper',
			validationForm: '.js-applepay-validation-form',
			privacyBlock: '.js-applepay-policy-wrapper',
			outerPrivacyBlock: 'outer',
			privacyForm: '.js-applepay-policy-form',
			privacySummaryBlock: '.js-applepay-ordersummary-wrapper',
			shippingWrapper: '.js-single_shipping_wrap',
			billingWrapper: '.js-billing_address_block',
			alterSel: '.js-alter',
			hHidden: 'h-hidden',
			pdpMainContainer: '.js-pdp_main',
			applepaySelectVariantButton: 'js-applepay-select-variant'
		};
	}
	
	function initializeDOM(){
		ready(function(){
			initializeRequest();
			beginAPProcess();
		});
	}
	
	function initializeEvents(){
		//jquery storefront events
		$cache.document.on('product.variation.reloaded quickview.opened shopthelook.opened', function(e, refreshData){
			if(e.type === 'product'){
				refreshPolicyProductData(refreshData.pid, refreshData.masterPid);
			}
			
			initializeRequest();
			beginAPProcess();
		});
		
		if(app.page.ns !== 'checkout'){
			$cache.document.on('product.added minicart.product.removed minicart.afterload fancy.mobile.added', function() {
				initializeRequest();
				beginAPProcess();
			});
		}
		else{
			$cache.document.on('cart.updateModels cart.shippingCountryChange', function() {
				initializeRequest();
				beginAPProcess();
			});
		}
		
		//ApplePay event
		document.addEventListener('click', applePayHandler, false);
		document.addEventListener('applePayData', function(e){
			var data = e.detail;
			if(data.administrativeValue){
				administrativeValue = data.administrativeValue;
			}
			else if(data.billingAdministrativeValue){
				billingAdministrativeValue = data.billingAdministrativeValue;
			}
			
			if(data.errorMessages && data.errorMessages.length){
				shippingCountryIncorrect = data;
			}
			else{
				shippingCountryIncorrect = null;
			}
			
			storefrontShippingCountry = data.storefrontShippingCountry;
		}, false);
	}
	
	function initializeRequest(){
		paymentScript = window[$mainConfig.paymentScript];
		requiredRequestField = $mainConfig.requiredData;

		for (var i = 0; i < $mainConfig.data.length; i++) {
			var requestField = $mainConfig.data[i];

			if (fieldMapping[requestField]) {
				request[requestField] = fieldMapping[requestField];
			}
		}
	}
	
	function beginAPProcess(){
		if(paymentScript && (!requiredRequestField || request[requiredRequestField]) && 
				app.util.isApplePaySupported()) {
			apiVersion = defineApiVersion();
			getRequest();
			showApplePayButton();
			showAlterSignatures();
		}
	}
	
	function applePayHandler(evt){
		var target = evt.target;
		if(target.classList.contains($cache.applepayGeneralButton)){
			evt.preventDefault();
			if (target.classList.contains($cache.applepaySelectVariantButton)) {
				$(target).addClass('js-applepay-start-selected-variant');
				app.flyoutMgr.open('size', {
					appendToElement: $cache.pdpMainContainer
				});
				return;
			}
			var sku = target.dataset.sku;
			var errorWrapper = $(evt.target).closest($cache.productWrapper);
			var errorVariationsBlock = errorWrapper.find($cache.errorVariations);

			if (sku && errorVariationsBlock.length) {
				$cache.document.trigger('product.invalid', [errorVariationsBlock]);
				errorVariationsBlock.show();
			} else {
				// checking privacy policy
				var privacyBlocks = $(determinePrivacyBlock(target));
				var isValid = true;
				var isHidden = privacyBlocks.hasClass($cache.hHidden);

				revealPrivacyBlocks(privacyBlocks);
				alignPrivacyPolicy();

				if (!isHidden) {
					$.each(privacyBlocks, function(){
						var privacyBlock = $(this),
							privacyForm = privacyBlock.children($cache.privacyForm);
						
						if(privacyForm.length){
							privacyForm.validate();
							var result = privacyForm.valid();
							if(isValid){
								isValid = result;
							}
						}
					});

					if (isValid) {
						determineValidationForm(target);
						determineSectionData(target);
						initializeRequest();
						prepareBasket(sku);
						createSession();
					}
				}
			}
		}
	}
	
	function alignPrivacyPolicy(){
		var items = [$cache.applePayButtonWrapper, $cache.checkoutAlter, $cache.payPalButtonWrapper, $cache.amazonButtonWrapper],
			outerCheckoutPolicy = $($cache.privacyBlock + '-' + 'checkout' + '.' + $cache.outerPrivacyBlock);
		$.each(outerCheckoutPolicy, function(){
			var checkoutSignature = $(this);
			if(checkoutSignature.is(':visible')){
				var outerSum = 0;
				for(var i = 0, len = items.length; i<len; i++){
					var sibling = checkoutSignature.siblings(items[i]);
					outerSum += sibling.length ? sibling.outerWidth(true) : 0;
				}
				checkoutSignature.width(outerSum);
			}
		});
	}
	
	function refreshPolicyProductData(productPid, masterPid){
		if(!masterPid){
			return;
		}
		
		var policyWrappers = document.querySelectorAll($cache.privacyBlock + '[data-master-id="' + masterPid + '"]');
		policyWrappers.forEach(function(policyWrapper){
			policyWrapper.dataset.productId = productPid;
			policyWrapper.classList.add($cache.hHidden);
		});
	}
	
	function revealPrivacyBlocks(privacyBlocks){
		var firstOpen = false;
		
		if(privacyBlocks){
			$.each(privacyBlocks, function(){
				if(!firstOpen){
					firstOpen = this.classList.contains($cache.hHidden);
				}
				this.classList.remove($cache.hHidden);
			});
		}
		
		$cache.document.trigger('appleypay.privacy.revealed', [privacyBlocks, firstOpen]);
	}
	
	/*
	 * Update errors with new ApplePayError objects
	 * @input data : object messages and field info of errors
	 * @input cleanUp : boolean clean up previous erros (use for events)
	 */
	
	function updateErrors(data, cleanUp){
		if(data){
			//clean up previous errors
			if(cleanUp){
				for(var i = errors.length - 1; i>=0; i--){
					if(errors[i].code === data.errorSection){
						errors.splice(i, 1);
					}
				}
			}
			
			if(data.errorMessages && data.errorMessages.length){
				if (data.isBannedZipCode) {
					if (sectionData.section === 'checkout' || sectionData.section === 'minicart') {
						redirect = app.util.appendParamsToUrl(app.urls.cartShow, { fromPayment: 'DW_APPLE_PAY' });
						window.location = redirect;
					}
				}

				mergeErrors(data);
				for(var i = 0; i < data.errorMessages.length; i++){
					errors.push(new ApplePayError(data.errorSection, data.errorFields[i], data.errorMessages[i]));
				}
			}
		}
	}
	
	function mergeErrors(data){
		if(data){
			for(var i = data.errorMessages.length - 1; i>=0; i--){
				for(var j = 0; j<i; j++){
					if(data.errorFields[i] === data.errorFields[j]){
						if(data.errorMessages[j].indexOf(data.errorMessages[i]) === -1){
							data.errorMessages[j] += " " + data.errorMessages[i];
						}
						data.errorFields.splice(i, 1);
						data.errorMessages.splice(i, 1);
						break;
					}
				}
			}
		}
	}
	
	function populateIntoStorefrontFields(searchInside, storefrontMapping, data, regionValue){
		var element;
		for(var field in data){
			if(field in storefrontMapping){
				if(Array.isArray(data[field])){
					var selectors = storefrontMapping[field].split(',');
					for(var i = 0, len = i<data[field].length; i<len; i++){
						element = searchInside.find(selectors[i]);
						writeIntoField(element, data[field][i]);
					}
				}
				else{
					element = searchInside.find(storefrontMapping[field]);
					if(field === 'administrativeArea'){
						writeIntoField(element, regionValue, data[field]);
					}
					else if(element.prop('nodeName') === "SELECT" && field === "country"){
						writeIntoField(element, data["countryCode"], data[field]);
					}
					else{
						writeIntoField(element, data[field]);
					}
				}
			}
		}
	}
	
	function validateIntoStorefrontFields(searchInside, widgetMapping, errorSection){
		var data = {
			errorSection: errorSection,
			errorMessages: [],
			errorFields: []
		};
		
		for(var selector in widgetMapping){
			var element = searchInside.find(selector),
				errorMessage = getStorefrontErrorMessage(element);
			if(errorMessage){
				data.errorFields.push(widgetMapping[selector]);
				data.errorMessages.push(errorMessage);
			}
		}
		
		if(data.errorMessages){
			updateErrors(data);
		}
	}
	
	function fillValidationForm(formCls, applePayData){
		var $form = $(formCls).first();
		if($form && $form.length){
			var billingData = applePayData.billingContact,
				shippingData = applePayData.shippingContact,
				searchInside,
				storefrontMapping;
			
			if(shippingData){
				searchInside = $($cache.shippingWrapper).first();
				storefrontMapping = {
					"emailAddress": ".f-type-emailaddress input", "givenName": ".f-type-firstname input",
					"familyName": ".f-type-lastname input", "addressLines": ".address1,.f-type-address2 input",
					"postalCode": ".f-type-zip .zip", "locality": ".f-type-city input",
					"country": ".js-country-change-click span", "countryCode": ".f-type-country .country",
					"administrativeArea": ".f-type-state select", "phoneNumber": ".f-type-phonesimple input"
				};

				populateIntoStorefrontFields(searchInside, storefrontMapping, shippingData, administrativeValue);
			}
			
			if(billingData){
				searchInside = $($cache.billingWrapper).first();
				storefrontMapping = {
					"givenName": ".f-type-firstname input",
					"familyName": ".f-type-lastname input", "addressLines": ".address1,.f-type-address2 input",
					"postalCode": ".f-type-zip .zip", "locality": ".f-type-city input",
					"country": ".f-type-country select", "administrativeArea": ".f-type-state select"
				};
				
				populateIntoStorefrontFields(searchInside, storefrontMapping, billingData, billingAdministrativeValue);
			}
			
			validateApplePayData($form, applePayData);
		}
	}
	
	function validateState(data, errorSection) {
		var countryList = app.customObjects.countriesAndStates,
			isValid = true,
			country = data.countryCode.toUpperCase(),
			countyCode,
			errorMessage;

		if (errorSection === 'billingContactInvalid') {
			countyCode = billingAdministrativeValue;
			errorMessage = app.resources.AP_REGION_BILLING_INVALID;
		} else if (errorSection === 'shippingContactInvalid') {
			countyCode = data.administrativeArea;
			errorMessage = app.resources.AP_REGION_SHIPPING_INVALID;
		} else {
			return;
		}

		if(country in countryList && countryList[country].regions
				&&	Object.keys(countryList[country].regions).length && !(countyCode in countryList[country].regions)){
			isValid = false;
		}

		if(!isValid){
			updateErrors({
				errorSection: errorSection,
				errorFields: ['administrativeArea'],
				errorMessages: [errorMessage]
			});
		}
	}
	
	function validateApplePayData($form, applePayData){
		if($form){
			var validator = $form.validate();
			validator.settings.ignore = '';
			var isValid = $form.valid(),
				errorSection,
				widgetMapping,
				searchInside;
			
			//for billing update data
			updateErrors({
				errorSection: "billingContactInvalid"
			}, true);
			
			//for shipping update data
			updateErrors({
				errorSection: "shippingContactInvalid"
			}, true);
			
			if(shippingCountryIncorrect){
				updateErrors(shippingCountryIncorrect);
			}
			
			//validate state for billing
			if(applePayData.billingContact){
				validateState(applePayData.billingContact, "billingContactInvalid");
			}
			
			//validate state for shipping
			if(applePayData.shippingContact){
				validateState(applePayData.shippingContact, "shippingContactInvalid");
			}
			
			if(!isValid){
				searchInside = $($cache.shippingWrapper).first();
				if(searchInside.length){
					errorSection = "shippingContactInvalid";
					widgetMapping = {
						".f-type-emailaddress input": "emailAddress", ".f-type-firstname input": "name",
						".f-type-lastname input": "name", ".address1": "addressLines", ".f-type-address2 input": "addressLines",
						".f-type-zip .zip": "postalAddress", ".f-type-city input": "locality",
						".f-type-country .country": "country", ".f-type-state select": "administrativeArea",
						".f-type-phonesimple input": "phoneNumber"
					};
					
					validateIntoStorefrontFields(searchInside, widgetMapping, errorSection);
				}
				
				searchInside = $($cache.billingWrapper).first();
				if(searchInside.length){
					errorSection = "billingContactInvalid";
					widgetMapping = {
						".f-type-firstname input": "name",
						".f-type-lastname input": "name", ".address1": "addressLines", ".f-type-address2 input": "addressLines",
						".f-type-zip .zip": "postalAddress", ".f-type-city input": "locality",
						".f-type-country select": "country", ".f-type-state select": "administrativeArea"
					};
					
					validateIntoStorefrontFields(searchInside, widgetMapping, errorSection);
				}
			}
			
			validator.resetForm();
			$form.find('.f-error_text').remove();
		}
	}
	
	function writeIntoField(field, value, displayValue){
		if(field && field.length){
			switch(field.prop('nodeName')){
				case "SELECT":
					field.append($("<option></option>").attr({"value": value, "selected": "selected"}).text(displayValue));
					break;
				case "INPUT":
					field.attr('value', value);
					break;
			}
		}
	}
	
	function getStorefrontErrorMessage(field){
		if(field && field.length){
			var message = '',
				messageBlock,
				textBlock;
			
			switch(field.prop('nodeName')){
				case "SELECT":
					messageBlock = field.closest(".f-select-wrapper").siblings(".f-error_message").find(".f-error_message-block");
					break;
				case "INPUT":
					messageBlock = field.siblings(".f-error_message").find(".f-error_message-block");
					break;
			}
			
			textBlock = messageBlock.children('.f-error_text');
			if(textBlock.length){
				message = textBlock.text();
			}
			
			return message;
		}
	}
	
	function replaceBillingArea(county, country){
		var countryList = app.customObjects.countriesAndStates,
			regions,
			stateCode = county;
		if(country in countryList){
			regions = countryList[country].regions;
			
			//find state code if name provided
			if (county in regions) {
				for(var currentStateCode in regions){
					if(regions[currentStateCode] === county){
						stateCode = currentStateCode;
						break;
					}
				}
			} else {
				stateCode = null;
			}
		}
		
		billingAdministrativeValue = stateCode;
	}
	
	function determineValidationForm(applePayButton){
		if(applePayButton.classList.contains($cache.applePayPDPButton)){
			validationWrapperCls = $cache.validationWrapper + '-pdp-' + applePayButton.dataset.sku;
			validationFormCls = $cache.validationForm + '-pdp-' + applePayButton.dataset.sku;
		}
		else if(applePayButton.classList.contains($cache.applePayQuickviewButton)){
			validationWrapperCls = $cache.validationWrapper + '-quickview-' + applePayButton.dataset.sku;
			validationFormCls = $cache.validationForm + '-quickview-' + applePayButton.dataset.sku;
		}
		else if(applePayButton.classList.contains($cache.applePayCheckoutButton)){
			validationWrapperCls = $cache.validationWrapper + '-checkout';
			validationFormCls = $cache.validationForm + '-checkout';
		}
		else if(applePayButton.classList.contains($cache.applePayMinicartButton)){
			validationWrapperCls = $cache.validationWrapper + '-minicart';
			validationFormCls = $cache.validationForm + '-minicart';
		}
	}
	
	function determinePrivacyBlock(applePayButton){
		if(applePayButton.classList.contains($cache.applePayPDPButton)){
			if(applePayButton.classList.contains($cache.outerPrivacyBlock)){
				return $cache.privacyBlock + '-pdp[data-product-id="' + applePayButton.dataset.sku + '"]';
			}
			return $cache.privacyBlock + '-pdp-' + applePayButton.dataset.sku;
		}
		else if(applePayButton.classList.contains($cache.applePayQuickviewButton)){
			if(applePayButton.classList.contains($cache.outerPrivacyBlock)){
				return $cache.privacyBlock + '-quickview[data-product-id="' + applePayButton.dataset.sku + '"]';
			}
			return $cache.privacyBlock + '-quickview-' + applePayButton.dataset.sku;
		}
		else if(applePayButton.classList.contains($cache.applePayMinicartButton)){
			return $cache.privacyBlock + '-minicart';
		}
		else if(applePayButton.classList.contains($cache.applePayCheckoutButton)){
			return $cache.privacyBlock + '-checkout';
		}
	}
	
	function determineSectionData(applePayButton){
		sectionData = {};
		sectionData['isOrderSummary'] = false;
		sectionData['sku'] = false;
		if(applePayButton.classList.contains($cache.applePayPDPButton)){
			sectionData['section'] = 'pdp';
			sectionData['sku'] = applePayButton.dataset.sku;
		}
		else if(applePayButton.classList.contains($cache.applePayQuickviewButton)){
			sectionData['section'] = 'quickview';
			sectionData['sku'] = applePayButton.dataset.sku;
		}
		else if(applePayButton.classList.contains($cache.applePayMinicartButton)){
			sectionData['section'] = 'minicart';
		}
		else if(applePayButton.classList.contains($cache.applePayCheckoutButton)){
			if(applePayButton.classList.contains($cache.applePaySummaryButton)){
				sectionData['isOrderSummary'] = true;
			}
			sectionData['section'] = 'checkout';
		}
	}
	
	function oncancelHandler(){
		setSession(null);
		nullApplePayData();
		
		postJson(config.action.cancel, {})
			.then(function(response){
				finishServerProcessor(response);
			}, function(error){
				finishServerProcessor(error.response);
				doRedirect();
			})
			.catch(function(err){
				console.error(err);
			});
	}
	
	function onpaymentmethodselectedHandler(e){
		postJson(config.action.onpaymentmethodselected, filterEvent(e))
			.then(function(response){
				updatedRequest = Object.assign(updatedRequest, response);
				if(apiVersion > 2){
					session.completePaymentMethodSelection(shapeForThirdAPI({type: 'onpaymentmethodselected', data: response}));
				}
				else{
					session.completePaymentMethodSelection(response.total, response.lineItems);
				}
				finishServerProcessor(response);
			}, function(error){
				if(apiVersion > 2){
					session.completePaymentMethodSelection(shapeForThirdAPI({type: 'onpaymentmethodselected', data: updatedRequest}));
				}
				else{
					session.completePaymentMethodSelection(updatedRequest.total, updatedRequest.lineItems);
				}
				finishServerProcessor(error.response);
			})
			.catch(function(err){
				nullApplePayData();
				console.error(err);
			});
	}
	
	function onshippingmethodselectedHandler(e){
		postJson(config.action.onshippingmethodselected, filterEvent(e))
			.then(function(response){
				updatedRequest = Object.assign(updatedRequest, response);
				if(apiVersion > 2){
					session.completeShippingMethodSelection(shapeForThirdAPI({type: 'onshippingmethodselected', data: response}));
				}
				else{
					session.completeShippingMethodSelection(STATUSES['Success'], response.total, response.lineItems);
				}
				finishServerProcessor(response);
			}, function(error){
				if(apiVersion > 2){
					session.completeShippingMethodSelection(shapeForThirdAPI({type: 'onshippingmethodselected', data: updatedRequest}));
				}
				else{
					session.completeShippingMethodSelection(mapStatus(error.message), updatedRequest.total, updatedRequest.lineItems);
				}
				finishServerProcessor(error.response);
			}).catch(function(err){
				nullApplePayData();
				console.error(err);
			});
	}
	
	function onshippingcontactselectedHandler(e){
		postJson(config.action.onshippingcontactselected, filterEvent(e))
			.then(function(response){
				updatedRequest = Object.assign(updatedRequest, response);
				if(apiVersion > 2){
					session.completeShippingContactSelection(shapeForThirdAPI({type: 'onshippingcontactselected', data: response}));
				}
				else{
					session.completeShippingContactSelection(STATUSES['Success'], response.shippingMethods, response.total, response.lineItems);
				}
				finishServerProcessor(response);
			}, function(error){
				if(apiVersion > 2){
					session.completeShippingContactSelection(shapeForThirdAPI({type: 'onshippingcontactselected', data: updatedRequest}));
				}
				else{
					session.completeShippingContactSelection(mapStatus[error.message], [], updatedRequest.total, updatedRequest.lineItems);
				}
				finishServerProcessor(error.response);
			})
			.catch(function(err){
				nullApplePayData();
				console.error(err);
			});
	}
	
	function showApplePayButton(){
		var APWrappers = document.querySelectorAll($cache.applePayButtonWrapper);
		APWrappers.forEach(function(APWrapper){
			if(APWrapper.dataset.hidden === "false"){
				APWrapper.classList.remove($cache.hHidden);
				if(app.page.ns === 'checkout'){
					APWrapper.dataset.hidden = "true";
				}
			}
		});
	}
	
	function showAlterSignatures(){
		var alters = document.querySelectorAll($cache.alterSel);
		alters.forEach(function(alter){
			if(alter.dataset.applepayAlter === "true"){
				alter.classList.remove($cache.hHidden);
			}
		});
	}
	
	function getRequest(){
		getJson(config.action.getRequest)
			.then(function(response){
				var initialRequest = request || {};
				request = Object.assign(initialRequest, response.request);
				finishServerProcessor(response);
			})
			.catch(function(err){
				console.error(err);
			});
	}
	
	function prepareBasket(sku){
		postJson(config.action.prepareBasket, {
			sku: sku
		})
			.then(function(response){
				finishServerProcessor(response);
			}, function(error){
				try {
					session.abort();
				}
				catch(e){
					console.error(e);
				}
				finishServerProcessor(error.response);
				doRedirect();
			})
			.catch(function(err){
				console.error(err);
			});
	}

	function updateBillinigAndShippingState(payResult) {
		// check if state is mandatory attribute for country
		if (app.preferences.applePayStateValidation.indexOf(payResult.billingContact.countryCode) === -1) {
			payResult.billingContact.administrativeArea = '';
		}
		if (app.preferences.applePayStateValidation.indexOf(payResult.shippingContact.countryCode) === -1) {
			payResult.shippingContact.administrativeArea = '';
		}

		payResult.shippingContact.administrativeArea = administrativeValue;
	}

	function createSession() {
		if (parseFloat(request.total.amount) === 0) {
			request.total.amount = '0.01';
		}
		
		var currentSession = paymentScript.applePay.buildSession(request, function(payResult, completion){
			if(payResult.billingContact){
				replaceBillingArea(payResult.billingContact.administrativeArea, payResult.billingContact.countryCode);
			}
			updateBillinigAndShippingState(payResult);
			
			//check if final step should be reloaded
			if(payResult.billingContact.countryCode !== storefrontShippingCountry 
					|| payResult.shippingContact.countryCode !== storefrontShippingCountry){
				var url = app.util.appendParamsToUrl(app.urls.applePayValidationForm, {
					'validationFormCls': validationFormCls.substring(1),
					'shippingCountry': payResult.shippingContact.countryCode,
					'billingCountry': payResult.billingContact.countryCode,
					'section': sectionData.section,
					'sku': sectionData.sku,
					'isOrderSummary': sectionData.isOrderSummary
				});
				getHTML(url)
					.then(function(data){
						var putInside = $(validationWrapperCls);
						if(data && putInside.length){
							putInside.first().html(data);
						}
						finalStep(completion, payResult);
					});
			}
			else{
				finalStep(completion, payResult);
			}
		});

		setSession(currentSession);
		session.begin();
		updatedRequest = Object.assign({}, request);
	}
	
	function finalStep(completion, payResult){
		fillValidationForm(validationFormCls, payResult);
		var eventObj = mockPaymentEvent(payResult);
		if(errors.length){
			completion(false, errors);
		}
		else{
			postJson(config.action.onpaymentauthorized, eventObj)
				.then(function(response){
					completion(true);
					finishServerProcessor(response);
					setSession(null);
					submitOrder(response.redirect);
				}, function(error){
					completion(false);
					finishServerProcessor(error.response);
					doRedirect();
				})
				.catch(function(err){
					console.error(err);
				});
		}
	}
	
	function submitOrder(redirect){
		var form = document.createElement('form');
		form.action = redirect;
		form.method = 'post';
		
		document.body.appendChild(form);
		form.submit();
	}
	
	function setSession(currentSession){
		if(session){
			session.oncancel = null;
			session.onpaymentmethodselected = null;
			session.onshippingmethodselected = null;
			session.onshippingcontactselected = null;
		}
		
		session = currentSession;
		
		if(session){
			session.oncancel = oncancelHandler;
			session.onpaymentmethodselected = onpaymentmethodselectedHandler;
			session.onshippingmethodselected = onshippingmethodselectedHandler;
			session.onshippingcontactselected = onshippingcontactselectedHandler;
		}
	}
	
	function responseHandler(response){
		return typeof response === 'object' ? response.json()
			.then(function(json){
				if(response.status >= 200 && response.status < 300){
					return json;
				}
						
				//Throw error in other case
				var err = new Error(json ? json.status : 'Request error');
				err.response = json;
				throw err;
			})
			: Promise.resolve(response);
	}
	
	function finishServerProcessor(response){
		if(!response){
			return;
		}
		
		dispatchEvent(response.event);
		
		if(typeof response.redirect !== 'undefined'){
			redirect = response.redirect;
		}
	}
	
	/*
	 * Make get request to get html
	 * @input url:string
	 * @return Promise
	 */
	
	function getHTML(url){
		return fetch(url, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Accept': 'text/html'
			}
		}).then(function(res){
			return res.text();
		});
	}
	
	/*
	 * Make get request
	 * @input url:string
	 * @return Promise
	 */
	
	function getJson(url){
		return fetch(url, {
			credentials: 'include',
			method: 'GET',
			headers: {
				'Accept': 'application/json'
			}
		}).then(responseHandler);
	}
	
	/*
	 * Make post request
	 * @input url : string
	 * @input data : object
	 * @return Promise
	 */
	
	function postJson(url, data){
		var json = data;
		
		if(typeof data === 'object'){
			json = JSON.stringify(data);
		}
		else if(typeof data !== 'string'){
			throw new Error('Body data should be an object or a JSON string');
		}
		
		return fetch(url, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: json
		}).then(responseHandler);
	}
	
	function doRedirect(){
		if(redirect && window.location.href !== redirect){
			window.location = redirect;
		}
	}
	
	function dispatchEvent(event){
		if(!event || !event.name){
			return;
		}
		
		document.body.dispatchEvent(new CustomEvent(event.name, {
			bubbles: true,
			detail: event.detail
		}));
	}
	
	function filterEvent(e){
		var filteredEvent = {};
		for(var prop in e){
			if(!Event.prototype.hasOwnProperty(prop)){
				filteredEvent[prop] = e[prop];
			}
		}
		
		return filteredEvent;
	}
	
	function mapStatus(status){
		if(status && STATUSES[status]){
			return STATUSES[status];
		}
		
		return ApplePaySession && ApplePaySession.STATUS_FAILURE;
	}
	
	/*
	 * Define what API version current device supports
	 */
	
	function defineApiVersion(){
		var apiVersion = 1;
		if (ApplePaySession.supportsVersion(3)) {
			apiVersion = 3;
		} else if (ApplePaySession.supportsVersion(2)) {
			apiVersion = 2;
		} else if (ApplePaySession.supportsVersion(4)) {
			apiVersion = 4;
		}
		
		return apiVersion;
	}
	
	/*
	 * Use Update 3rd ApplePay API object structure
	 */
	
	function shapeForThirdAPI(action){
		var updatedResponse = {};
		switch(action.type){
			case 'onshippingcontactselected':
				updatedResponse['newTotal'] = action.data.total || {};
				updatedResponse['newLineItems'] = action.data.lineItems || [];
				updatedResponse['newShippingMethods'] = action.data.shippingMethods || [];
				break;
			case 'onshippingmethodselected':
			case 'onpaymentmethodselected':
				updatedResponse['newTotal'] = action.data.total || {};
				updatedResponse['newLineItems'] = action.data.lineItems || [];
				break;
		}
		
		return updatedResponse;
	}
	
	/*
	 * Emulate payment event data structure
	 */
	
	function mockPaymentEvent(responseObject){
		var event = {
			payment: {}
		};
		
		if(responseObject.token){
			event.payment.token = responseObject.token;
		}
		
		if(responseObject.shippingContact){
			event.payment.shippingContact = responseObject.shippingContact;
		}
		
		if(responseObject.billingContact){
			event.payment.billingContact = responseObject.billingContact;
		}
		
		return event;
	}
	
	/*
	 * Method for starting work when all code is rendered
	 */
	
	function ready(fn) {
		document.onreadystatechange = function() {
			if (document.readyState === 'complete') {
				fn();
			}
		};
	}
	
	function nullApplePayData(){
		errors = [];
		administrativeValue = null;
		shippingCountryIncorrect = null;
		billingAdministrativeValue = null;
		validationWrapperCls = null;
		validationFormCls = null;
		storefrontShippingCountry = null;
		sectionData = null;
	}
	
	function removeDWScript(){
		var dwScriptArr = Array.prototype.filter.call(document.getElementsByTagName('script'), function(item){
			return item.src.indexOf('/applepay.js') !== -1;
		});
		
		for(var i = 0; i<dwScriptArr.length; i++){
			dwScriptArr[i].parentNode.removeChild(dwScriptArr[i]);
		}
	}
	
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.applepay = {
		init: function(){
			//remove not needed DW applepay script
			removeDWScript();
			
			if('https:' === document.location.protocol && app.paymentInfo.applePayMerchantProvider &&
					dwObject.applepay && !$.isEmptyObject(dwObject.applepay)){
				config = dwObject.applepay;
				initializeConfigs();
				initializeCache();
				initializeDOM();
				initializeEvents();
			}
		},
		showApplePayPrivacyBlocks: function() {
			var privacyBlocks = $($cache.privacyBlock);
			privacyBlocks.removeClass($cache.hHidden);
		}
	};
	
})(window.app = window.app || {}, jQuery, window.dw = window.dw || {});