import AmazonPayButtonMgr from 'amazon/AmazonPayButtonMgr';

(function(app, $) {
	var $cache;

	function setAddToCartHandler(e) {
		var form = $(this).closest("form");
		e.preventDefault();

		if (form.valid()) {
			var options = {
				url: app.util.ajaxUrl(form.attr('action')),
				method: 'POST',
				cache: false,
				data: form.serialize()
			};

			$.ajax(options).done(function(response) {
				if (response.success) {
					app.ajax.load({
						url: app.urls.minicartGC,
						data: { lineItemId: response.result.lineItemId },
						callback: function(res) {
							app.minicart.update(res);

							if (app.configs.minicart.enabled) {
								app.flyoutMgr.open('minicart');
								$cache.document.trigger('minicart.afterload');
							}

							form.find('input,textarea').val('');
							form.find('div.f-field').removeClass('f-state-valid');

							if (app.device.isMobileView() && app.preferences.productShowPopup && !app.preferences.isMiniCartEnabled) {
								var url = app.util.appendParamsToUrl(app.urls.productOnAddTo, {
									addedTo: 'cart'
								});

								if (url) {
									app.fancybox.open(url, {
										type: 'ajax',
										width: '100%',
										margin: 0,
										padding: 0,
										wrapCSS: 'b-giftcert_add_event',
										autoSize: true,
										afterShow: function() {
											$cache.document.trigger('fancy.mobile.added');

											if (app.amazon && app.amazon.pay.enabled) {
												const amazonPayButtons = document.querySelectorAll('.js-amazon-pay-button');

												if (amazonPayButtons.length > 0) {
													for (var i = 0, len = amazonPayButtons.length; i < len; i++) {
														AmazonPayButtonMgr.render(amazonPayButtons[i], app.amazon.pay, 'Cart');
													}
												}
											}
										},
										afterClose: function() {
											$cache.document.trigger('fancy.mobile.closed');
										}
									});
								}
							}

							$cache.addGiftCardForm.find('.f-field').removeClass('f-state-error');
							$cache.addGiftCardForm.find('input').removeClass('error');
							form.get(0).dispatchEvent(new CustomEvent('submitSuccessful'));
						}
					});
				} else {
					var resposeErrorKeys = Object.keys(response.errors.FormErrors);
					var resposeErrorKeysLength = resposeErrorKeys.length;

					for (var i = 0; i < resposeErrorKeysLength; i++) {
						var id = resposeErrorKeys[i];
						var $inputElem = $('#' + id);

						$inputElem.nextAll($cache.errorMessageSel).show();
						$inputElem.addClass('error');

						var errorEl = $inputElem.removeClass('valid').closest('.f-field').find($cache.errorMessageBlockSel);

						if (!errorEl || errorEl.length === 0) {
							errorEl = $('<span for="' + id + '" generated="true" class="f-error_text"></span>');
							$('#' + id).parent().find($cache.errorMessageBlockSel).html(errorEl);
						} else {
							errorEl.html(response.errors.FormErrors[id].replace(/\\'/g, "'")).show();
						}

						$cache.addGiftCardForm.find('div.f-state-required:not(.f-state-valid)').addClass('f-state-error');

						$inputElem.one('focusout', function() {
							var errorBlockEl = $(this).siblings('.f-error_message').find('.f-error_message-block');

							if (errorBlockEl.text().length) {
								errorBlockEl.text('');
							}
						});
					}
				}
			}).fail(function(xhr, textStatus) {
				// failed
				if (textStatus === 'parsererror') {
					app.fancybox.open(app.resources.BAD_RESPONSE);
				} else {
					app.fancybox.open(app.resources.SERVER_CONNECTION_ERROR);
				}
			});
		}
	}

	function initializeCache() {
		$cache = {
			document: $(document),
			addGiftCardForm: $('#GiftCertificateForm'),
			addToCart: $('#AddToBasketButton'),
			errorMessageSel: '.f-error_message',
			errorMessageBlockSel: '.f-error_message-block'
		};
	}

	function initializeEvents() {
		$cache.addToCart.on('click', setAddToCartHandler);
	}

	app.components = app.components || {};
	app.components.account = app.components.account || {};
	app.components.account.giftcertpurchase = {
		init : function(){
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
