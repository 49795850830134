/**
 * @class app.sendToFriend
 */
(function (app, $) {
	var $cache = {},
		configs = {},
		initialized=false;
	/**
	 * @private
	 * @function
	 * @description Initializes the events (preview, send, edit, cancel and close) on the send to friend form
	 */
	function initializeCache(){
		$cache = {
			body: $("body"),
			form: $(".js-send_to_friend_form"),
			dialog: $(".fancybox-inner"),
			pdpForm: $(".js-form_pdp"),
			jsSendButtonClass: ".js-send_button",
			click: "click",
			isPDPConfigurable: 'configurator' in app.components.product && app.components.product.configurator.isConfigurable
		};
	}

	function initializeConfigs(params) {
		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeEvents() {
		app.util.limitCharacters();
		if (initialized) {return; }
		$cache.body.on($cache.click, $cache.jsSendButtonClass, function (e) {
			e.preventDefault();
			$cache.form.validate();
			if (!$cache.form.valid()) {
				return false;
			}
			var requestType = $cache.form.find(".js-request_type");
			if (requestType.length>0) {
				requestType.remove();
			}
			$("<input/>").attr({"class":"js-request_type", "type":"hidden", "name":$(this).attr("name"), "value":$(this).attr("value")}).appendTo($cache.form);

			var data = $cache.form.serializeArray();

			if ($cache.isPDPConfigurable || app.product.isCustomized()) {
				app.components.sendtofriend.custom.sendCustomizedProduct(data);
			} else {
				sendToFriendsAction(data);
			}

		});

		initialized=true;
	}

	function sendToFriendsAction(data) {
		app.ajax.load({
			url: $cache.form.attr("action"),
			data: data,
			target: $(".js-send_to_friend_response"),
			callback: function() {
				$(document).trigger("sendtofriend.send", $cache.form);
				app.validator.init();
				app.util.limitCharacters();
				$cache.form = $(".js-send_to_friend_form");
				app.fancybox.open($(".js-send_to_friend_response"));
			}
		});
	}

	function initializeDialog(eventDelegate, eventTarget) {
		initializeCache();
		$(eventDelegate).on($cache.click, eventTarget, function (e) {
			e.preventDefault();
			var data = app.util.getQueryStringParams($cache.pdpForm.serialize());
			if (data.cartAction) {
				delete data.cartAction;
			}

			if (data.customProducts) {
				delete data.customProducts;
			}

			if (app.product.isCustomizable()) {
				data.pid = app.configurator.configurator.getProductID();
			}

			var url = app.util.appendParamsToUrl(this.href, data);
			if (url.indexOf(this.hostname) === -1) {
				url = this.protocol + '//' + this.hostname + ((url.charAt(0) === '/') ? url : ('/' + url));
			}
			var options = {
				type: 'ajax',
				wrapCSS: 'fancybox-send_to_friend',
				afterShow: function() {
					app.components.global.sendToFriend.init();
					app.validator.init();
				}
			};
			if ((configs != undefined) && !jQuery.isEmptyObject(configs)){
				$.extend(options, configs);
			}
			app.fancybox.open(app.util.ajaxUrl(url), options);
		});
	}

	/*************** app.components.global.sendToFriend public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.sendToFriend = {
		init: function(params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},
		initializeDialog: initializeDialog,
		sendToFriendsAction: sendToFriendsAction
	};

}(window.app = window.app || {}, jQuery));