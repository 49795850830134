import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $) {
	var $cache = {};
	var footer = 'FOOTER';
	var configs = {};
	var resources = app.resources;

	function initializeConfigs(params) {
		configs = {
			successCallback: successCallback,
			newsletterFormSelector: '.js-simple_newsletter-form',
			submitActionSelector: $('.js-simple_newsletter-button').length
				? '.js-simple_newsletter-button'
				: "[name$='_newsletter_simple_apply']:not(.js-popup_submit)",
			submitBtnsSelector: ".js-action_simple [name*='_newsletter_simple_apply']",
			typeFieldSelector: "[name$='_newsletter_simple_type']",
			detailedSubmitBtnsSelector: "[name*='_newsletter_detailed_apply_']",
			detailedNewsletterFormSelector: 'form.js-newsletter_subscription_form',
			newsletterSubscriptionTypesSelector: '.js-newsletter-form_subscription-types',
			emailFieldSelector: "input[type='email']",
			selectAllBtnSelector: '.js-simple_newsletter-button_selectall',
			buttonTextSelectAllSelector: '.js-button-text-selectall',
			buttonTextDeselectAllSelector: '.js-button-text-deselectall',
			submitBtnSelector: '.js-simple_newsletter-button_submit',
			submitBtnDisabledClass: 'b-simple_newsletter-button_disabled',
			newsletterFormNewsforSelector: '.js-newsletter_form_newsfor',
			newsletterButtonSelector: '.js-simple_newsletter-button',
			newsletterFormNewsforMultiSelector: '.js-newsletter-form-newsfor',
			newsletterFormNewsforMultiFieldSelector: '.js-newsletter-form-newsfor_field',
			newsletterFormNewsforMultiFieldCheckedSelector: '.js-newsletter-form-newsfor_field .f-checkbox:checked',
			popupNewsletterFormSelector: '.js-popup_form_for_submit',
			newsletterTypeNotRequiredSelector: '.js-newsletter-type-not-required'
		};

		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeCache() {
		var newsletterForm;
		var typeField;
		var	detailedNewsletterForm;

		$cache = {
			newsletterForm: function(el) {
				if (el) {
					newsletterForm = el;

					return;
				}
				if (!(newsletterForm && newsletterForm.length)) {
					newsletterForm = $(configs.newsletterFormSelector);
				}

				return newsletterForm;
			},
			typeField: function() {
				if (!(typeField && typeField.length)) {
					typeField = $(configs.typeFieldSelector);
				}

				return typeField;
			},
			detailedNewsletterForm: function() {
				if (!(detailedNewsletterForm && detailedNewsletterForm.length)) {
					detailedNewsletterForm = $(configs.detailedNewsletterFormSelector);
				}

				return detailedNewsletterForm;
			},
			document: $(document),
			selectedForm: $(configs.popupForm).length ? $(configs.popupForm) : $(configs.newsletterFormSelector),
			selectAllButton: $(configs.selectAllBtnSelector),
			submitButton: $(configs.submitBtnSelector),
			newsletterFormNewsfor: $(configs.newsletterFormNewsforSelector),
			newsletterFormSelector: $(configs.newsletterFormSelector)
		};
		$cache.typeCheckboxes = $cache.selectedForm.find('input:checkbox');
	}

	function initializeEvents() {
		$cache.document.on('submit', configs.newsletterFormSelector, function(e) {
			var form = $(this);
			form.length && $cache.newsletterForm(form);
			e.preventDefault();

			if (app.preferences.subscribeByEnter) {
				subscribeUser();
			}

			return false;
		});
		$cache.document.on('click', configs.submitBtnsSelector, function(e) {
			var form = $(this).parents('form');
			form.length && $cache.newsletterForm(form);

			if ($cache.newsletterFormNewsfor.length) {
				$cache.newsletterFormNewsfor.val(e.target.closest(configs.newsletterButtonSelector).value);
			}

			e.preventDefault();
			subscribeUser();
		});

		$cache.document.on('click', configs.detailedSubmitBtnsSelector, function(e) {
			e.preventDefault();
			detailedSubscribeUser($(this).val());
		});

		$cache.document.on('change', $cache.document.find('form input:checkbox'), function(e) {
			toggleActiveClassSubmitBtn();
			selectAllButtonChange(e.target);
		});

		$cache.selectAllButton.on('click', function(e) {
			e.preventDefault();

			if (!allCheckboxesSelected()) {
				$cache.typeCheckboxes.attr('checked', true);
				toggleActiveClassSubmitBtn();
			} else {
				$cache.typeCheckboxes.attr('checked', false);
				toggleActiveClassSubmitBtn();
			}

			selectAllButtonChange(e.target.closest('form'));
		});

		if (configs.submitActionSelector && $(configs.submitBtnsSelector).length === 0) {
			$cache.document.on('click', configs.submitActionSelector, function(e) {
				var form = $(this).parents('form');

				form.length && $cache.newsletterForm(form);
				e.preventDefault();
				subscribeUser();
			});
		}

		$cache.document.on('newsletterpopup.opened', function() {
			populateMultiChoiceSelection($(configs.popupNewsletterFormSelector));
		});
	}

	function subscribeUser() {
		var globalNewsletter = app.components.global.newsletter;
		var sectionsStatus = true;

		$cache.newsletterForm().validate();

		if (globalNewsletter && globalNewsletter.validateNewsletterSections) {
			sectionsStatus = app.components.global.newsletter.validateNewsletterSections('.js-footer-blocks-wrap');
		}

		if (!$cache.newsletterForm().valid() || !sectionsStatus || ($cache.newsletterFormSelector.find(configs.newsletterSubscriptionTypesSelector).length && !$cache.newsletterFormSelector.find('input:checkbox:checked').length && !$cache.newsletterFormSelector.find(configs.newsletterTypeNotRequiredSelector).length)) {
			return false;
		}

		var customEvent = $.Event('simple.subscribe');

		$cache.document.trigger(customEvent, $cache.newsletterForm());

		if (customEvent.isDefaultPrevented()) {
			return false;
		}

		var url = app.urls.submitSimpleSubscription;
		var data = $cache.newsletterForm().serializeArray();

		if (app.newsletterSubscriptionSource) {
			data.push({ name: 'customSource', value: app.newsletterSubscriptionSource });
		}

		CSRF.populatePayloadByCSRFToken(data)
			.then(() => app.ajax.load({
				url: url,
				type: 'POST',
				data: data,
				callback: function(data) {
					if (data.redirectURL) {
						app.page.redirect(data.redirectURL);
					} else {
						var newsletterForm = $cache.newsletterForm();
						var emailField = newsletterForm.find(configs.emailFieldSelector);
						var newsletterSections = newsletterForm.find('.js-section');

						$cache.document.trigger('newsletter.subscribed', [emailField.val(), footer]);

						setNewsletterCookie(2);
						emailField.blur();
						emailField.val('');
						newsletterSections.attr('checked', false);
						$cache.typeCheckboxes.attr('checked', false);
						toggleActiveClassSubmitBtn();

						if ($cache.selectAllButton.length) {
							selectAllButtonChange($cache.selectAllButton);
						}

						if (configs.successCallback && typeof configs.successCallback === 'function') {
							configs.successCallback(data);
						}
					}
				}
			}));
	}

	function detailedSubscribeUser(type) {
		if (type) {
			$cache.typeField().val(type);
		}

		var $form = $cache.detailedNewsletterForm();

		$form.validate();

		if (!$form.valid()) {
			return false;
		}

		$form.submit();
	}

	function successCallback(data) {
		$cache.document.trigger('footer.newsletter.success');

		if (app.util.getConfig('showNotificationMessage') && data) {
			app.notificationsMgr.show('general', { text: data });

			return;
		}

		if (data) {
			app.fancybox.open($('footer'), {
				content: data,
				type: 'html',
				closeBtn: app.device.isMobileView(),
				wrapCSS: 'l-newsletter_popup_desktop m-simplesubscription js-simplesubscription',
				afterShow: function() {
					$cache.document.trigger('simplenewsletterpopupconfirmation.opened');
				},
				afterClose: function() {
					$cache.document.trigger('simplenewsletterpopupconfirmation.closed');
				}
			});
			setTimeout(function() {
				app.fancybox.close();
			}, app.util.getConfig('ajaxNewsletter.thankyouFancyboxCloseTimeout'));
		}
	}

	function setNewsletterCookie(value) {
		var expiration = new Date();
		var year = 365 * 24 * 60;

		expiration.setTime(expiration.getTime() + year * 60 * 1000);
		$.cookie('nlPopupCount', value, { expires: expiration, path: '/' });
	}

	function selectAllButtonChange(el) {
		var isAllCheckboxesSelected = allCheckboxesSelected();

		$cache.selectedForm.find(configs.buttonTextSelectAllSelector).toggleClass('h-hidden', isAllCheckboxesSelected);
		$cache.selectedForm.find(configs.buttonTextDeselectAllSelector).toggleClass('h-hidden', !isAllCheckboxesSelected);
		populateMultiChoiceSelection(el.closest('form'));
	}

	function allCheckboxesSelected() {
		var $checkedCheckboxes = $cache.selectedForm.find('input:checkbox:checked');

		return $checkedCheckboxes.length === $cache.typeCheckboxes.length;
	}

	function toggleActiveClassSubmitBtn() {
		var $checkedCheckboxes = $cache.selectedForm.find('input:checkbox:checked');

		if (resources.NL_EMAIL_VALIDATION_ENABLED) {
			var $formInput = $cache.selectedForm.find('input[type="email"]')[0];
			var toggleClassFlag = $checkedCheckboxes.length && $formInput.value !== '';

			$cache.selectedForm.find($cache.submitButton).toggleClass(configs.submitBtnDisabledClass, !toggleClassFlag);
		} else {
			$cache.selectedForm.find($cache.submitButton).toggleClass(configs.submitBtnDisabledClass, !$checkedCheckboxes.length);
		}
	}

	function populateMultiChoiceSelection(form) {
		var newsletterFieldsWrapper = $(form).find(configs.newsletterSubscriptionTypesSelector);
		var newsForElement = newsletterFieldsWrapper.find(configs.newsletterFormNewsforMultiSelector);

		if ($(configs.newsletterFormNewsforMultiSelector).length) {
			var newsforValue = [];

			$(newsletterFieldsWrapper.find(configs.newsletterFormNewsforMultiFieldCheckedSelector)).each(function() {
				var choiceID = $(this).closest(configs.newsletterFormNewsforMultiFieldSelector).data('newsforChoiceId');

				newsforValue.push(choiceID);
			});

			newsForElement.val(newsforValue);
		}
	}

	/** app.components.global.simplesubscription public object */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.simplesubscription = {
		init: function(params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},
		successCallback: successCallback
	};
})((window.app = window.app || {}), jQuery);
