//app.components.global.dynamicpromotion
(function(app, $) {
	var $cache = {};

	function initCache() {
		$cache = {
			document: $(document),
			dynamicPDPPromoSel: '.js-product_dynamic_promotions',
			dynamicMiniCartPromoSel: '.js-mini_cart-product_dynamic_promotions',
			productIDSel: '.js-product_id:first'
		};
	}

	function initEvents() {
		$cache.document.on('dynamicpromotion.pdp.update', function() {
			var pid = $($cache.productIDSel).val();
			if (pid) {
				$.get(app.urls.getDynamicPromotionInfo, { viewtype: 'pdp', pid: pid }).done(function(response) {
					if (response) {
						$($cache.dynamicPDPPromoSel).html(response);
					}
				});
			}
		});
	}

	/**
	 * @namespace app.components.global.dynamicpromotion public object
	 **/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.dynamicpromotion = {
		init: function() {
			if (app.preferences.dynamicPromotionsEnable) {
				initCache();
				initEvents();
			}
		}
	};
})((window.app = window.app || {}), jQuery);
