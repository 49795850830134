(function(app, $) {
	var $cache = {},
		placeSearch,
		autocomplete,
		formMapping = {
			street_number: 'street',
			route: 'street',
			locality: 'city',
			administrative_area_level_1: 'stateCode',
			administrative_area_level_2: 'stateCode',
			postal_code: 'zipCode',
			postal_town: 'city'
		},
		componentForm = {
			street_number: 'short_name',
			route: 'long_name',
			locality: 'long_name',
			administrative_area_level_1: 'short_name',
			administrative_area_level_2: 'short_name',
			postal_code: 'short_name',
			postal_town: 'long_name'
		};

	function initializeCache() {
		$cache = {
			shipping: {
				street: "input[name$='_shippingAddress_addressFields_address1']",
				city: "input[name$='_shippingAddress_addressFields_city']",
				zipCode: "input[name$='_shippingAddress_addressFields_zip']",
				countryCode: "[id$='_shippingAddress_addressFields_country']",
				stateCode: "[id$='_shippingAddress_addressFields_states_state']"
			},
			billing: {
				street: "input[name$='_billing_billingAddress_addressFields_address1']",
				city: "input[name$='_billing_billingAddress_addressFields_city']",
				zipCode: "input[name$='_billing_billingAddress_addressFields_zip']",
				countryCode: "[id$='_billingAddress_addressFields_country']",
				stateCode: "[id$='_billingAddress_addressFields_states_state']"
			},
			document: $(document)
		};
		$cache.selectors = {
			shipping: "input[name$='_shippingAddress_addressFields_address1']",
			billing: "input[name$='_billing_billingAddress_addressFields_address1']"
		};
		$cache.fragments = {
			shipping: $($cache.shipping.street).length
				? document.createDocumentFragment().appendChild(
						$($cache.shipping.street)
							.get(0)
							.cloneNode(true)
				  )
				: null,
			billing: $($cache.billing.street).length
				? document.createDocumentFragment().appendChild(
						$($cache.billing.street)
							.get(0)
							.cloneNode(true)
				  )
				: null
		};
	}

	function initializeEvents() {
		$($cache.shipping.street).on('focusin', function() {
			createAutocomplete(autocomplete, 'shipping');
		});
		$($cache.billing.street).on('focusin', function() {
			createAutocomplete(autocomplete, 'billing');
		});
		$cache.document.on('autocomplete.change.country', function(e, data) {
			switch (data.type) {
				case 'shipping':
					logAction('User change country for shipping address');
					createAutocomplete(autocomplete, 'shipping');
					break;
				case 'billing':
					logAction('User change country for billing address');
					createAutocomplete(autocomplete, 'billing');
					break;
			}
		});
	}
	function createAutocomplete(autocomplete, addressType) {
		// Create the autocomplete object, restricting the search to geographical
		// location types.

		if (!app.constants.ADDRESS_AUTOCOMPLETE) {
			return;
		}

		var input, options, currentCountry;

		if ($cache[addressType].street.length) {
			input = $($cache.selectors[addressType]).get(0);
			currentCountry = $($cache[addressType].countryCode).val();
			logAction('Current country is ' + currentCountry);
			options = { types: ['address'] };
			autocomplete = new google.maps.places.Autocomplete(input, options);
			if (currentCountry) {
				setAutocompleteCountry(autocomplete, currentCountry);
			} else {
				geolocate(autocomplete);
			}
			if (
				autocomplete.componentRestrictions &&
				autocomplete.componentRestrictions.country &&
				(app.constants.ADDRESS_AUTOCOMPLETE.indexOf(autocomplete.componentRestrictions.country) != -1 ||
					app.constants.ADDRESS_AUTOCOMPLETE.indexOf('ALL') != -1)
			) {
				bindAutocompleteEvent(autocomplete, addressType);
			} else if (input !== undefined) {
				var value = $(input).val();
				$(input).replaceWith($cache.fragments[addressType].cloneNode(true));
				var $input = $($cache.selectors[addressType]);
				$input.val(value).focus();
				$cache.document.trigger('address-cloned', addressType, $input);
				app.components.global.warning.reinit($input);
			}
		}
	}
	function bindAutocompleteEvent(autocomplete, addressType) {
		logAction("Unbind all autocomplete event 'place_changed'");
		google.maps.event.clearListeners(autocomplete, 'place_changed');
		logAction("Bind new autocomplete event 'place_changed'");
		google.maps.event.addListener(autocomplete, 'place_changed', function() {
			fillInAddress(autocomplete, addressType);
		});
	}
	// Fill address form from response
	function fillInAddress(autocomplete, addressType) {
		// Get the place details from the autocomplete object.
		var place = autocomplete.getPlace(),
			inverseAddress =
				app.preferences.countryListAddressInverse &&
				app.preferences.countryListAddressInverse.indexOf(app.user.country.value.toUpperCase()) !== -1,
			val,
			fieldType,
			formField,
			oldFormFieldVal,
			addressLine;
		logAction('Get the place details from the autocomplete object.');
		// Get each component of the address from the place details
		// and fill the corresponding field on the form.

		if (place.hasOwnProperty('address_components') && place.address_components.length) {
			logAction('Clean address fields for ' + addressType);
			cleanAddress(addressType);
			logAction('Start fill the corresponding field on the form');
			logAction(place.address_components);
			for (var i = 0; i < place.address_components.length; i++) {
				fieldType = place.address_components[i].types[0];
				if (componentForm[fieldType]) {
					val = place.address_components[i][componentForm[fieldType]];
					formField = $($cache[addressType][formMapping[fieldType]]);
					switch (fieldType) {
						case 'route':
							oldFormFieldVal = formField.val();
							if (oldFormFieldVal) {
								addressLine = inverseAddress ? oldFormFieldVal + ' ' + val : val + ' ' + oldFormFieldVal;
							} else {
								addressLine = val;
							}
							formField.val(addressLine).trigger('focusout');
							break;
						case 'administrative_area_level_1':
						case 'administrative_area_level_2':
							if (formField.find('option').length) {
								if (formField.find('option[value="' + val + '"]').length) {
									formField.find('option').removeAttr('selected');
									formField.find('option[value="' + val + '"]').attr('selected', 'selected');
									formField.val(val);
									formField.trigger('focusout');
								}
							} else {
								formField.val(val).trigger('focusout');
							}
							break;
						default:
							formField.val(val).trigger('focusout');
					}
				}
			}
			$cache.document.trigger('addressfield.autofill');
		}
	}
	// Cleans address form
	function cleanAddress(addressType) {
		for (var field in $cache[addressType]) {
			if (field != 'countryCode') {
				$($cache[addressType][field]).val('');
			}
		}
	}
	// Set the country restriction based on user input.
	function setAutocompleteCountry(autocomplete, country) {
		if (country) {
			logAction('Current country set into autocomplete object ' + country);
			logAction('Autocomplete country before set (autocomplete.componentRestrictions.country):');
			logAction(autocomplete.componentRestrictions ? autocomplete.componentRestrictions.country : 'undefined');
			autocomplete.setComponentRestrictions({ country: country });
			logAction('Autocomplete after set country (autocomplete.componentRestrictions.country):');
			logAction(autocomplete.componentRestrictions ? autocomplete.componentRestrictions.country : 'undefined');
		} else {
			geolocate(autocomplete);
		}
	}

	// Bias the autocomplete object to the user's geographical location,
	// as supplied by the browser's 'navigator.geolocation' object.
	function geolocate(autocomplete) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function(position) {
				var geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				var circle = new google.maps.Circle({
					center: geolocation,
					radius: position.coords.accuracy
				});
				autocomplete.setBounds(circle.getBounds());
			});
		}
	}
	// Log Action
	function logAction(data) {
		if (window.hasOwnProperty('console') && data && app.debugMode != app.constants.PRODUCTION_SYSTEM) {
			console.log(data);
		}
	}
	/*************** app.components.global.autocomplete public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.autocomplete = {
		// initializations
		init: function(params) {
			initializeCache();
			initializeEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
