(function(app, $){
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			document: $(document),
			bridalItems: $('.js-bridal_item'),
			selectors: {
				bridalBookAppointment: '.js-bridal_book_appointment',
				bridalDescription: '.js-bridal_description',
				bridalAppointmentFormContainer: '.js-bridal_appointment_form_container',
				bridalAppointmentForm: '.js-bridal_appointment_form',
				bridalAppointmentSubmit: '.js-bridal_appointment_submit',
				bridalAppointmentSuccess: '.js-bridal_appointment_success',
				bridalAppointmentFailed: '.js-bridal_appointment_failed',
				bridalPopup: '.js-bridal_popup',
				bridalProductName: '.js-bridal_product_name',
				carouselImagesSelector: '.b-owl_carousel-item:not(.cloned)',
				carouselItemSelector: '.b-owl_carousel-item',
				carouselNavigation: '.b-owl_carousel-nav_next, .b-owl_carousel-nav_prev',
				thumbnailsSlider: '.js-thumbnails_slider',
				thumbnails: '.js-thumbnails',
				thumbnail: '.js-thumbnail',
				tile: '.js-title',
				longDescription: '.js-long_description',
				popupMainImage: '.js-main_image',
				bridalArrows: '.js-bridal_next, .js-bridal_prev',
				activeBridal: '.js-active',
				backButton: '.js-bridal_back',
				owlCarousel: '.js-owl_carousel',
				mainPopUpImg: '.js-bridal_popup .js-main_image img',
				thumbnailsForZoom: '.js-bridal_popup .js-thumbnails',
				zoomSource: '.js-zoom_fancybox',
				zoomFancyBox: '.js-zoomed_img_fancybox',
				productVimeo : '.js-product-vimeo',
				vimeoDataAttribute: '[data-vimeo-id]'
			},
			classes: {
				hidden: 'h-hidden',
				bridalNext: 'js-bridal_next',
				activeBridal: 'js-active',
				thumbnailsArrowUp: 'b-thumbnails-arrow_up',
				thumbnailsArrowDown: 'b-thumbnails-arrow_down',
				thumbnailSelected: 'b-thumbnail-selected',
				zoomPrimaryImg: 'main_zoomed_img',
				zoomFancyBox: 'b-product_image_zoomed js-zoomed js-zoomed_img_fancybox',
				zoomedMobileCntr: 'b-bridal_zoomed_img_mob'
			},
			templates: {
				bridal: $('#js-bridal_popup').html()
			},
			videoCarouselData: {}
		}

	}
	
	function initializeDOM() {
		$($cache.selectors.owlCarousel).each(function() {
			var owlInstance = app.owlcarousel.getInstance(this);
			var $currentItem = $(this).find($cache.selectors.carouselItemSelector).eq(owlInstance.current()).find($cache.selectors.vimeoDataAttribute);
			initializeVideoOnItem($currentItem);
		});
	}
	
	function initializeEvents() {
		$cache.bridalItems.on('click', function(e) {
			if (!$(e.target).is($cache.selectors.carouselNavigation)) {
				showBridalPopup.call(this);
			}
			
			return false;
		});

		$cache.document.on('changed.owl.carousel', $cache.selectors.owlCarousel, function(e) {
			var $currentItem = $(e.target).find($cache.selectors.carouselItemSelector).eq(e.item.index).find($cache.selectors.vimeoDataAttribute);
			if ($currentItem.length) {
				
				initializeVideosOnCarousel($(e.target));
			}
		});

		$cache.document.on('drag.owl.carousel', $cache.selectors.owlCarousel, function (e) {

			var carouselActive = $(e.target).attr('carousel-active');
			if (carouselActive === "true") {
				var $currentItem = $(e.target).find($cache.selectors.carouselItemSelector).find($cache.selectors.vimeoDataAttribute);
				$currentItem.each(function () {
					var dataVimeoID = $(this).data('vimeo-id');
					if (dataVimeoID in $cache.videoCarouselData) {
						$cache.videoCarouselData[dataVimeoID].setCurrentTime(0);
					}
				});
			} else {
				initializeVideosOnCarousel($(e.target));
			}

		});
		
		$cache.document.on('click', $cache.selectors.bridalBookAppointment, function() {
			setProductName();
			switchBlocks($cache.selectors.bridalDescription, $cache.selectors.bridalAppointmentFormContainer);
			if (app.device.isMobileUserAgent()) {
				$($cache.selectors.bridalAppointmentFormContainer)[0].scrollIntoView({behavior: 'smooth', block: 'start'});
			}
			app.validator.initForm($cache.selectors.bridalAppointmentForm);
		});
		
		$cache.document.on('click', $cache.selectors.bridalArrows, function() {
			var activeIndex = $cache.bridalItems.index($cache.bridalItems.filter($cache.selectors.activeBridal));
			var indexToSelect = getItemIndexToSelect($(this).hasClass($cache.classes.bridalNext), $cache.bridalItems.length - 1, activeIndex);
			showBridalPopup.call($cache.bridalItems.eq(indexToSelect));
			
			return false;
		});
		
		$cache.document.on('click', $cache.selectors.bridalAppointmentSubmit, function() {
			var $form = $(this).closest('form');
			if ($form.valid()) {
				var disabled = $form.find(':input:disabled').removeAttr('disabled');
				var options = {
					url : app.util.ajaxUrl($form.attr('action')),
					method : 'POST',
					cache: false,
					data : $form.serialize()
				};
				disabled.attr('disabled','disabled');
				
				$.ajax(options).done(function (response) {
					if (response.success) {
						switchBlocks($cache.selectors.bridalAppointmentFormContainer, $cache.selectors.bridalAppointmentSuccess);
					}
					else if (response.error) {
						switchBlocks($cache.selectors.bridalAppointmentFormContainer, $cache.selectors.bridalAppointmentFailed);
					}
					else {
						$($cache.selectors.bridalAppointmentFormContainer).html(response);
					}
				}).fail(function() {
					switchBlocks($cache.selectors.bridalAppointmentFormContainer, $cache.selectors.bridalAppointmentFailed);
				});
			}
			
			return false;
		});
		
		$cache.document.on('click', $cache.selectors.backButton, function() {
			app.fancybox.close();
			return false;
		});
		
		$cache.document.on('click', $cache.selectors.mainPopUpImg, function(e) {
			var bridalPopUp = $.fancybox.current.inner.clone();
			
			var bridalPopUpSelects = bridalPopUp.find("select");
			$.fancybox.current.inner.find("select").each(function(i) {
				bridalPopUpSelects.eq(i).val($(this).val());
			});
			
			var bridalFormReinitIsNeeded = !bridalPopUp
				.find($cache.selectors.bridalAppointmentFormContainer)
				.hasClass($cache.classes.hidden);
			function afterZoomClosed() {
				showBridalPopup.call($cache.bridalItems.filter($cache.selectors.activeBridal), bridalPopUp);
				if(bridalFormReinitIsNeeded) {
					app.validator.initForm($cache.selectors.bridalAppointmentForm);
				}
			} 
			app.zoom.showZoomedImg({
				source: $($cache.selectors.zoomSource),
				fancyBoxWrapperClass: $cache.classes.zoomFancyBox,
				fancyBoxWrapperSel: $cache.selectors.zoomFancyBox,
				zoomedImg: $(e.target).clone().addClass($cache.classes.zoomPrimaryImg),
				thumbnails: $($cache.selectors.thumbnailsForZoom).clone(),
				onImgClick: function() {
					afterZoomClosed();
				},
				onCloseClick: function() {
					afterZoomClosed();
					return false;
				}
			});
		});
	}
	
	function setProductName() {
		var productId = $cache.bridalItems.filter($cache.selectors.activeBridal).data("product-id");
		$($cache.selectors.bridalProductName).find('input').val(productId);
	}

	function showBridalPopup(content) {
		setItemAsActive(this);
		
		var $this = $(this);
		var $elementsForThumbnails = $this.find($cache.selectors.carouselImagesSelector).children();
		var thumbnails = createThumbnailsHtml($elementsForThumbnails);
		var mainimage = $elementsForThumbnails.length > 0 ? $elementsForThumbnails.get(0).outerHTML : '';
		var popupHtml = app.util.renderTemplate($cache.templates.bridal, {
			tumbnails: thumbnails,
			mainimage: mainimage,
			title: $this.find($cache.selectors.tile).text(),
			description: $this.find($cache.selectors.longDescription).text()
		});
		app.fancybox.open(popupHtml, {
			width: '100%',
			autoSize: false,
			autoResize: true,
			margin: 0,
			helpers: {
				overlay: { locked: false }
			},
			wrapCSS: 'fancybox-bridal',
			beforeShow : function() {
				if (app.device.isMobileUserAgent()) {
					var $popupCarousel = $($cache.selectors.bridalPopup).find($cache.selectors.owlCarousel);
					var $carouselImages = $popupCarousel.find("img");
					app.owlcarousel.initCarousel($popupCarousel);
					$carouselImages.pinchzoomer();
					var isPinchZoomerOpen = false;
					$(PinchZoomer.objs).each(function() {
						this.on(PinchZoomer.FULLSCREEN_TOGGLE, function(el) {
							var img = el.target.elem();
							var toggleElem = img.next('.controlHolder').children('.fullscreenToggle');
							if (toggleElem.hasClass('on')) {
								img.closest(".fullscreenDiv").addClass($cache.classes.zoomedMobileCntr);
								isPinchZoomerOpen = true;
							} else {
								img.closest(".fullscreenDiv").removeClass($cache.classes.zoomedMobileCntr);
								$("body").css("overflow", "hidden auto");
								isPinchZoomerOpen = false;
							}
						});
						this.on(PinchZoomer.DRAG, function (el) {
							if (isPinchZoomerOpen === false) {
								el.target.resetElem(true);
							}
						});
					});
					
					var owlInstance = app.owlcarousel.getInstance($popupCarousel);
					initializeVideoOnItem($popupCarousel.find($cache.selectors.carouselItemSelector).eq(owlInstance.current()).find($cache.selectors.vimeoDataAttribute));
				}
				else {
					if(content && $.fancybox.current && $.fancybox.current.inner) {
						$.fancybox.current.inner.replaceWith(content);
						$.fancybox.current.inner = content;
					}
					
					$.fancybox.current.outer.height($(window).height());
					
					var $thumbnailsSlider = $($cache.selectors.bridalPopup).find($cache.selectors.thumbnailsSlider);
					$thumbnailsSlider.find($cache.selectors.vimeoDataAttribute).each(function() {
						initializePlaceorderForVideo($(this));
					});
					
					initializeVideoOnItem($($cache.selectors.bridalPopup).find($cache.selectors.popupMainImage + ' ' + $cache.selectors.vimeoDataAttribute));
					initThumbnailsSlider($thumbnailsSlider);
				}
				app.components.global.socials.init();
			}
		});
	}
	
	function createThumbnailsHtml($elements) {
		var thumbnailImagesHtml = "";
		
		if (app.device.isMobileUserAgent()) {
			for (var i = 0, len = $elements.length; i < len; i++) {
				thumbnailImagesHtml += $elements.get(i).outerHTML;
			}
		}
		else {
			for (var i = 0, len = $elements.length; i < len; i++) {
				if (i === 0) {
					thumbnailImagesHtml += "<li class='b-bridal_thumbnail js-thumbnail b-thumbnail-selected'>" + $elements.get(i).outerHTML + "</li>";
				}
				else {
					thumbnailImagesHtml += "<li class='b-bridal_thumbnail js-thumbnail'>" + $elements.get(i).outerHTML + "</li>";
				}
			}
		}
		
		return thumbnailImagesHtml;
	}
	
	function setItemAsActive(item) {
		$cache.bridalItems.removeClass($cache.classes.activeBridal);
		$(item).addClass($cache.classes.activeBridal);
	}
	
	function initThumbnailsSlider($element) {
		var images = $element.find('img'),
			promises = [];
		
		for (var i = 0, len = images.length; i < len; i++) {
			promises.push(app.components.global.images.imageLoaded(images.eq(i)));
		}
		
		$.when.apply($, promises).done(function() {
			$element.thumbnailsSlider({
				itemCount: 4,
				arrowUpClass: $cache.classes.thumbnailsArrowUp,
				arrowDownClass: $cache.classes.thumbnailsArrowDown,
				heightRecalculate: app.util.getConfig('disableThumbHeightRecalculate'),
				reverse: app.preferences.pdpThumbnailsSliderReverse,
				loop: true
			});
			
			initThumbnailsClickEvent($element.find($cache.selectors.thumbnail));
		});
	}
	
	function initThumbnailsClickEvent($thumbnails) {
		$thumbnails.on('click', function() {
			var $this = $(this);
			var $thumbnail = $this.children(':first');
			var $mainImage = $($cache.selectors.bridalPopup).find($cache.selectors.popupMainImage);
			$mainImage.find($cache.selectors.vimeoDataAttribute).remove();
			
			if ($thumbnail.data('vimeo-id')) {
				$mainImage.html($thumbnail.clone().empty());
				initializeVideoOnItem($($cache.selectors.bridalPopup).find($cache.selectors.popupMainImage + ' ' + $cache.selectors.vimeoDataAttribute));
			}
			else {
				if (!$mainImage.find('img').length) {
					$mainImage.html($('<img>'));
				}
				var imageUrl = $thumbnail.attr('src');
				$mainImage.find('img').attr('src', imageUrl);
			}
			
			$this
				.closest($cache.selectors.thumbnails)
				.find($cache.selectors.thumbnail)
				.removeClass($cache.classes.thumbnailSelected);
			$this.addClass($cache.classes.thumbnailSelected);
			
			return false;
		});
	}
	
	function switchBlocks(firstBlockSelector, secondBlockSelector) {
		$(firstBlockSelector).addClass($cache.classes.hidden);
		$(secondBlockSelector).removeClass($cache.classes.hidden);
	}
	
	function getItemIndexToSelect(isNext, lastIndex, currentIndex) {
		if (isNext) {
			return currentIndex === lastIndex ? 0 : ++currentIndex;
		} else {
			return currentIndex === 0 ? lastIndex : --currentIndex;
		}
	}

	function initializeVideosOnCarousel($currentCarousel) {
		var $videoItems = $currentCarousel.find($cache.selectors.carouselItemSelector).find($cache.selectors.vimeoDataAttribute);
		var carouselActive = $currentCarousel.attr('carousel-active');
		if (carouselActive === "true") {
			$videoItems.each(function () {
				var dataVimeoID = $(this).data('vimeo-id');
				if (dataVimeoID in $cache.videoCarouselData) {
					$cache.videoCarouselData[dataVimeoID].setCurrentTime(0);
				}
			});
		} else {
			$('[carousel-active="true"]').find($cache.selectors.carouselItemSelector).find($cache.selectors.vimeoDataAttribute).each(function () {
				initializePlaceorderForVideo($(this));
			});
			
			$($cache.selectors.owlCarousel).attr('carousel-active', "false");
			$currentCarousel.attr('carousel-active', 'true');
			
			$cache.videoCarouselData = {};
			$videoItems.each(function () {
				var dataVimeoID = $(this).data('vimeo-id');
				if (dataVimeoID) {
					if (!(dataVimeoID in $cache.videoCarouselData)) {
						$cache.videoCarouselData[dataVimeoID] = {};
					}
					if ($(this).parent().hasClass('cloned')) {
						initializePlaceorderForVideo($(this));
					} else {
						$cache.videoCarouselData[dataVimeoID] = initializeVideoOnItem($(this));
					}
				}
			});
		}
	}

	function initializePlaceorderForVideo($videoItem) {
		if (!$videoItem.find('img').length) {
			$.when(app.components.product.video.getThumbnail($videoItem.data('vimeo-id'), 'large')).done(function (src) {
				$videoItem.html($('<img>', {
					src: src
				}));
			});
		}
	}

	function initializeVideoOnItem($currentItem) {
		if ($currentItem.data('vimeo-id')) {

			var videoPlayer = app.components.product.video.getPlayer({
				id: $currentItem.data('vimeo-id'),
				queryParams: {
					autoplay: true,
					loop: true,
					title: false,
					portrait: false,
					autopause: false,
					background: true,
					allow: 'autoplay',
					muted: true
				}
			});
			$currentItem.html(videoPlayer.element);

			return videoPlayer;
		}
	}

	app.components = app.components || {};
	app.components.bridal = app.components.bridal || {};
	app.components.global.bridal = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
	
})(window.app = window.app || {}, jQuery);