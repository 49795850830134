import CSRF from 'oneapp/src/utils/CSRF';

(function(app, $) {
	var $cache = {};
	var delay = 500;
	var toggledClass = 'h-toggled';
	var updateAfter = true;
	var initialized = false;

	function initializeCache() {
		$cache = {
			document: $(document),
			header: $('header'),
			addToCartSel: '.js-add_to_cart_from_wishlist',
			wishlistFlyoutContainer: $('.js-wishlist_flyout_container'),
			wishlistQtyValSel: '.js-wishlist-quantity_value',
			wishlistQty: '.js-wishlist_qty',
			flyOutQtyContainerSel: '.js-wishlist_qty'
		};
	}

	function initializeEvents() {
		$cache.header.on('click', $cache.addToCartSel, function() {
			// add product to cart and show minicart
			var $form = $(this).closest('form');

			CSRF.populateFormByCSRFToken($form[0]).then(() => {
				var data = $form.serialize();

				app.cart.update(data, function(response) {
					var uuid = $form.find('.js-product_uuid');

					if (uuid.length > 0 && uuid.val().length > 0) {
						app.cart.refresh();
					} else {
						app.minicart.update(response);
						app.flyoutMgr.open('minicart', {
							autoClose: true
						});
						$cache.document.trigger('minicart.afterload');
						$cache.document.trigger('tracking.cartItemAdded');
					}
				});
			});
		});

		$cache.document.on('wishlist.updated', function(event, target) {
			$.ajax({
				url: app.urls.getFlyoutWishlist,
				type: 'get'
			}).done(function(data) {
				if (data) {
					app.wishlist.update(data);
					if (app.page.ns !== 'checkout') {
						app.flyoutMgr.open('wishlist');
					}

					app.components.global.headerwishlist.init({
						updateAfter: false
					});

					if (target === 'header' && app.page.ns === 'wishlist') {
						app.page.refresh();
					}
				}
			});
		});
	}

	/*************** app.components.global.headerwishlist public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.headerwishlist = {
		// initializations
		init : function (params) {
			if (initialized) {
				return;
			}
			params = params || {};
			if ( params.hasOwnProperty('updateAfter')) {
				updateAfter = params.updateAfter;
			}
			initializeCache();
			initializeEvents();
			initialized = true;
		}
	};
})(window.app = window.app || {}, jQuery);
