/**
 * Components specific config
 **/
( function(app, $) {

	var settings =	{
		service_menu_midnight : {},
		logo_midnight : {},	
		navigation_midnight : {
			headerClass: 'js-midnight-navigation'
		}
	};
	
	/*************** app.midnight.settings ***************/
	app.midnight = app.midnight || {};
	app.midnight.settings = app.midnight.settings || {};
	app.midnight.settings = $.extend(true, app.midnight.settings, settings);

}(window.app = window.app || {}, jQuery));