(function (app, $) {
	var $cache = {};

	function initializeCache () {
		$cache = {
			document: $(document),
			window: $(window),
			checkoutForm: $('.js-checkout_form'),
			conditionForm: $('.js-checkout_placeorder-condition-form'),
			errorContainer: $('.js-error_container'),
			errorContainerWrapper: $('.js-error_container_wrapper'),
			productId: '.js-product_id',
			paypalButtonBigContainer: '.js-paypalButtonBigContainer',
			paypalButtonSmallContainer: '.js-paypalButtonSmallContainer',
			paypalExpressButtonBigContainer: '.js-paypalExpressButtonBigContainer',
			paypalExpressButtonBigContainer2: '.js-paypalExpressButtonBigContainer2',
			paypalExpressButtonSmallContainer: '.js-paypalExpressButtonSmallContainer',
			paypalExpressButtonPopupContainer: '.js-paypalExpressButtonPopupContainer',
			paypalExpressButtonPDPContainer: '.js-paypalExpressButtonPDPContainer',
			paypalButtonWrapper: '.js-pdp_paypal_btn-wrapper',
			minicartButtonWrapper: '.b-mini_cart-checkout_btn_wrapper',
			hiddenClass: 'h-hidden'
		};
		$cache.events = {
			formValid:   'checkoutFormValid',
			formInvalid: 'checkoutFormInvalid'
		};
		$cache.mixedLocales = {
			ja: 'ja_JP',
			sv: 'sv_SE',
			zh: 'zh_TW',
			ko: 'ko_KR',
			ar: 'ar_EG'
		};
	}

	function initializeButtons () {
		initPayPalButton($cache.paypalButtonBigContainer);
		initPayPalButton($cache.paypalButtonSmallContainer);
		initExpressPayPalButton($cache.paypalExpressButtonSmallContainer);
		initExpressPayPalButton($cache.paypalExpressButtonBigContainer);
		initExpressPayPalButtonPDP();
	}

	function initializeEvents() {
		$cache.checkoutForm.on('blur change','input, select', function() {
			validateForm();
		});
		$cache.conditionForm.on('blur change','input, select', function() {
			validateForm();
		});
		$cache.document.on('minicart.afterload minicart.product.removed', function() {
			initExpressPayPalButton($cache.paypalExpressButtonSmallContainer);
			initExpressPayPalButton($cache.paypalExpressButtonBigContainer);
			initExpressPayPalButton($cache.paypalExpressButtonPopupContainer);
		});
		$cache.document.on('cart.updateModels cart.shippingCountryChange', function() {
			initExpressPayPalButton($cache.paypalExpressButtonSmallContainer);
			initExpressPayPalButton($cache.paypalExpressButtonBigContainer);
		});
		if (app.device.isMobileView()) {
			$cache.document.on('fancy.mobile.added', function() {
				initExpressPayPalButton($cache.paypalExpressButtonPopupContainer);
			});
		}
	}

	function validateForm() {
		var event = $cache.checkoutForm.validate().checkForm() ? $cache.events.formValid : $cache.events.formInvalid;
		$cache.checkoutForm.trigger(event);
		if ($cache.conditionForm.length){
			event = $cache.conditionForm.validate().checkForm() ? $cache.events.formValid : $cache.events.formInvalid;
			$cache.conditionForm.trigger(event);
		}
	}

	function checkoutFormStateChangeEvent(actions) {
		$cache.checkoutForm
			.on($cache.events.formValid, function() {
				actions.enable();
			})
			.on($cache.events.formInvalid, function() {
				actions.disable();
			});
		if ($cache.conditionForm.length){
			$cache.conditionForm
				.on($cache.events.formValid, function() {
					actions.enable();
				})
				.on($cache.events.formInvalid, function() {
					actions.disable();
				});
		}
	}

	function showError(error) {
		error = error || $cache.errorContainer.data('deferror');
		$cache.errorContainer.html(error);
		$cache.errorContainerWrapper.removeClass($cache.hiddenClass);
	}

	function hideError() {
		$cache.errorContainer.html();
		$cache.errorContainerWrapper.addClass($cache.hiddenClass);
	}

	function initPayPalButton(container) {
		var $container = $(container);

		if($container.length) {
			var buttonStyle = $container.data('buttonstyle');
			paypal.Button.render({
				env: app.debugMode === app.constants.PRODUCTION_SYSTEM ? 'production' : 'sandbox',
				commit: true,
				style: buttonStyle && buttonStyle.style || '',
				validate: function(actions) {
					checkoutFormStateChangeEvent(actions);
					validateForm();
				},
				onClick: function() {
					if (!$cache.checkoutForm.valid()) {
						validateForm();
					}
					if ($cache.conditionForm.length && !$cache.conditionForm.valid()){
						validateForm();
					}
				},
				payment: function(data, actions) {
					if ($cache.checkoutForm.valid()) {
						var postData = $cache.checkoutForm.serializeArray().reduce(function(m,o){ m[o.name] = o.value; return m;}, {});
						return paypal.request.post(app.urls.paypalPopup, postData)
							.then(function(res) {
								res.PaypalError ? showError() : hideError();
								return res.PaypalToken;
							});
					} else {
						actions(new Error('FillData'));
					}
				},
				onAuthorize: function(data, actions) {
					hideError();
					return actions.redirect();
				},
				onError: function(err) {
					showError();
				}
			}, container);
		}
	}

	function initExpressPayPalButton(container) {
		var $container = $(container);
		if($container.length && !$container.children().length) {
			var buttonStyle = $container.data('buttonstyle');
			paypal.Button.render({
				env: app.debugMode === app.constants.PRODUCTION_SYSTEM ? 'production' : 'sandbox',
				commit: false,
				style: buttonStyle && buttonStyle.style || '',
				locale: getCorrectLocaleName(app.preferences.currentLocale),
				payment: function(data, actions) {
					var postData = {};

					app.flyouts.minicart.abortCloseFlyoutAutomatically();
					app.flyouts.minicart.disableAutoHide = true;
					return paypal.request.post(app.urls.paypalPopup, postData)
						.then(function(res) {
							res.PaypalError ? showError() : hideError();
							return res.PaypalToken;
						});
				},
				onAuthorize: function(data, actions) {
					hideError();
					return actions.redirect();
				},
				onError: function(err) {
					window.location.assign(app.urls.cartShow);
				},
				onCancel: function() {
					app.flyouts.minicart.disableAutoHide = false;
					app.flyouts.minicart.closeFlyoutAutomatically();
				}
			}, container);
		}
	}

	function initExpressPayPalButtonPDP() {
		var $container = $($cache.paypalExpressButtonPDPContainer);

		if ($container.length && !$container.children().length && app.product.isVariationSelected() && $($cache.productId).length > 0) {
			var buttonStyle = $container.data('buttonstyle');

			paypal.Button.render({
				env: app.debugMode === app.constants.PRODUCTION_SYSTEM ? 'production' : 'sandbox',
				commit: false,
				style: buttonStyle && buttonStyle.style || '',
				locale: getCorrectLocaleName(app.preferences.currentLocale),
				payment: function(data, actions) {
					var postData = {
						source: 'product',
						pid: $($cache.productId).val()
					};

					app.flyouts.minicart.abortCloseFlyoutAutomatically();
					app.flyouts.minicart.disableAutoHide = true;

					return paypal.request.post(app.urls.paypalPopup, postData)
						.then(function(res) {
							res.PaypalError ? showError() : hideError();

							return res.PaypalToken;
						});
				},
				onAuthorize: function(data, actions) {
					hideError();

					return actions.redirect();
				},
				onError: function(error) {
					window.location.assign(app.urls.cartShow);
				},
				onCancel: function() {
					app.flyouts.minicart.disableAutoHide = false;
					app.flyouts.minicart.closeFlyoutAutomatically();
				}
			}, $cache.paypalExpressButtonPDPContainer);

			$($cache.paypalButtonWrapper).toggleClass($cache.hiddenClass);
		}
	}

	function getCorrectLocaleName(locale) {
		var lang = locale.substr(0, 2);
		if (locale === 'default' || lang === 'en') {
			return 'en_GB';
		}
		return $cache.mixedLocales[lang] || lang + '_' + lang.toUpperCase();
	}
	/*************** app.components.global.autocomplete public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.paypal = {
		// initializations
		init: function() {
			initializeCache();
			initializeEvents();
			$cache.window.load(function(){
				initializeButtons();
			});
		},
		initExpressPayPalButtonPDP: initExpressPayPalButtonPDP
	};
}(window.app = window.app || {}, jQuery));