( function(app, $) {
	$cache = {
		document: $(document), 
		midnightScrolledBlock: $('div[data-midnight]')
	},
	$selectors = {
		midnightBlock: '.js-midnight'	
	},
	customSettings = app.midnight && app.midnight.settings ? app.midnight.settings : {},
	//Documentation can be find here:
	// https://github.com/Aerolab/midnight.js
	defaultSettings = {
		midnightBlockClass: 'js-midnight',
		
		// The class that wraps each header. Used as a clipping mask.
		headerClass: 'midnightHeader',
		
		// The class that wraps the contents of each header. Also used as a clipping mask.
		innerClass: 'midnightInner',
		
		// The class used by the default header (useful when adding multiple headers with different markup).
		defaultClass: 'default',
			
		// By default, sectionSelector is 'midnight'. It will switch only on elements that have the data-midnight attribute.
		sectionSelector: 'midnight'
	}
	
	function initCache(){
		$cache.midnights = $($selectors.midnightBlock);
	}
	
	function initMidnight() {
		
		var $midnight = $(this),
			midnightSettings = {},
			dataSettings = $midnight.data('settings') ? $midnight.data('settings') : "";

		if ( dataSettings && customSettings.hasOwnProperty(dataSettings)) {
			midnightSettings = $.extend(true, {}, defaultSettings, customSettings[dataSettings]);
		}

		$(this).midnight(midnightSettings);
	}
	
	function initMidnights() {
		if($cache.midnightScrolledBlock.length) {
			$($selectors.midnightBlock).each(initMidnight);
		}
	}
	
	app.midnight = app.jcarousel || {};
	app.midnight = $.extend(true, app.midnight, {
		init : function () {
			if (!app.device.isMobileView()) {
				initCache();
				initMidnights();
			}
		},
		initMidnight : function($midnight){
			initMidnight.call($midnight);
		}
	});

}(window.app = window.app || {}, jQuery));