//app.components.account.addresses
( function(app, $) {
	var $cache = {};
		
	function initializeCache() {
		$cache = {
			document: $(document),
			externalPlaceholderSel: '.js-external_placeholder',
			externalPlaceholderActiveCls: 'f-external_placeholder-active',
			reinitDocumentEvents: 'customerservice.pagecontent.loaded afterShow creditcard.select address.select addressfield.autofill datepicker.selected'
		};
	}

	function initializeDom() {
		$($cache.externalPlaceholderSel).each(function() {
			var $this = $(this);
			var $inputfield = $this.find('input');
			if ($inputfield.length > 0 && $inputfield.val().trim() !== '') {
				$this.addClass($cache.externalPlaceholderActiveCls);
			}
		});
	}

	function initializeEvents() {
		initExternalPlaceholder();
	}

	/**
	 * @private
	 * @function
	 * @description Binds the events focusin/blur on the externalplaceholder inputfields
	 */
	function initExternalPlaceholder() {
		$cache.document
			.on('focusin', $cache.externalPlaceholderSel, function() {
				$(this).addClass($cache.externalPlaceholderActiveCls);
			})
			.on('blur', $cache.externalPlaceholderSel, function() {
				var $this = $(this);
				var $inputfield = $this.find('input');
				if($inputfield.length > 0 && $inputfield.val().trim() === ''){
					$this.removeClass($cache.externalPlaceholderActiveCls);
				}
			});
		
		// Reinit DOM after ajax loading
		$cache.document.on($cache.reinitDocumentEvents, initializeDom);
	}

	/**
	 * @namespace app.components.global.inputfield public object
	 */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.inputfield = {
		init: function() {
			initializeCache();
			initializeDom();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
