(function(app, $) {
	var $cache = {},
		counterScrollTimeout;
	
	function initializeCache() {
		$cache = {
			body : $('body'),
			document : $(document),
			window: $(window),
			header : $('.js-header_main'),
			mainNavigation : $('.js-main_navigation'),
			topLevelMenu:$('.js-menu-toplevel'),
			menuAccordionSel : '.js-menu-accordion',
			categoryItemSel : '.js-accordion-item',
			menuAccordionContainerSel : '.js-menu_subcategory_wrapper',
			midnightNavSel : '.js-midnight-navigation',
			activeItemClass : 'b-menu_category-item--current',
			categoriesParentClass : 'm-with_subcategories',
			headerExpandedClass : 'm-dropdown-opened',
			footer: $('.js-footer_container'),
			notSubcategorySel: 'a:not(.m-with-subcategories)'
		};

		$cache.event = {
			toggleHideall : 'toggle.hideall'
		};
	}

	function categoryOnClickEvent(category) {
		// Close all opened flyout windows in header if some of them is opened
		if ($cache.header.hasClass($cache.headerExpandedClass)) {
			$cache.document.trigger($cache.event.toggleHideall);
		}

		category.toggleClass($cache.activeItemClass);
		category.siblings().removeClass($cache.activeItemClass).find(
				'.' + $cache.categoriesParentClass).removeClass($cache.activeItemClass);
	}
	
	function reinitMidNightNavigation(category) {
		/*
		 * We should get indexes of active item in current Nav Menu to clone the
		 * behavior of them in cloned menu after reiniting them with midnight
		 * plugin
		 */

		var currentMenuParents = category.parents($cache.menuAccordionSel);

		// Get the indexes of active item in categories relative path (from
		// closest parent ul)
		var activeCategoryIndexesInverted = currentMenuParents.map(function() {
			return $(this).children("." + $cache.activeItemClass).index();
		});

		// Get the array of indexes counting from the menu beginning
		var activeCategoryIndexes = $.makeArray(activeCategoryIndexesInverted)
				.reverse();

		// var isActiveCategory = category.hasClass($cache.activeItemClass);
		var currentNavigationTheme = category.closest($cache.midnightNavSel);
		
		$cache.mainNavigation.find($cache.midnightNavSel).each(
			function() { // In case if we have more then one cloned menu
						// with different style classes
				var midnightNavigationVariation = $(this);
				var stepSearchLevel = $(this);

				for (var i = 0; i < activeCategoryIndexes.length; i++) {
					var closestMenu = stepSearchLevel.find($cache.menuAccordionSel).eq(0);
					
					//Remove all active classes in current and nested levels menu if we are clicking on already opened item
					if(activeCategoryIndexes[i] === -1) { 
						closestMenu.find("." + $cache.activeItemClass).removeClass($cache.activeItemClass);
						break; //Stop set active indexes on this and next (nested) levels
					}
					
					var activeLevelItem = closestMenu.children().eq(activeCategoryIndexes[i]);	
					stepSearchLevel = activeLevelItem;
					
					if(!midnightNavigationVariation.is(currentNavigationTheme)) {	
						// If it's a last menu item with index, it's a clone of
						// clicked link and we should apply here the behavior of the
						// origin clicked link
						if (i === activeCategoryIndexes.length - 1) {
							categoryOnClickEvent(activeLevelItem);
							break;
						}
	
						activeLevelItem.addClass($cache.activeItemClass);
					}
				}
			});

		// Recalculate position and height of menus copies
		$cache.mainNavigation.midnight('refresh');
	}
	
	//Get height of overlapping area of vertical fixed navbar with footer
	function getOverlappedAreaHeight() {
		return $($cache.topLevelMenu).offset().top + $($cache.topLevelMenu).height() - $cache.footer.offset().top;
	}
	
	//Slide up navbar in case of overlapping or return it to default top position
	function slideUpVerticalNav(scrollNavTo) {
		var topPosition =  scrollNavTo === 0 ? '0' : '-=' + scrollNavTo + 'px';
				
		$($cache.topLevelMenu).stop().animate({
			'top' : topPosition
		}, 300, function() { //check overlapping after completing animation
			if (scrollNavTo === 0) {
				checkNavigationOffset();
			}
		});
	}
	
	//Check fixed navbar/footer overlapping
	function checkNavigationOffset() {
		var overlappedAreaHeight = Math.round(getOverlappedAreaHeight());
		
		if(overlappedAreaHeight > 0) {
			slideUpVerticalNav(overlappedAreaHeight);
			
		} else if (overlappedAreaHeight < 0) {
			//If vertical navmenu has no overlapping and offset - do nothing
			if($cache.topLevelMenu.position().top === 0) {
				return;
			}
			
			slideUpVerticalNav(0);
		}
	}

	function setMinHeightForMenuOverlappedPage() {
		var height = $cache.footer.height() + $($cache.topLevelMenu).offset().top + $($cache.topLevelMenu).height() + $cache.header.height();

		if (height > $cache.window.height()) {
			$cache.body.css('min-height', height);
		}

		return height;
	}

	function initializeEvents() {
		var menuItemsWithSubcategories = $($cache.categoryItemSel).filter(
			function() {
				return $(this).hasClass($cache.categoriesParentClass);
			});

		$($cache.categoryItemSel).on('click', function(e) {
			e.stopPropagation();
			
			if($(e.target).closest($cache.notSubcategorySel).length) {
				return;
			}
			
			if ($(this).hasClass($cache.categoriesParentClass)) {
				categoryOnClickEvent($(this));
			}
			// if midnight.js plugin is enabled (black/white class of nav
			// depends on dark/white data attribute of block we are scrolling
			// above)
			if ($($cache.midnightNavSel).length > 1) {
				reinitMidNightNavigation($(this)); // Reinit midnight menu copies with selected active categories
			}

			setMinHeightForMenuOverlappedPage();
			checkNavigationOffset();
		});
		
		$cache.document.on("scroll", function() {
			if($cache.topLevelMenu.length) {
				clearTimeout(counterScrollTimeout);
				counterScrollTimeout = setTimeout(function () {
					checkNavigationOffset();
				}, 50);
			}
		});
		
		//Set min-height to body in case of navbar/footer overlapping
		$cache.document.on("ready", function() {
			if($cache.topLevelMenu.length) {
				setMinHeightForMenuOverlappedPage();
			}
		});
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.categoriesAccordion = {
		init : function(param) {
			initializeCache();
			initializeEvents(param);
		}
	};
})((window.app = window.app || {}), jQuery);
