/*jshint -W700, -W703, -W704, -W705, -W707, -W089*/
(function(app, $, global) {
	var config = { tags: {}, events: {} };
	var cache = {};
	var trackerData = app.trackerData || {};

	if (!trackerData || !trackerData.gtmData) {
		console.debug('gtm: is disabled');
		return;
	}
	
	function initEvents() {
		if (app.gtm && app.gtm.config) {
			if (app.gtm.config.global) {
				config.tags = $.extend(true, config.tags, app.gtm.config.global.getTags());
				config.events = $.extend(true, config.events, app.gtm.config.global.getEvents());
			}
			if (app.gtm.config.custom) {
				config.tags = $.extend(true, config.tags, app.gtm.config.custom.getTags());
				config.events = $.extend(true, config.events, app.gtm.config.custom.getEvents());
			}
		}
		console.debug('gtm: init events');
		var types = ['default'];
		if (cache.tracker.gtmData.gtmPageType !== 'default') {
			types.push(cache.tracker.gtmData.gtmPageType);
		}

		$.each(types, function (i, key) {
			if (config.events && $.isPlainObject(config.events[key])) {
				$.each(config.events[key], function (i, eventFn) {
					if ($.isFunction(eventFn)) {
						console.debug('gtm: init event: ' + i);
						eventFn(pushEvent);
					}
				});
			}
		});
	}
	
	function runGTM() {
		cache.tracker = $.extend({}, trackerData);
		cache.tracker = $.extend(cache.tracker, trackerData.userInfo);
		
		// detect checkout step
		if (
			trackerData.gtmData.gtmPageType
			&& trackerData.gtmData.gtmPageType.indexOf('checkout_') === 0
		) {
			var checkoutNameArr = trackerData.gtmData.gtmPageType.split('_');
			trackerData.checkoutStep = parseInt(checkoutNameArr[1], 10);
		}
		
		console.debug('gtm: started', (new Date()).getTime());
	}

	function pushEvent(event) {
		if ($.isArray(global.dataLayer)) {
			global.dataLayer.push(event);
			console.debug('gtm: push event', event);
		}
	}

	// actually running before dom is ready
	console.debug('gtm: starting', (new Date()).getTime());
	runGTM();

	app.gtm = $.extend(app.gtm || {}, {
		init : function () {
			initEvents();
			console.debug('gtm: is inited', (new Date()).getTime());
		},
		pushEvent : pushEvent
	});
}(window.app = window.app || {}, jQuery, window));

